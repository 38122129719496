import {EXERCISES_DOMAINS, EXERCISES_TYPES} from "./exercises.constants";

export enum LEARNING_LANGUAGES {
  ENGLISH = 1,
  PORTUGAL = 2,
  LATVIAN = 3,
}

export interface LearningLanguagesInterface {
  name: string;
  flag: string;
  code: string;
  index: number;
  inactive?: boolean;
}

export const LEARNING_LANGUAGES_ARR: LearningLanguagesInterface[] = [
  {
    name: 'English',
    flag: 'GB',
    code: 'EN',
    index: LEARNING_LANGUAGES.ENGLISH
  },
  {
    name: 'Portugal',
    flag: 'PT',
    code: 'PT',
    index: LEARNING_LANGUAGES.PORTUGAL
  },
  {
    name: 'Latvian',
    flag: 'LV',
    code: 'LV',
    index: LEARNING_LANGUAGES.LATVIAN
  }
]

export const LEARNING_LANGUAGES_EXERCISES: any = {
  1: [
    EXERCISES_TYPES.TRUE_FALSE,
    EXERCISES_TYPES.GOAL,
    EXERCISES_TYPES.MISSING,
    EXERCISES_TYPES.MULTIPLE,
    EXERCISES_TYPES.FIND_INFORMATION,
    EXERCISES_TYPES.QUESTIONS,
    EXERCISES_TYPES.PREPOSITION
    // EXERCISES_TYPES.GERUND_INFINITIVE
  ],
  2: [
    EXERCISES_TYPES.TRUE_FALSE,
    EXERCISES_TYPES.GOAL,
    EXERCISES_TYPES.MISSING,
    EXERCISES_TYPES.MULTIPLE,
    EXERCISES_TYPES.FIND_INFORMATION
  ],
  3: [
    EXERCISES_TYPES.TRUE_FALSE,
    EXERCISES_TYPES.MISSING,
    EXERCISES_TYPES.MULTIPLE
  ]
}

export const LEARNING_LANGUAGES_DOMAINS: any = {
  reading: {
    1: [
      EXERCISES_DOMAINS.SCIENCE,
      EXERCISES_DOMAINS.BUSINESS
    ],
    2: [
      EXERCISES_DOMAINS.CULTURE
    ],
    3: [
      EXERCISES_DOMAINS.BUSINESS,
      EXERCISES_DOMAINS.CULTURE
    ]
  },
  listening: {
    1: [
      EXERCISES_DOMAINS.SCIENCE,
      EXERCISES_DOMAINS.BUSINESS
    ],
    2: [
      EXERCISES_DOMAINS.CULTURE
    ],
    3: [
      EXERCISES_DOMAINS.BUSINESS,
      EXERCISES_DOMAINS.CULTURE
    ]
  }
}

export const SKILLS_DOMAINS: any = {
  reading: [
    EXERCISES_DOMAINS.SCIENCE,
    EXERCISES_DOMAINS.BUSINESS
  ],
  listening: [
    EXERCISES_DOMAINS.SCIENCE
  ]
}
