import React from 'react';
import { useParams } from "react-router-dom";
import {
  MainContent,
} from '../../elements';
import {
  ExercisesContent,
} from "./Exercises.Styles";
import { MatchTheHeadings, TrueFalse, Goal, Missing, Multiple, FindInformation, Questions, Preposition, GerundInfinitive } from "../../components/Tasks";
import {EXERCISES_ALIAS, EXERCISES_SKILLS} from "../../const/exercises.constants";

const Exercises: React.FC = () => {
  let { exercise } = useParams();

  const Exercise = () => {
    switch (exercise) {
      case EXERCISES_ALIAS.MATCH_THE_HEADINGS:
        return <MatchTheHeadings />;
      case EXERCISES_ALIAS.TRUE_FALSE:
        return <TrueFalse exerciseSkill={EXERCISES_SKILLS.READING} />;
      case EXERCISES_ALIAS.GOAL:
        return <Goal exerciseSkill={EXERCISES_SKILLS.READING} />;
      case EXERCISES_ALIAS.MISSING:
        return <Missing exerciseSkill={EXERCISES_SKILLS.READING} />;
      case EXERCISES_ALIAS.MULTIPLE:
        return <Multiple exerciseSkill={EXERCISES_SKILLS.READING} />;
      case EXERCISES_ALIAS.FIND_INFORMATION:
        return <FindInformation exerciseSkill={EXERCISES_SKILLS.READING} />;
      case EXERCISES_ALIAS.QUESTIONS:
        return <Questions exerciseSkill={EXERCISES_SKILLS.READING} />;
      case EXERCISES_ALIAS.PREPOSITION:
        return <Preposition exerciseSkill={EXERCISES_SKILLS.READING} />;
      case EXERCISES_ALIAS.GERUND_INFINITIVE:
        return <GerundInfinitive exerciseSkill={EXERCISES_SKILLS.READING} />;
      case EXERCISES_ALIAS.LISTENING_TRUE_FALSE:
        return <TrueFalse exerciseSkill={EXERCISES_SKILLS.LISTENING} />;
      case EXERCISES_ALIAS.LISTENING_GOAL:
        return <Goal exerciseSkill={EXERCISES_SKILLS.LISTENING} />;
      case EXERCISES_ALIAS.LISTENING_MISSING:
        return <Missing exerciseSkill={EXERCISES_SKILLS.LISTENING} />;
      case EXERCISES_ALIAS.LISTENING_MULTIPLE:
        return <Multiple exerciseSkill={EXERCISES_SKILLS.LISTENING} />;
      case EXERCISES_ALIAS.LISTENING_FIND_INFORMATION:
        return <FindInformation exerciseSkill={EXERCISES_SKILLS.LISTENING} />;
      case EXERCISES_ALIAS.LISTENING_QUESTIONS:
        return <Questions exerciseSkill={EXERCISES_SKILLS.LISTENING} />;
      case EXERCISES_ALIAS.LISTENING_PREPOSITION:
        return <Preposition exerciseSkill={EXERCISES_SKILLS.LISTENING} />;
      case EXERCISES_ALIAS.LISTENING_GERUND_INFINITIVE:
        return <GerundInfinitive exerciseSkill={EXERCISES_SKILLS.LISTENING} />;
      default:
        return null;
    }
  };

  return (
    <>
      <MainContent className="content-main home-page">
        <ExercisesContent className="features">
          <Exercise />
        </ExercisesContent>
      </MainContent>
    </>
  );
};

export default Exercises;
