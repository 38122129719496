import styled from 'styled-components';

export const ActivesStyles: any = styled.div`
  padding-right: 1rem;

  .actives__link {
    color: var(--black);
  }

  @media only screen and (max-width: 40.0625em) {
    padding-right: 0;
  }
`;

export const TitleBlock: any = styled.h1`
  margin: 0 0 45px;
  font-size: 25px;
  line-height: 30px;
  color: var(--black);
  font-weight: 700;

  @media only screen and (max-width: 40.0625em) {
    margin: 0 0 25px;
    font-size: 20px;
    line-height: 24px;
  }
`;

export const ActivesHeader: any = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;

  .actives__title {
    margin: 0;
  }

  .actives__link {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-decoration: underline;
  }

    .actives__link {
      color: var(--black);
    }

  @media only screen and (max-width: 40.0625em) {
    margin-bottom: 25px;

    .actives__link {
      font-size: 14px;
      line-height: 17px;
    }
  }
`;

export const ActivesContracts: any = styled.section`
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;

  @media only screen and (max-width: 40.0625em) {
    margin: 0 -16px 45px 0;
    overflow-x: auto;
    flex-wrap: nowrap;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const ActivesContractsItem: any = styled.article`
  position: relative;
  width: calc(100% / 3 - 40px);
  min-width: 300px;
  margin: 0 20px 40px;
  padding: 40px 25px;
  border-radius: var(--border-radius);
  background: var(--light-blue);
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.04);

  &:last-child {
    margin-right: 0;
  }

  .active-contracts__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }

  .active-contracts__percent {
    margin: 0;
    font-size: 25px;
    line-height: 30px;
    color: var(--black);
    font-weight: bold;

    span {
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
      background-image: linear-gradient(180deg, #5cf9dd 0%, #10b698 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .active-contracts__easy {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.66) 0%, #9a9a9a 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .info-item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      .info-item__divider {
        background-image: linear-gradient(
          -90deg,
          var(--red) 0%,
          rgba(255, 255, 255, 0.06) 71.01%,
          rgba(255, 255, 255, 0) 99.52%
        );
      }
    }

    &__text {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
    }

    &__value {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
    }
  }
  
  .active-contracts__easy {
    background-image: linear-gradient(180deg, #8569f9 0%, #b7a7fc 100%);
  }

  .blur {
    display: none;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background: rgba(196, 196, 196, 0.01);

    h3 {
      margin: 0 0 5px;
    }

    p {
      margin: 0;
      max-width: 210px;
      font-family: var(--poppins);
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
    }
  }

  &.-inactive {
    .active-contracts__container {
      filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='5');
      -webkit-filter: url(#blur);
      filter: url(#blur);
      -webkit-filter: blur(5px);
      filter: blur(5px);
    }

    .overlay {
      display: flex;
    }
  }

  @media only screen and (max-width: 40.0625em) {
    width: 100%;
    margin: 0 16px 0 0;
    padding: 28px 20px;

    .active-contracts__header {
      flex-direction: column;
    }

    .active-contracts__percent {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 7px;
    }

    .active-contracts__easy {
      display: block;
      color: var(--white);
      background-image: linear-gradient(0deg, #ffffff, #ffffff);
    }

    .info-item {
      &__text {
        font-size: 12px;
        line-height: 15px;
      }

      &__value {
        font-size: 12px;
        line-height: 15px;
      }
    }

    .info-item.total {
      margin-top: 20px;

      .info-item__text {
        font-size: 14px;
        line-height: 17px;
      }

      .info-item__value {
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
`;

