import React from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { Button } from "../Button/Button";
import {CalendarMain, CalendarPanel, CalendarTooltip} from "./Calendar.Styles";
import {useTranslation} from "react-i18next";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/lv";
import {Shedule} from "../../api";
import {EXERCISES_ALIAS} from "../../const/exercises.constants";
import {Link} from "react-router-dom";
import {PATHS} from "../../const/paths.constants";

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  data?: Dayjs | null;
  close?: () => void;
  apply?: (payload: Dayjs | null) => void;
  shedulets?: Shedule[];
}

const Calendar: React.FC<Props> = (props: Props) => {
  const { close, apply, data, shedulets } = props;
  const { i18n, t } = useTranslation();
  const [value, setValue] = React.useState<Dayjs | null>(data || null);

  const getTooltip = (day: string) => {
    const dayShedulets = shedulets?.filter((shedule: Shedule) => shedule.eventDate.split('T')[0] === day);
    return (
      <div className="shedulets-list">
        {
          dayShedulets ? dayShedulets.map((shedule: Shedule, index: number) => (
            <div className="shedulets-item" key={`shedulets-item-${day}-${index}`}>
              <span>{t('common.shedule')} {t(`tasks.tasks.practice.items.${EXERCISES_ALIAS[shedule.taskType]}.title`)}</span>
              <p>{shedule.description}</p>
              <Button
                as={Link}
                className="shedulets-item__btn"
                to={`${PATHS.PRACTICE}/${EXERCISES_ALIAS[shedule.taskType]}/${shedule.taskId}`}
              >
                {t('common.explore')}
              </Button>
            </div>
          )) : null
        }
      </div>
    )
  };

  const ServerDay = (props: PickersDayProps<Dayjs> & { shedulets?: Shedule[] }) => {
    const { shedulets = [], day, outsideCurrentMonth, isFirstVisibleCell, isLastVisibleCell, today, selected, ...other } = props;
    const highlightedDays = shedulets.map((shedule: Shedule) => shedule.eventDate.split('T')[0]);
    const isSelected =
      !props.outsideCurrentMonth && highlightedDays.includes(day.format('YYYY-MM-DD'));

    const clickHandler = (selectedDay: Dayjs) => {
      props.onDaySelect(selectedDay);
      return false;
    }

    return (
      <CalendarTooltip
        key={props.day.toString()}
        title={isSelected ? getTooltip(props.day.format('YYYY-MM-DD')) : null}
        className={isSelected ? 'sheduled' : ''}
      >
        <PickersDay
          onDaySelect={(selectedDay: Dayjs) => clickHandler(selectedDay)}
          day={day}
          outsideCurrentMonth={outsideCurrentMonth}
          isFirstVisibleCell={isFirstVisibleCell}
          isLastVisibleCell={isLastVisibleCell}
          today={today}
          selected={selected}
        />
      </CalendarTooltip>
    );
  }

  return (
    <CalendarPanel>
      <div className="calendar-block">
        <div className="calendar-block__calendar">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n?.language}>
            <CalendarMain
              onChange={(newValue: any) => setValue(newValue)}
              value={value}
              dayOfWeekFormatter={(day: string) => day.slice(0, 2)}
              showDaysOutsideCurrentMonth={true}
              slots={{
                day: ServerDay,
              }}
              slotProps={{
                day: {
                  shedulets,
                } as any,
              }}
            />
          </LocalizationProvider>
        </div>
        {
          close || apply ? (
            <div className="calendar-block__btns">
              {
                close ? (
                  <Button
                    className="calendar-block__btn -white"
                    type="button"
                    onClick={() => close()}
                  >
                    Cancel
                  </Button>
                ) : null
              }
              {
                apply ? (
                  <Button
                    className="calendar-block__btn"
                    type="button"
                    onClick={() => apply(value)}
                  >
                    Apply
                  </Button>
                ) : null
              }
            </div>
          ) : null
        }
      </div>
    </CalendarPanel>
  );
};

export default Calendar;
