import { FormControlLabel, Checkbox } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { validateEmail, validatePassword } from '../../../common/utils/validators';
import {
  Button,
  Title,
  Input,
  Loader,
} from '../../../elements';
import { RegistrationStyles } from './Registration.Styles';
import {RegistrationParams} from "../../../api";
import {Link, useNavigate} from "react-router-dom";
import {PATHS} from "../../../const/paths.constants";
import { AppStateType } from '../../../store';
import types from '../../../store/actionTypes';
import { selectErrorByKey, selectLoadingByKey } from '../../../store/loadingsErrors/selectors';
import { registration } from '../../../store/user/actions';
import {UserReducerState} from "../../../store/user/reducers";

export interface RegistrationProps {
  user: UserReducerState;
  loading: boolean;
  error: string | null;
  registration: (payload: RegistrationParams | any) => void;
}

const Registration: React.FC<RegistrationProps> = (props: RegistrationProps) => {
  const { registration, user, error, loading } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [values, setValues] = useState<{ [key: string]: string }>({
    email: '',
    password: '',
    confirmPassword: '',
    terms: ''
  });
  const [showInput, setShowInput] = useState<{ [key: string]: boolean }>({
    password: false,
    confirmPassword: false,
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [termsRegistration, setTermsRegistration] = useState<boolean>(false);

  useEffect(() => {
    if (Object.keys(user.errors).length) {
      const newErrors: RegistrationParams = {
        email: '',
        password: '',
        confirmPassword: '',
        terms: ''
      };
      Object.keys(user.errors).forEach((key: string) => {
        newErrors[key] = user.errors[key];
      });

      setErrors(newErrors);
    }
  }, [user.errors, setErrors]);

  useEffect(() => {
    console.log('user', user);
    if (user.errors?.Message) {
      switch (user.errors?.Message) {
        case 'ACCOUNT_CREATED':
          navigate(PATHS.LOGIN);
          break;
        case 'ACCOUNT_ALREADY_EXISTS':
          navigate(PATHS.LOGIN);
          break;
        default:
          break;
      }
    }
  }, [user]);

  let getFormErrors: (data: { [p: string]: string }) => RegistrationParams;

  getFormErrors = (data: { [key: string]: string }) => {
    const {email, password, confirmPassword} = data;
    const newErrors: RegistrationParams = {
      email: '',
      password: '',
      confirmPassword: '',
      terms: ''
    };

    if (!password) newErrors.password = 'registration.page.form.password.errors.empty';
    if (password && !validatePassword(password)) newErrors.password = 'registration.page.form.password.errors.valid';
    if (!confirmPassword) newErrors.confirmPassword = 'registration.page.form.confPassword.errors.empty';
    if (password !== confirmPassword)
      newErrors.confirmPassword = 'registration.page.form.confPassword.errors.not_match';

    if (!email) newErrors.email = 'registration.page.form.email.errors.empty';
    if (email && !validateEmail(email)) newErrors.email = 'login.page.form.email.errors.valid';
    if (!termsRegistration) newErrors.terms = 'registration.page.form.terms.errors.empty';

    return newErrors;
  };

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: RegistrationParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: RegistrationParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onToggleShow = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(showInput, field)) {
      setShowInput({
        ...showInput,
        [field]: !showInput[field],
      });
    }
  };

  const onChangeTermsRegistration = (value: boolean) => {
    setTermsRegistration(value);
    if (Object.prototype.hasOwnProperty.call(errors, 'terms')) {
      const showError = !value;
      setErrors({
        ...errors,
        terms: showError ? 'Terms is not chacked' : '',
      });
    }
  };

  const onSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      const newErrors: RegistrationParams = getFormErrors(values);
      setErrors(newErrors);

      const data: RegistrationParams = {
        password: values.password,
        confirmPassword: values.confirmPassword,
      };

      data.email = values.email.toLowerCase();

      if (!checkErrors(newErrors)) {
        registration(data);
      }
    },
    [values, getFormErrors]
  );

  return (
    <RegistrationStyles className='registration'>
      <div className="registration__container">
        <div className="registration__box">
          {error && <div className="error-text">{t(`${error}`)}</div>}

          <Title className='registration__title'>
            {t('registration.page.title')}
          </Title>

          <div className="registration__text-wrap">
            <p className="registration__text" >{t('registration.page.texts.text')}</p>
            <Link className="registration__text-link" to={PATHS.LOGIN}>{t('registration.link.login')}</Link>
          </div>

          <form onSubmit={onSubmit}>
            <Input
              className='registration__input'
              type="email"
              name="email"
              value={values.email}
              placeholder={`${t('registration.page.form.email.placeholder')}`}
              error={errors.email}
              onChange={onChange}
              onBlur={onBlur}
            />
            <Input
              className='registration__input'
              type="password"
              name="password"
              value={values.password}
              placeholder={`${t('registration.page.form.password.placeholder')}`}
              error={errors.password}
              onChange={onChange}
              onBlur={onBlur}
              show={showInput.password}
              onShow={onToggleShow}
            />
            <Input
              className='registration__input'
              type="password"
              name="confirmPassword"
              value={values.confirmPassword}
              placeholder={`${t('registration.page.form.confPassword.placeholder')}`}
              error={errors.confirmPassword}
              onChange={onChange}
              onBlur={onBlur}
              show={showInput.confirmPassword}
              onShow={onToggleShow}
            />
            <FormControlLabel
              className={`registration__checkbox ${errors.terms ? '-error' : ''}`}
              control={
                <Checkbox
                  checked={termsRegistration}
                  aria-describedby="termsRegistration-text"
                  onChange={(e: React.ChangeEvent<any>) =>
                    onChangeTermsRegistration(e.target.checked)}
                />
              }
              label={
                <React.Fragment>
                  {t('registration.page.form.terms.label.agree')}
                  {' '}
                  <a
                    href="/docs/terms.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('registration.page.form.terms.label.terms')}
                  </a>
                  {' '}
                  {t('registration.page.form.terms.label.and')}
                  {' '}
                  <a
                    href="/docs/privacy.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('registration.page.form.terms.label.privacy')}
                  </a>
                </React.Fragment>
              }
            />
            <div className="registration__button-wrap">
              <Button
                className='registration__button'
                type="submit"
                disabled={loading}
              >
                {t('registration.btns.registration')}
                {loading ? <Loader /> : null}
              </Button>
            </div>

          </form>

        </div>
      </div>
    </RegistrationStyles>
  );
};

const mapState = (state: AppStateType) => {
  const { user } = state;
  return {
    user,
    loading: selectLoadingByKey(state, types.REGISTRATION_REQUEST),
    error: selectErrorByKey(state, types.REGISTRATION_REQUEST),
  };
};

export default connect(mapState, { registration })(Registration);
