import React, { useRef } from 'react';
import { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {CalendarBlock, CalendarHidden} from "./ModalCalendar.Styles";
import Calendar from "../Calendar/Calendar";

import {useTranslation} from "react-i18next";

import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/lv";
import {Shedule} from "../../api";

interface Props {
  className?: string;
  data: Dayjs | null;
  setData: (payload: Dayjs | null) => void;
  shedulets?: Shedule[];
}

const ModalCalendar: React.FC<Props> = (props: Props) => {
  const { className, data, setData, shedulets } = props;
  const [calendarOpened, setCalendarOpened] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<Dayjs | null>(data);
  const buttonRef = useRef();
  const { t } = useTranslation();

  const handleClose = () => {
    setCalendarOpened(false)
  };

  const handleApply = (payload: Dayjs | null) => {
    setCalendarOpened(false);
    setData(payload);
  };

  return (
    <CalendarBlock className={`calendar ${className}`}>
      <div className="calendar-wrap">
        <button
          ref={() => buttonRef}
          className={`calendar__btn`}
          type="button"
          onClick={
            () => setCalendarOpened(!calendarOpened)
          }
        >
          {t('common.calendar')}
        </button>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CalendarHidden
            open={calendarOpened}
            onOpen={() => setCalendarOpened(true)}
            onClose={() => setCalendarOpened(false)}
            onChange={(newValue: any) => setValue(newValue)}
            value={value}
            renderInput={() => <div />}
            components={{
              Layout: Calendar
            }}
            componentsProps={{
              layout: {data, close: handleClose, apply: handleApply, shedulets},
              openPickerButton: { ref: buttonRef }
            }}
          />
        </LocalizationProvider>
      </div>
    </CalendarBlock>
  );
};

export default ModalCalendar;
