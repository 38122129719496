import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {
  Container,
  MainContent,
  GainsLevel,
  ProfitComponent,
  TaskComponent,
  ScrollBar, Loader, Calendar
} from '../../elements';
import {
  ContentCalendar,
  ContentMainGains,
  ContentMainProfit,
  Gains,
  GainsRank,
  Profit,
  ProfitContainer, Tasks, TasksContainer, TasksTitle
} from "./Dashboard.Styles";
import { Profitability } from "../../elements/ProfitComponent/ProfitComponent";
import { Task } from "../../elements/TaskComponent/TaskComponent";
import {PATHS} from "../../const/paths.constants";
import {AppStateType} from "../../store";
import {selectLoadingByKey} from "../../store/loadingsErrors/selectors";
import types from "../../store/actionTypes";
import {UserReducerState} from "../../store/user/reducers";
import {fetchStatistics} from "../../store/user/actions";
import {GetSheduletsParams, Shedule, StatisticsParams} from "../../api";
import {StatisticsListTask} from "../../api/codecs.users";
import {PerformanceByPerccent} from "../../const/app.constants";
import {ProfitComponentLoading} from "../../elements/ProfitComponent/ProfitComponent.Styles";
import {useTranslation} from "react-i18next";
import {EXERCISES_ALIAS, TASK_DATA_BY_ID} from "../../const/exercises.constants";
import {getShedulets} from "../../store/practice/actions";
import {PracticeReducerState} from "../../store/practice/reducers";

const data: any = {
  gains: [
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.MATCH_THE_HEADINGS}.title`,
      description: 'common.developing',
      currency: '%',
      value: 0,
      maxValue: 100,
      count: 0,
      className: '-inactive'
    }
  ],
  reading: [
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.TRUE_FALSE}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/${EXERCISES_ALIAS.TRUE_FALSE}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.TRUE_FALSE}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.GOAL}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/${EXERCISES_ALIAS.GOAL}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.GOAL}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.MISSING}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/${EXERCISES_ALIAS.MISSING}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.MISSING}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.MULTIPLE}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/${EXERCISES_ALIAS.MULTIPLE}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.MULTIPLE}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.FIND_INFORMATION}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/${EXERCISES_ALIAS.FIND_INFORMATION}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.FIND_INFORMATION}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.QUESTIONS}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/${EXERCISES_ALIAS.QUESTIONS}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.QUESTIONS}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.PREPOSITION}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/${EXERCISES_ALIAS.PREPOSITION}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.PREPOSITION}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.GERUND_INFINITIVE}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/${EXERCISES_ALIAS.GERUND_INFINITIVE}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.GERUND_INFINITIVE}.description`,
      description: '',
      className: ''
    },
    {
      title: 'tasks.tasks.practice.items.listSelection.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.listSelection.description',
      description: '',
      className: '-inactive'
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.MATCH_THE_HEADINGS}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.match.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.matchingParagraphInfo.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.matchingParagraphInfo.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.sentenceCompletion.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.sentenceCompletion.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.shortAnswerQuestion.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.shortAnswerQuestion.description',
      description: '',
      className: '-inactive'
    }
  ],
  listening: [
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.TRUE_FALSE}.title`,
      value: '',
      redirectUrl: `${PATHS.LISTENING}/${EXERCISES_ALIAS.LISTENING_TRUE_FALSE}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.TRUE_FALSE}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.GOAL}.title`,
      value: '',
      redirectUrl: `${PATHS.LISTENING}/${EXERCISES_ALIAS.LISTENING_GOAL}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.GOAL}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.MISSING}.title`,
      value: '',
      redirectUrl: `${PATHS.LISTENING}/${EXERCISES_ALIAS.LISTENING_MISSING}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.MISSING}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.MULTIPLE}.title`,
      value: '',
      redirectUrl: `${PATHS.LISTENING}/${EXERCISES_ALIAS.LISTENING_MULTIPLE}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.MULTIPLE}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.FIND_INFORMATION}.title`,
      value: '',
      redirectUrl: `${PATHS.LISTENING}/${EXERCISES_ALIAS.LISTENING_FIND_INFORMATION}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.FIND_INFORMATION}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.QUESTIONS}.title`,
      value: '',
      redirectUrl: `${PATHS.LISTENING}/${EXERCISES_ALIAS.LISTENING_QUESTIONS}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.QUESTIONS}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.PREPOSITION}.title`,
      value: '',
      redirectUrl: `${PATHS.LISTENING}/${EXERCISES_ALIAS.LISTENING_PREPOSITION}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.PREPOSITION}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.GERUND_INFINITIVE}.title`,
      value: '',
      redirectUrl: `${PATHS.LISTENING}/${EXERCISES_ALIAS.LISTENING_GERUND_INFINITIVE}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.GERUND_INFINITIVE}.description`,
      description: '',
      className: ''
    },
    {
      title: 'tasks.tasks.practice.items.listSelection.title',
      value: '',
      redirectUrl: `${PATHS.LISTENING}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.listSelection.description',
      description: '',
      className: '-inactive'
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.MATCH_THE_HEADINGS}.title`,
      value: '',
      redirectUrl: `${PATHS.LISTENING}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.match.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.matchingParagraphInfo.title',
      value: '',
      redirectUrl: `${PATHS.LISTENING}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.matchingParagraphInfo.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.sentenceCompletion.title',
      value: '',
      redirectUrl: `${PATHS.LISTENING}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.sentenceCompletion.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.shortAnswerQuestion.title',
      value: '',
      redirectUrl: `${PATHS.LISTENING}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.shortAnswerQuestion.description',
      description: '',
      className: '-inactive'
    }
  ],
  meaning: [
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.TRUE_FALSE}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/${EXERCISES_ALIAS.TRUE_FALSE}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.TRUE_FALSE}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.GOAL}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/${EXERCISES_ALIAS.GOAL}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.GOAL}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.MISSING}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/${EXERCISES_ALIAS.MISSING}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.MISSING}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.MULTIPLE}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/${EXERCISES_ALIAS.MULTIPLE}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.MULTIPLE}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.FIND_INFORMATION}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/${EXERCISES_ALIAS.FIND_INFORMATION}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.FIND_INFORMATION}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.QUESTIONS}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/${EXERCISES_ALIAS.QUESTIONS}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.QUESTIONS}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.PREPOSITION}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/${EXERCISES_ALIAS.PREPOSITION}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.PREPOSITION}.description`,
      description: '',
      className: ''
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.GERUND_INFINITIVE}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/${EXERCISES_ALIAS.GERUND_INFINITIVE}`,
      subTitle: '',
      valueText: `tasks.tasks.practice.items.${EXERCISES_ALIAS.GERUND_INFINITIVE}.description`,
      description: '',
      className: ''
    },
    {
      title: 'tasks.tasks.practice.items.listSelection.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.listSelection.description',
      description: '',
      className: '-inactive'
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.MATCH_THE_HEADINGS}.title`,
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.match.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.matchingParagraphInfo.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.matchingParagraphInfo.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.sentenceCompletion.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.sentenceCompletion.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.shortAnswerQuestion.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.shortAnswerQuestion.description',
      description: '',
      className: '-inactive'
    }
  ],
  allegory: [
    {
      title: 'tasks.tasks.practice.items.idioms.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.idioms.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.metaphors.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.metaphors.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.personification.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.personification.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.storyElements.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.storyElements.description',
      description: '',
      className: '-inactive'
    }
  ],
  structure: [
    {
      title: 'tasks.tasks.practice.items.choosingTitle.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.choosingTitle.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.classificationQuestion.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.classificationQuestion.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.compareContrast.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.compareContrast.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.diagramCompletion.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.diagramCompletion.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.matchingSentenceEnding.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.matchingSentenceEnding.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.sequencing.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.sequencing.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.summaryCompletion.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.summaryCompletion.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.tableCompletion.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.tableCompletion.description',
      description: '',
      className: '-inactive'
    },
  ],
  criticalThinking: [
    {
      title: 'tasks.tasks.practice.items.cause.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.cause.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.conclusionsInferences.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.conclusionsInferences.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.contextClues.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.contextClues.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.factOpinion.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.factOpinion.description',
      description: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.prediction.title',
      value: '',
      redirectUrl: `${PATHS.PRACTICE}/match-the-headings`,
      subTitle: '',
      valueText: 'tasks.tasks.practice.items.prediction.description',
      description: '',
      className: '-inactive'
    }
  ]
}

export interface DashboardProps {
  user: UserReducerState;
  practice: PracticeReducerState;
  loadingStatistics: boolean;
  loadingShedulets: boolean;
  fetchStatistics: (payload: StatisticsParams | any) => void;
  getShedulets: (payload: GetSheduletsParams) => void;
}

const Dashboard: React.FC<DashboardProps> = (props: DashboardProps) => {
  const { user, practice, loadingStatistics, loadingShedulets, fetchStatistics, getShedulets } = props;
  const { t } = useTranslation();
  const [selectedCard, setSelectedCard] = useState(0);
  const [statistics, setStatistics] = useState<any[]>([]);
  const [profitabilities, setProfitabilities] = useState<Profitability[]>([]);

  useEffect(() => {
    if (!user.statistics.loaded) {
      fetchStatistics({
        StudentId: user.userData?.id,
        languageId: user.learningLanguage
      });
    } else {
      if (user.statistics.list.length) {
        let completedTasks = 0;
        let totalSeconds = 0;
        let totalPerformance = 0;
        let totalTasks = 0;
        const newStatistics = user.statistics.list.map((stat: StatisticsListTask ) => {
          if (!TASK_DATA_BY_ID[stat.TaskType]) return null;
          const taskType = TASK_DATA_BY_ID[stat.TaskType];

          completedTasks += stat.CompletedTasks;
          totalSeconds += stat.FactTimeSec;
          totalPerformance += (stat.CompletedTasks / stat.GeneratedTasks) * 100;
          totalTasks += 1;

          return {
            title: `tasks.tasks.practice.items.${EXERCISES_ALIAS[taskType]}.title`,
            description: 'common.completed',
            currency: '%',
            value: (stat.CompletedTasks / stat.GeneratedTasks) * 100,
            maxValue: 100,
            count: stat.CompletedTasks,
            taskType,
            className: ''
          }
        }).filter((stat) => !!stat);

        const statisticsArr: any[] = [...newStatistics, ...data.gains];

        const newProfitabilities: any = [
          {
            title: 'dashboard.profitabilities.performance.title',
            tooltip: 'dashboard.profitabilities.performance.tooltip',
            value: PerformanceByPerccent(totalPerformance / totalTasks),
            className: ''
          },
          {
            title: 'dashboard.profitabilities.spent.title',
            tooltip: 'dashboard.profitabilities.spent.tooltip',
            value: `${(totalSeconds > 60 ? (totalSeconds / 60) > 60 ? totalSeconds / 3600 : totalSeconds / 60 : totalSeconds).toFixed(2).replace(/\.?0+$/, '')} ${totalSeconds > 60 ? (totalSeconds / 60) > 60 ? 'hours' : 'minutes' : 'seconds'}`,
            className: ''
          },
          {
            title: 'dashboard.profitabilities.total.title',
            tooltip: 'dashboard.profitabilities.total.tooltip',
            value: `${completedTasks} tasks`,
            className: ''
          }
        ]

        setStatistics(statisticsArr);
        setProfitabilities(newProfitabilities);
      }
    }

    if (user.learningLanguage !== user.statistics.languageId && user.statistics.loaded) {
      fetchStatistics({
        StudentId: user.userData?.id,
        languageId: user.learningLanguage
      });
    }

    if (!practice.shedulets.loaded) {
      getShedulets({
        UserId: user.userData?.id || 0,
        Language_id: user.learningLanguage
      });
    }

    if (user.learningLanguage !== practice.shedulets.languageId && practice.shedulets.loaded) {
      getShedulets({
        UserId: user.userData?.id || 0,
        Language_id: user.learningLanguage
      });
    }
  }, [user, fetchStatistics, setStatistics, setProfitabilities, practice.shedulets.languageId, practice.shedulets.loaded, getShedulets]);

  return (
    <MainContent className='content-main home-page'>
      <Container className="-no-border">
        <ContentMainGains className="content-main__gains">
          <Gains className="" >
            <h2 className="home-title">{t('home.knowledge_tracker.title')}</h2>

            <GainsRank className="gains-rank">
              {
                !loadingStatistics ? statistics.map((gain: any, index: number) => (
                  <GainsLevel
                    key={`gain-${index + 1}`}
                    className={gain.className}
                    title={gain.title}
                    text={gain.description}
                    count={gain.count}
                    currency={gain.currency}
                    value={gain.value}
                    maxValue={gain.maxValue}
                    taskType={gain.taskType}
                    isMain
                  />
                )) : (
                  <Loader />
                )
              }
            </GainsRank>
          </Gains>

          <ContentMainProfit className="profitability">
            <ContentCalendar>
              <Calendar
                shedulets={practice.shedulets.list}
              />
              <Profit className="profit">
                <ProfitContainer className="profit__container">
                  {
                    !loadingStatistics ? profitabilities.map((item: Profitability, index: number) => {
                      return (
                        <ProfitComponent
                          selected={selectedCard === index && !!item.value}
                          key={`profitability-${index + 1}`}
                          onClick={() => setSelectedCard(index)}
                          item={item}
                        />
                      );
                    }) : (
                      <>
                        <ProfitComponentLoading>
                          <Loader />
                        </ProfitComponentLoading>
                        <ProfitComponentLoading>
                          <Loader />
                        </ProfitComponentLoading>
                        <ProfitComponentLoading>
                          <Loader />
                        </ProfitComponentLoading>
                      </>
                    )
                  }
                </ProfitContainer>
              </Profit>
            </ContentCalendar>

            <TasksTitle>{t('tasks.headers.reading')}</TasksTitle>

            <Tasks className="tasks">
              <ScrollBar className="tasks-scrollbar">
                <TasksContainer className="tasks__container">
                  {data.reading.map((item: Task, index: number) => (
                    <TaskComponent
                      item={item}
                      key={`task-${index + 1}`}
                    />
                  ))}
                </TasksContainer>
              </ScrollBar>
            </Tasks>

            <TasksTitle>{t('tasks.headers.listening')}</TasksTitle>

            <Tasks className="tasks">
              <ScrollBar className="tasks-scrollbar">
                <TasksContainer className="tasks__container -four">
                  {data.listening.map((item: Task, index: number) => (
                    <TaskComponent
                      item={item}
                      key={`task-${index + 1}`}
                    />
                  ))}
                </TasksContainer>
              </ScrollBar>
            </Tasks>

            {/*<TasksTitle>{t('tasks.headers.structure')}</TasksTitle>*/}

            {/*<Tasks className="tasks">*/}
            {/*  <ScrollBar className="tasks-scrollbar">*/}
            {/*    <TasksContainer className="tasks__container -eight">*/}
            {/*      {data.structure.map((item: Task, index: number) => (*/}
            {/*        <TaskComponent*/}
            {/*          item={item}*/}
            {/*          key={`task-${index + 1}`}*/}
            {/*        />*/}
            {/*      ))}*/}
            {/*    </TasksContainer>*/}
            {/*  </ScrollBar>*/}
            {/*</Tasks>*/}

            {/*<TasksTitle>{t('tasks.headers.criticalThinking')}</TasksTitle>*/}

            {/*<Tasks className="tasks">*/}
            {/*  <ScrollBar className="tasks-scrollbar">*/}
            {/*    <TasksContainer className="tasks__container -five">*/}
            {/*      {data.criticalThinking.map((item: Task, index: number) => (*/}
            {/*        <TaskComponent*/}
            {/*          item={item}*/}
            {/*          key={`task-${index + 1}`}*/}
            {/*        />*/}
            {/*      ))}*/}
            {/*    </TasksContainer>*/}
            {/*  </ScrollBar>*/}
            {/*</Tasks>*/}
          </ContentMainProfit>
        </ContentMainGains>
      </Container>
    </MainContent>
  );
};

const mapState = (state: AppStateType) => {
  const { user, practice } = state;
  return {
    user,
    practice,
    loadingStatistics: selectLoadingByKey(state, types.GET_STATISTICS_REQUEST),
    loadingShedulets: selectLoadingByKey(state, types.GET_SHEDULETS_REQUEST)
  };
};

export default connect(mapState, { fetchStatistics, getShedulets })(Dashboard);
