import { Skeleton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon/Icon';
import {
  Level,
  LevelBox,
  LevelLeft,
  LevelRight,
  LevelText,
} from './GainsLevel.Styles';
import ProgressBar from '../ProgressBar/ProgressBar';
import {PATHS} from "../../const/paths.constants";
import {EXERCISES_ALIAS} from "../../const/exercises.constants";

interface Props {
  value: number;
  maxValue: number;
  title?: string | null;
  count?: number | null;
  tooltip?: string | null;
  tooltipParams?: any;
  text?: string | null;
  currency?: string;
  isMain?: boolean;
  isBolean?: boolean;
  className?: string;
  taskType: string
}

export const GainsLevel: React.FC<Props> = (props: Props) => {
  const {
    title,
    tooltip,
    tooltipParams,
    value,
    currency,
    maxValue,
    text,
    count,
    isMain,
    isBolean,
    className,
    taskType,
  } = props;

  const { t } = useTranslation();

  const isCompleted = value / maxValue >= 1 && !isBolean;
  const progress = isCompleted ? 100 : (value / maxValue) * 100;

  return (
    <Level
      className={`level ${isCompleted ? 'completed' : ''} ${className}`}
      data-title={tooltip ? t(tooltip, tooltipParams) : null}
    >
      <LevelBox
        className="level__box"
        href={`${PATHS.PRACTICE}/${EXERCISES_ALIAS[taskType]}`}
      >
        <LevelLeft className="level__left">
          <LevelText className={`level__text ${isMain ? '' : 'more'}`}>
            <p>{!title ? <Skeleton variant="text" animation="wave" width={110} /> : t(title)}</p>
            <span>{!text ? <Skeleton variant="text" animation="wave" width={110} /> : `${t(text)}${count ? ': ' + count : ': 0'}`}</span>
          </LevelText>
        </LevelLeft>
        {isCompleted ? (
          <LevelRight className="level__right">
            <Icon name="ok" size="28" />
          </LevelRight>
        ) : (
          <LevelRight className="level__right">
            {!value && value !== 0 ? (
              <p>
                0{currency ? currency : ''}
              </p>
            ) : (
              !isBolean && (
                <p>
                  {`${value.toFixed(2).replace(/\.?0+$/, '')}`}
                  {currency ? currency : ''}
                </p>
              )
            )}
          </LevelRight>
        )}
      </LevelBox>
      <ProgressBar max={100} value={progress || 0} />
    </Level>
  );
};
