import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { AuthLayout } from '../components/Layout';
import { PATHS } from '../const/paths.constants';

interface AuthRoutesProps {
  children?: React.ReactElement;
  title?: string;
  loggedIn: boolean;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({
  children,
  title,
  loggedIn,
}) => {
  const { t } = useTranslation();
  document.title = `${t(title || '')}`;

  return loggedIn ? (
    <Navigate replace to={PATHS.DASHBOARD} />
  ) : (
    <AuthLayout>
      { children }
    </AuthLayout>
  );
};

const mapStateToProps = (state: any) => ({
  loggedIn: state.user.loggedIn,
});

export default connect(mapStateToProps)(AuthRoutes);
