import { Select } from '@mui/material';
import styled from 'styled-components';

export const SelectStyles: any = styled(Select)`
  border-radius: var(--border-radius);
  border: 3px solid var(--gray);
  padding: 12px 0;
  text-align: center;
  width: 200px;
  
  .MuiSvgIcon-root {
    display: flex;
  }
`;
