import { all, call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import API from '../../api/executor';
import {
  ALERT_TYPES,
} from '../../const/app.constants';
import { STORAGE_KEYS } from '../../const/storage_keys.constants';
import { setAlert, setLoading } from '../loadingsErrors/actions';
import {
  RegistrationRequest,
  LoginRequest, GetStatisticsRequest, UpdateUserRequest,
} from './actionTypes';
import types from '../actionTypes';
import {UserData} from "../../api";

// calls
const logInCall = (payload?: any) => API.call('logIn', payload);
const registrationCall = (payload?: any) => API.call('registration', payload);
const fetchStatisticsCall = (payload?: any) => API.call('getStatistics', payload);

const updateUserCall = (payload?: any) => API.call('updateUser', payload);

// call types
type Login = SagaReturnType<typeof logInCall>;
type Registration = SagaReturnType<typeof registrationCall>;
type FetchStatistics = SagaReturnType<typeof fetchStatisticsCall>;
type UpdateUser = SagaReturnType<typeof updateUserCall>;

function* login({ payload }: LoginRequest) {
  yield put(setLoading(types.LOGIN_REQUEST, true));
  try {

    const res: Login = yield call(() => logInCall(payload));
    const currentDate = new Date();
    const token = {
      accessToken: res.tokenDetails.access_token,
      refreshToken: res.tokenDetails.refresh_token,
      expiresIn: currentDate.getTime() + res.tokenDetails.expires_in
    }
    const user: UserData = {
      id: res.Id,
      levelId: res.LevelId,
      email: res.Email,
      phone: res.Phone,
      rating: res.Rating,
      skills: res.Skills,
      teacherId: res.TeacherId,
      photo: '',
      isAdmin: res.RoleName === 'Admin',
      first_name: res.FirstName,
      last_name: res.LastName
    }

    localStorage.setItem(STORAGE_KEYS.AUTH, JSON.stringify(token));
    localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(user));

    yield all([
      put({ type: types.LOGIN_SUCCESS, payload: user }),
      put(setLoading(types.LOGIN_REQUEST, false)),
    ]);
  } catch (e: any) {
    if (e.errObj) {
      const error = e?.errObj ? e.errObj : null;

      yield all([
        put(setLoading(types.LOGIN_REQUEST, false)),
        put(setAlert(error ? `error.${error}` : 'error.network.404', ALERT_TYPES.DANGER)),
        put({
          type: types.LOGIN_FAILURE,
          payload: error,
        }),
      ]);
    }
  }
}

function* registration({ payload }: RegistrationRequest) {
  yield put(setLoading(types.REGISTRATION_REQUEST, true));
  try {
    const res: Registration = yield call(() => registrationCall(payload));

    yield all([
      put({ type: types.REGISTRATION_SUCCESS, payload: res }),
      put(setLoading(types.REGISTRATION_REQUEST, false)),
      put(setAlert('registration.page.texts.created', ALERT_TYPES.SUCCESS)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : null;

    yield all([
      put(setLoading(types.REGISTRATION_REQUEST, false)),
      put(setAlert(error ? `error.${e}` : 'error.network.404', ALERT_TYPES.DANGER)),
      put({
        type: types.REGISTRATION_FAILURE,
        payload: error,
      }),
    ]);
  }
}

function* forgotPassword() {
  yield all([
    put({ type: types.FORGOT_PASSWORD_SUCCESS, payload: true }),
    put(setLoading(types.FORGOT_PASSWORD_REQUEST, false)),
    put({ type: types.LOGIN_SUCCESS })
  ]);
}

function* logout() {
  yield put(setLoading(types.LOGOUT_REQUEST, true));
  localStorage.removeItem(STORAGE_KEYS.AUTH);
  localStorage.removeItem(STORAGE_KEYS.USER);
  yield all([put({ type: types.LOGOUT_SUCCESS }), put(setLoading(types.LOGOUT_REQUEST, false))]);
}

function* fetchStatistics({ payload }: GetStatisticsRequest) {
  yield put(setLoading(types.GET_STATISTICS_REQUEST, true));
  try {
    const res: FetchStatistics = yield call(() => fetchStatisticsCall(payload));
    yield all([
      put({ type: types.GET_STATISTICS_SUCCESS, payload: res }),
      put(setLoading(types.GET_STATISTICS_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : null;

    yield all([
      put(setLoading(types.GET_STATISTICS_REQUEST, false)),
      put(setAlert(error instanceof String ? `error.${error}` : 'error.network.404', ALERT_TYPES.DANGER)),
      put({ type: types.GET_STATISTICS_FAILURE }),
    ]);
  }
}

function* updateUser({ payload }: UpdateUserRequest) {
  yield put(setLoading(types.UPDATE_USER_REQUEST, true));
  try {
    const res: UpdateUser = yield call(() => updateUserCall(payload));

    yield all([
      put({ type: types.UPDATE_USER_SUCCESS, payload: payload }),
      put(setLoading(types.UPDATE_USER_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : null;

    yield all([
      put(setLoading(types.UPDATE_USER_REQUEST, false)),
      put(setAlert(error ? `error.${e}` : 'error.network.404', ALERT_TYPES.DANGER)),
      put({
        type: types.UPDATE_USER_FAILURE,
        payload: error,
      }),
    ]);
  }
}

export default function* usersSagas() {
  yield takeLatest(types.LOGIN_REQUEST, login);
  yield takeLatest(types.REGISTRATION_REQUEST, registration);
  yield takeLatest(types.FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeLatest(types.LOGOUT_REQUEST, logout);
  yield takeLatest(types.GET_STATISTICS_REQUEST, fetchStatistics);
  yield takeLatest(types.UPDATE_USER_REQUEST, updateUser);
}
