import React, {createContext, useEffect, useState} from 'react'
import { AudioContextApi } from './types'
import {useLocation} from "react-router-dom";

type Props = {
  children: React.ReactNode; //👈 children prop typr
};

export const AudioContext = createContext<AudioContextApi | undefined>(undefined)

export const BackgroundAudio: React.FC<Props> = ({ children }: Props) => {
  const location = useLocation();

  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [audioLink, setAudioLink] = useState<string | null>(null);

  const remove = () => {
    console.log('remove', audio);
    if (audio) {
      audio.pause();
    }
    setAudio(null);
    setAudioLink(null);
  }

  useEffect(() => {
    if (audio) {
      remove();
    }
  }, [location]);

  return (
    <AudioContext.Provider
      value={{ audio, audioLink, remove, setAudio, setAudioLink }}
    >
      {children}
    </AudioContext.Provider>
  )
}
