import React, {useState} from 'react';

import {
  TaskContent,
} from "./Tasks.Styles";
import cloneDeep from "lodash/cloneDeep";
import {Button, Input} from "../../elements";
import {useTranslation} from "react-i18next";

export interface ExercixeProps {
  text: string;
  name: string;
  correctOption: string;
  error: boolean;
}

const data: ExercixeProps[] = [
  {
    text: "I would not have thought anything of it, save for the fact that I’ve had similar experiences over and over again of late. My younger son drinks infant formula; I haven’t been able to buy our preferred brand more than once or twice in his lifetime. My older son recently needed an antibiotic for an ear infection; the pediatrician warned my husband he might not be able to find it. My dogs’ veterinarian told me this fall that we should find a new vet; he’s so overbooked that he’s dropping clients. My family is relocating, so we are now scrambling to find nursery-school spots for our kids. As for reasonably priced movers—I’m not sure they exist. I’m probably going to drive the truck myself.",
    name: "",
    correctOption: 'Resembling situations',
    error: false
  },
  {
    text: "Since the pandemic hit, the economy has been plagued by shortages, some caused or worsened by COVID and many not. Indeed, none of the supply crunches I just cited—coins, formula, antibiotics, veterinary services, early-childhood education, truck drivers—has much to do with the virus still afflicting the world. Something deeper is going on. After the Great Recession, we went through a decade in which economic life was defined by a lack of demand. Now, after the COVID recession, we’ve entered a period in which economic life is defined by a lack of supply.",
    name: "",
    correctOption: 'Suffering from absence',
    error: false
  },
  {
    text: "During the aughts and 2010s, the primary problem was that most families did not earn enough money. Unemployment and underemployment were rampant. Wage growth was slack because companies had no incentive to compete for workers. The middle class was shrinking. And inequality yawned, with the haves getting richer while the have-nots struggled.",
    name: "",
    correctOption: 'Absence of growth',
    error: false
  },
  {
    text: "This era—which lasted from 2007 until 2018, give or take—was one of extremely loose monetary policy and stingy fiscal policy. The Federal Reserve made it as cheap as it possibly could for businesses and individuals to borrow, but only corporations and the wealthy had the cash on hand to take advantage; Congress, for its part, declined to do much long-term investment and kept its spending stable. It was also an era of low GDP growth, low inflation, and a steady debt-to-GDP ratio, outside of the Great Recession itself. In this environment—let’s call it Demand World—the fundamental problem was the economy’s low appetite for goods and services.",
    name: "",
    correctOption: 'No development',
    error: false
  },
  {
    text: "Today, we live in Supply World. People’s primary economic fixation is getting their hands on enough of the stuff they want to buy. Families, for once, have plenty of money. By the middle of the Trump administration, the unemployment rate had fallen low enough and stayed low for long enough that wages started increasing. Businesses began bidding against one another to win over workers. (A Panda Express near my home had a sign up offering $86,000 a year plus a bonus for managers and $19 an hour for its lowest-paying kitchen jobs.) Then the government showered families with money during the pandemic, in the form of stimulus checks, child allowances, small-business relief, and extended unemployment-insurance payments. As a result, inequality has—in a remarkable and underappreciated trend—declined, a lot and fast.",
    name: "",
    correctOption: 'Desire to acquire',
    error: false
  }
];

const options: any[] = [
  {
    number: 'a',
    text: 'Desire to acquire',
  },
  {
    number: 'b',
    text: 'No development'
  },
  {
    number: 'c',
    text: 'Resembling situations'
  },
  {
    number: 'd',
    text: 'Absence of growth'
  },
  {
    number: 'e',
    text: 'Suffering from absence'
  }
];

const MatchTheHeadings: React.FC = () => {
  const { t } = useTranslation();

  const [values, setValues] = useState<ExercixeProps[]>(data);
  const [correctValues, setCorrectValues] = useState<number>(0);
  const [submited, setSubmited] = useState<boolean>(false);

  const onChange = (field: string, value: string) => {
    const newValues = cloneDeep(values);
    newValues[Number(field)].name = value;
    setValues(newValues);
  };

  const onSubmit = () => {
    let newValues: ExercixeProps[] = cloneDeep(values);
    let currentErrors = 0;
    newValues = newValues.map((val: ExercixeProps) => {
      if (val.correctOption.toLowerCase().replace(/\s/g, '') === val.name.toLowerCase().replace(/\s/g, '')) {
        currentErrors += 1;
        val.error = false;
      } else {
        val.error = true;
      }

      return val;
    });

    setValues(newValues);
    setCorrectValues(currentErrors);
    setSubmited(true);
  };

  return (
    <TaskContent className="exercise">
      <div className="grid-container">
        <div className="exercise-title__wrap">
          <p className="exercise-title h1">{t('home.choose.tasks.practice.items.match')}</p>
        </div>

        <div className="exercise-description">
          <p className="bolder">{t('exercises.match.description')}</p>
        </div>

        <ul className="exercise-list__wrap">
          {
            options.map((header) => (
              <li key={`${header.number}`} className="exercise-list">{header.number}. {header.text}</li>
            ))
          }
        </ul>

        <div className="exercise-article">
          <p className="h3">The Economy’s Fundamental<br />Problem Has Changed</p>
          <br />
          <p className="h4">For years, Americans couldn’t afford to buy things. Now there aren’t<br />enough things to buy.</p>
        </div>

        <div className="exercise-description">
          <p className="bolder">By Annie Lowrey</p>
        </div>

        <div className="exercise-article">
          <p>A few weeks ago, I was buying an iced coffee near my home in San Francisco. I went to pay with cash, and the barista asked me to pay with Apple Pay or a card—she could give me back bills, but did not have any coins.</p>
        </div>

        <div className="exercise-form">
          {
            values.map((value: ExercixeProps, index: number) => (
              <div className={`exercise-line ${submited && value.error ? '-error' : ''} ${submited && !value.error ? '-success' : ''}`} key={`exercise-${index}`}>
                <div className="exercise-col">
                  <div className="exercise-wrap">
                    <div className="exercise-text__wrap">
                      <p className="exercise-text">{value.text}</p>
                    </div>
                    <div className="exercise-input__wrap">
                      <span className="exercise-input__label">{t('exercises.match.texts.header')}</span>
                      <Input
                        className="exercise-input"
                        type="text"
                        name={`${index}`}
                        value={value.name}
                        placeholder={t('exercises.match.texts.add')}
                        onChange={onChange}
                      />
                    </div>
                    {
                      submited && value.error ? (
                        <div className="exercise-error">
                          <p className="bolder">{t('exercises.true.texts.correct')}: {value.correctOption}</p>
                        </div>
                      ) : null
                    }
                  </div>
                </div>
              </div>
            ))
          }
        </div>

        {
          submited ? (
            <div className={`exercise-result ${correctValues <= 3 ? '-error' : '-success'}`}>
              {t('exercises.true.texts.result')} {correctValues}/5
            </div>
          ) : (
            <div className="exercise-btn__wrap">
              <Button
                type="button"
                className="exercise-btn"
                onClick={onSubmit}
              >
                {t('exercises.btns.ready')}
              </Button>
            </div>
          )
        }
      </div>
    </TaskContent>
  );
};

export default MatchTheHeadings;
