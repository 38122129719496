import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import { STORAGE_KEYS } from '../const/storage_keys.constants';

import reducers from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducers, applyMiddleware(sagaMiddleware, thunk));

sagaMiddleware.run(rootSaga);

const auth = JSON.parse(localStorage.getItem(STORAGE_KEYS.AUTH) || '{}');

if (!!auth.accessToken) {

}

export type AppStateType = ReturnType<typeof store.getState>;

export default store;
