import { API_URL } from '../const/general.constants';
import { STORAGE_KEYS } from '../const/storage_keys.constants';

import { ApiHandlerParams, ApiHandlerTypes, getHandler, ApiHandlerResponse } from './calls';
import { ApiError, isApiError } from './error';
import { Request, FormDataEntry } from './request';

type ErrorHandler = (e: ApiError) => void;

type ProtoError = {
  error: string;
};

const extraGoodHttpStatuses = new Set<number>([204]);
const unauthorizedHttpStatuses = new Set<number>([401]);

function processFormData(form: FormDataEntry[]) {
  if (!Array.isArray(form)) return null;
  const res = new FormData();
  for (const entry of form) {
    res.append(entry.name, entry.value);
  }
  return res;
}

class Api {
  private timeout: number;

  private errorHandler?: ErrorHandler;

  constructor() {
    this.timeout = 60 * 1000;
  }

  call<Type extends ApiHandlerTypes>(
    type: Type,
    params: ApiHandlerParams<Type>,
): Promise<ApiHandlerResponse<Type>>;
  call(type: any, params: any): any {
    const h = getHandler(type);
    const req = h.prepare(params);
    const a = this.makeRequest(req).then((data: any) => h.decode(data));
    if (this.errorHandler) a.catch(this.errorHandler);
    return a;
  }

  private executeFetch(url: string, params: Request, auth: { accessToken: string }): Promise<any> {
    const headers: Record<string, string> = {
      ...params.headers,
    };

    if (!!auth.accessToken) {
      headers['Authorization'] = `Bearer ${auth.accessToken}`;
    }

    const jsonData = params.data ? JSON.stringify(params.data) : processFormData(params.form);

    if (params.data) {
      headers['Content-Type'] = 'application/json';
    }

    const init: RequestInit = {
      method: params.method,
      headers,
      body: jsonData,
    };

    const abortController = new AbortController();
    init.signal = abortController.signal;

    let timeoutHandle: ReturnType<typeof setTimeout> | undefined = setTimeout(
      () => abortController.abort(),
      this.timeout
    );
    const cleanupTimeout = () => {
      if (!timeoutHandle) return;
      clearTimeout(timeoutHandle);
      timeoutHandle = undefined;
    };

    // TODO: appProvider.application.logger.info('lib request', url, init);

    return fetch(url, init)
      .then(res => {
        if (res.ok || extraGoodHttpStatuses.has(res.status)) {
          const contentType = res.headers.get('Content-Type');
          const contentLength = res.headers.get('content-length');

          if (contentType === 'text/plain' || contentLength === '0') {
            return Promise.resolve({});
          }
          return res.json().then(r => {
            const responseData: any = r.data || { data: r } || {};
            return responseData;
          });
        }

        if (unauthorizedHttpStatuses.has(res.status)) {
          localStorage.removeItem(STORAGE_KEYS.AUTH);
          localStorage.removeItem(STORAGE_KEYS.USER);
          document.location.reload();
          return Promise.resolve({});
        }

        const err = new ApiError('NetworkError');
        err.httpStatus = res.status;
        err.httpStatusText = res.statusText;
        err.message = '';

        return res.text().then((text: string) => {
          try {
            const data = JSON.parse(text) as ProtoError;

            const error = data;
            const errorDetail: Record<string, string> = {};
            for (const i in error) {
              if (Object.prototype.hasOwnProperty.call(error, i)) {
                const e = error[i];
                if (e instanceof Array) {
                  err.message = `${i}:${e.join(';')}`;
                  errorDetail[i] = e.join(';');
                } else {
                  errorDetail[i] = e;
                }
              }
            }
            if (Object.prototype.hasOwnProperty.call(errorDetail, 'detail')) {
              err.message = errorDetail.detail;
            }
            err.details = errorDetail;
            err.errObj = errorDetail;
          } catch (e) {
            err.message = text;
          }
          throw err;
        });
      })
      .catch((e: Error) => {
        if (isApiError(e)) {
          throw e
        };
        throw new ApiError('NetworkError', e.toString());
      })
      .finally(() => {
        cleanupTimeout();
      });
  }

  private makeRequest(params: Request): Promise<any> {
    const url = `${API_URL}${params.path}`;
    let auth = JSON.parse(localStorage.getItem(STORAGE_KEYS.AUTH) || '{}');

    if (auth.accessToken) {
      const currentDate = new Date();

      if (auth.expiresIn < currentDate.getTime()) {
        const headers: Record<string, string> = {
          ...params.headers,
        };

        // if (!!auth.accessToken) {
        //   headers['Authorization'] = `${auth.refreshToken}`;
        // }

        const refreshInit: RequestInit = {
          method: 'GET',
          headers,
        };

        return fetch(`${API_URL}/api/Login/RefreshTokenDetails?RefreshToken=${auth.refreshToken}`, refreshInit)
          .then((res: any) => {
            return res.json().then((r: { access_token: string, refresh_token: string, expires_in: string }) => {

              const currentDate = new Date();
              auth = {
                accessToken: r.access_token,
                refreshToken: r.refresh_token,
                expiresIn: currentDate.getTime() + r.expires_in
              };

              if (auth.accessToken) {
                localStorage.setItem(STORAGE_KEYS.AUTH, JSON.stringify(auth));
                return this.executeFetch(url, params, auth);
              } else {
                localStorage.removeItem(STORAGE_KEYS.AUTH);
                localStorage.removeItem(STORAGE_KEYS.USER);
                document.location.reload();
                throw new ApiError('NetworkError');
              }

            });
          })
          .catch((e: Error) => {
            if (isApiError(e)) throw e;
            localStorage.removeItem(STORAGE_KEYS.AUTH);
            localStorage.removeItem(STORAGE_KEYS.USER);
            document.location.reload();
            throw new ApiError('NetworkError', e.toString());
          });
      }
    }

    return this.executeFetch(url, params, auth);
  }
}

export default new Api();
