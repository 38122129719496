import React, {useCallback, useEffect, useState} from 'react';
import {
  MainContent,
} from '../../elements';
import {
  ExercisesContent,
} from "./ProcessingTasks.Styles";
import {AppStateType} from "../../store";
import {connect} from "react-redux";
import {UserReducerState} from "../../store/user/reducers";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useBackgroundAudio from "../../hooks/useBackgroundAudio";
import API from "../../api/executor";
import {ApiHandlerResponse} from "../../api/calls";

export interface Props {
  user: UserReducerState;
}
const ProcessingTasks: React.FC<Props> = (props: Props) => {
  const { user } = props;
  const { task } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [taskObj, setTaskObj] = useState<any>();
  const { audio, audioLink, setAudio, setAudioLink } = useBackgroundAudio();


  useEffect(() => {
    if (user.userData?.isAdmin) {
      if (!loading && !loaded) {
        setLoaded(true);
        handleGetListeningExtTask(user.userData.id || 0).then(() => {

        })
      }
    }
  }, [user, loading, loaded]);

  const handleGetListeningExtTask = async (userId: number) => {
    const getListeningExtTaskData: { userId: number, taskId: string } = {
      userId,
      taskId: task || '',
    }

    setLoading(true);

    const sendListeningExtTask = (payload: { userId: number, taskId: string }) => API.call('getListeningExtTask', payload);


    await sendListeningExtTask(getListeningExtTaskData).then((answer: ApiHandlerResponse<'getListeningExtTask'>) => {
      console.log('answer', answer);
      if (answer) {
        setTaskObj(answer);
      }
    }).catch((error: any) => {
      console.log('error', error);
    }).finally(() => {
      setLoading(false);
    });
  };

  const playFileLink = useCallback(
    (e: React.ChangeEvent<any>, file: string) => {
      e.preventDefault();
      e.stopPropagation();
      if (audio) {
        audio.pause();
      }
      const newAudio: HTMLAudioElement = new Audio(file);
      newAudio.play();
      setAudio(newAudio);
      setAudioLink(file);
    }, [audio]
  );

  const stopFileLink = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      e.stopPropagation();
      audio?.pause();
      setAudio(null);
      setAudioLink(null);
    }, [audio]
  );

  return (
    <>
      <MainContent className="content-main home-page">
        <ExercisesContent className="features">
        </ExercisesContent>
      </MainContent>
    </>
  );
};

const mapState = (state: AppStateType) => {
  const { user } = state;
  return {
    user,
  };
};

export default connect(mapState)(ProcessingTasks);


