import styled from 'styled-components';

export const HeaderComponent: any = styled.header`
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: var(--poppins);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.06) 71.01%,
            rgba(255, 255, 255, 0) 99.52%
    );
  }

  .user-info {
    margin-right: 20px;
  }
  
  .header {
    display: flex;
    flex: 1;
    width: 100%;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    
    &-learning {
      margin-left: auto;
      
      &__title {
        color: var(--red);
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        margin-right: 0.5em;
      }
    }
    
    &-lang {
      margin-left: auto;
    }

    &-logo {
      display: none;
      width: 9rem;
      height: 3rem;

      &__link {
        font-family: Pacifico, sans-serif;
        font-size: 34px;
        line-height: 46px;
        color: var(--red);
      }
    }

    &-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      color: var(--black);

      &__wrap {

      }
    }
    
    &-logout {
      transition: all 0.3s;
      background-color: transparent;
      border: 0;
      outline: none;
      cursor: pointer;

      svg {
        fill: var(--black);
        overflow: visible;
        transition: fill 0.3s;

        .arrow {
          transition: transform 0.3s ease-in-out;
          transform: translate(0, 0);
          -webkit-transform: translate(0, 0);
        }

        .border {
          transition: transform 0.3s ease-in-out;
          transform: translate(0, 0);
          -webkit-transform: translate(0, 0);
        }
      }

      &:hover,
      &:focus {
        filter: blur(1px);

        svg {
          fill: var(--red);
          .arrow {
            -webkit-transform: translate(10, 0);
            transform: translate(10%, 0);
          }
          .border {
            -webkit-transform: translate(-10%, 0);
            transform: translate(-10%, 0);
          }
        }
      }
    }
  }

  @media only screen and (max-width: 40.0625em) {
    .header {
      padding: 0.5rem 1rem;
      &-logo {
        display: block;
      }

      &-lang {
        margin-right: 10px;
      }

      &-title {
        &__wrap {
          display: none;
        }
      }

      &-logout {
        display: none;
      }
    }

    .user-info {
      margin-right: 0;
    }
  }
`;

export const Burger: any = styled.button`
  display: none;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  z-index: 99;
  cursor: pointer;

  svg {
    width: 40px;
  }

  .line {
    fill: none;
    stroke: var(--black);
    stroke-width: 4;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }

  &.active {
    .line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 6;
    }
    .line2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -40;
      stroke-width: 6;
    }
    .line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 6;
    }
  }
`;

export const UserBar: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;


  &.sidebar__user {
    display: flex;
    max-width: 100%;
    margin-bottom: 44px;

    .user-info__img {
      width: 50px;
      height: 50px;
      margin-right: 15px;
    }

    .user-info__name {
      span {
        display: block;
      }
    }
  }
`;
