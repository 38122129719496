import styled from 'styled-components';

export const MainContentBlock: any = styled.main`
  width: 100%;
  min-height: calc(100vh - 330px);
  flex: 1 1 auto;
  position: relative;

  &.content-main_bg-1 {
    background: url('/img/bg/bg_1.svg') bottom right / contain no-repeat;
  }

  &.content-main_bg-2 {
    background: url('/img/bg/bg_2.svg') right bottom / 65% no-repeat;
  }

  &.content-main_bg-3 {
    background: url('/img/bg/bg_3.svg') right bottom / 50% no-repeat;
  }
  
  & .home-title {
    margin: 0 0 45px;
    font-size: 25px;
    line-height: 30px;
    color: var(--black);
    font-weight: 700;

    @media (max-width: 768px) {
      margin: 0 0 25px;
      font-size: 14px;
      line-height: 17px;
    }
  }

  @media (max-width: 20em) {
    min-height: calc(120vh - 330px);
  }
`;
