import styled from 'styled-components';

export const Progress: any = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  progress {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    left: -777px;
  }
`;

export const ProgressBg: any = styled.div`
  background: #414753;
  position: relative;
  height: 100%;
  border-radius: var(--border-radius);
  overflow: hidden;
`;

export const ProgressPanel: any = styled.div`
  overflow: hidden;
  background: #494f5b;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  transition: width 1s linear;
`;
