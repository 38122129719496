import styled from 'styled-components';

export const FeaturesContent: any = styled.div`
  height: 100%;
  padding-top: 50px;
  .features {
    &-list {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      margin-bottom: 0;
      border-radius: var(--border-radius);
    
      &__item {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        color: var(--black);
        text-decoration: none;
        background-color: var(--white);
        border: 1px solid var(--gray);
        width: 100%;
        text-transform: none;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        text-align: left;

        &:last-child {
          border-top-right-radius: var(--border-radius);
          border-top-left-radius: var(--border-radius);
        }

        &:last-child {
          border-bottom-right-radius: var(--border-radius);
          border-bottom-left-radius: var(--border-radius);
        }

        & + .features-list__item {
          border-top-width: 0;
        }
      }
    }
  }
`;

export const RoadmapMobile = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin-bottom: 38px;
  
  @media (max-width: 40.0625em) {
    flex-direction: row;
  }
`;

export const RoadmapRowMobile = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${({ gap }) => gap ||  '12%'};

  @media (max-width: 40.0625em) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
    margin: 36px 0;

    &.-desktop {
      display: none;
    }
  }
`;

export const RoadmapLineMobile = styled.div`
  display: flex;
  width: 100%;
  height: 2px;
  background: radial-gradient(circle, var(--red) 0%, rgba(255,255,255,0) 100%);
  margin-top: 51px;
  margin-bottom: 48px;
  
  &.-mobile {
    display: none;
  }

  @media (max-width: 40.0625em) {
    width: 2px;
    height: auto;
    background: linear-gradient( rgba(255,255,255,0) 0%, var(--red) 50.52%, rgba(255,255,255,0) 100% );
    margin-left: 29px;
    margin-right: 37px;

    &.-mobile {
      display: flex;
    }

    &.-desktop {
      display: none;
    }
  }
`;

export const RoadmapPostMobile = styled.div<{ bottomPost?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 200px;
  background: var(--light-blue);
  border-radius: 6px;
  text-align: left;
  padding: 20px 15px;
  
  &.roadmap-auto {
    height: 100%;
  }

  .roadmap-title {
    text-transform: none;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    text-align: left;
    color: var(--black);
    margin-bottom: 5px;
  }

  .roadmap-text {
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    text-align: left;
    color: var(--black);
  }
  .roadmap-list {
    & li {
      margin-bottom: 5px;
      
      &:before {
        content: '●';
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        text-align: left;
        color: var(--red);
        margin-right: 5px;
      }
    }
  }

  &.-mobile {
    display: none;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    right: auto;
    left: 22px;
    border: 17px solid transparent;
    border-top-color: ${({ bottomPost }) => (bottomPost ? 'transparent' : '#F0F6FF')};
    border-bottom-color: ${({ bottomPost }) => (bottomPost ? '#F0F6FF' : 'transparent')};
    bottom: ${({ bottomPost }) => (bottomPost ? 'auto' : '-32px')};
    top: ${({ bottomPost }) => (bottomPost ? '-32px' : 'auto')};
  }

  @media (max-width: 40.0625em) {
    position: relative;
    width: 253px;
    background: var(--light-blue);
    border-radius: 6px;
    text-align: left;
    padding: 28px 25px;

    &.-mobile {
      display: flex;
    }

    &.-desktop {
      display: none;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: auto;
      left: -33px;
      border: 17px solid transparent;
      border-right-color: var(--light-blue);
      top: 32px;
    }
  }
`;

export const RoadmapPostDotMobile = styled.div<{ bottomPost?: boolean }>`
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  background: var(--red);
  border-radius: 50%;
  bottom: ${({ bottomPost }) => (bottomPost ? 'auto' : '-57px')};
  top: ${({ bottomPost }) => (bottomPost ? '-55px' : 'auto')};
  left: 32px;

  @media (max-width: 40.0625em) {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    background: var(--red);
    border-radius: 50%;
    left: -43px;
    top: 44px;
  }
`;

export const RoadmapPostDotWhiteMobile = styled.div<{ bottomPost?: boolean }>`
  position: absolute;
  display: block;
  width: 6px;
  height: 6px;
  background: var(--light-blue);
  border-radius: 50%;
  bottom: ${({ bottomPost }) => (bottomPost ? 'auto' : '-55px')};
  top: ${({ bottomPost }) => (bottomPost ? '-53px' : 'auto')};
  left: 34px;

  @media (max-width: 40.0625em) {
    position: absolute;
    display: block;
    width: 6px;
    height: 6px;
    background: var(--light-blue);
    border-radius: 50%;
    left: -41px;
    top: 46px;
  }
`;
