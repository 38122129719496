export enum EXERCISES_SKILLS {
  READING = 'reading',
  WRITING = 'writing',
  LISTENING = 'listening',
  SPEAKING = 'speaking',
}

export enum EXERCISES_TYPES {
  TRUE_FALSE = 1,
  GOAL = 2,
  MISSING = 3,
  MULTIPLE = 4,
  FIND_INFORMATION = 5,
  QUESTIONS = 6,
  PREPOSITION = 7,
  GERUND_INFINITIVE = 8,
  LISTENING_TRUE_FALSE = 100,
  LISTENING_GOAL = 101,
  LISTENING_MISSING = 102,
  LISTENING_MULTIPLE = 103,
  LISTENING_FIND_INFORMATION = 104,
  LISTENING_QUESTIONS = 105,
  LISTENING_PREPOSITION = 106,
  GERUND_GERUND_INFINITIVE = 107,
}

export enum BASE_EXERCISES_ALIAS {
  TRUE_FALSE = 'true-false',
  GOAL = 'goal',
  MISSING = 'missing',
  MULTIPLE = 'multiple',
  FIND_INFORMATION = 'find-information',
  MATCH_THE_HEADINGS = 'match-the-headings',
  QUESTIONS = 'questions',
  PREPOSITION = 'preposition',
  GERUND_INFINITIVE = 'gerund-infinitive',
  LISTENING_TRUE_FALSE = 'true-false',
  LISTENING_GOAL = 'goal',
  LISTENING_MISSING = 'missing',
  LISTENING_MULTIPLE = 'multiple',
  LISTENING_FIND_INFORMATION = 'find-information',
  LISTENING_MATCH_THE_HEADINGS = 'match-the-headings',
  LISTENING_QUESTIONS = 'questions',
  LISTENING_PREPOSITION = 'preposition',
  LISTENING_GERUND_INFINITIVE = 'gerund-infinitive',
}

export enum EXERCISES_ALIAS {
  TRUE_FALSE = 'true-false',
  GOAL = 'goal',
  MISSING = 'missing',
  MULTIPLE = 'multiple',
  FIND_INFORMATION = 'find-information',
  MATCH_THE_HEADINGS = 'match-the-headings',
  QUESTIONS = 'questions',
  PREPOSITION = 'preposition',
  GERUND_INFINITIVE = 'gerund-infinitive',
  LISTENING_TRUE_FALSE = 'listening-true-false',
  LISTENING_GOAL = 'listening-goal',
  LISTENING_MISSING = 'listening-missing',
  LISTENING_MULTIPLE = 'listening-multiple',
  LISTENING_FIND_INFORMATION = 'listening-find-information',
  LISTENING_MATCH_THE_HEADINGS = 'listening-match-the-headings',
  LISTENING_QUESTIONS = 'listening-questions',
  LISTENING_PREPOSITION = 'listening-preposition',
  LISTENING_GERUND_INFINITIVE = 'listening-gerund-infinitive',
}

export const TASK_ALIAS_BY_SLUG: any = {
  'true-false': 'TRUE_FALSE',
  'goal': 'GOAL',
  'missing': 'MISSING',
  'multiple': 'MULTIPLE',
  'find-information': 'FIND_INFORMATION',
  'questions': 'QUESTIONS',
  'preposition': 'PREPOSITION',
  'gerund-infinitive': 'GERUND_INFINITIVE',
  'listening-true-false': 'LISTENING_TRUE_FALSE',
  'listening-goal': 'LISTENING_GOAL',
  'listening-missing': 'LISTENING_MISSING',
  'listening-multiple': 'LISTENING_MULTIPLE',
  'listening-find-information': 'LISTENING_FIND_INFORMATION',
  'listening-questions': 'LISTENING_QUESTIONS',
  'listening-preposition': 'LISTENING_PREPOSITION',
  'listening-gerund-infinitive': 'LISTENING_GERUND_INFINITIVE'
};

export const TASK_ID_BY_SLUG: any = {
  'true-false': 1,
  'goal': 2,
  'missing': 3,
  'multiple': 4,
  'find-information': 5,
  'questions': 6,
  'preposition': 7,
  'gerund-infinitive': 8,
  'listening-true-false': 100,
  'listening-goal': 101,
  'listening-missing': 102,
  'listening-multiple': 103,
  'listening-find-information': 104,
  'listening-questions': 105,
  'listening-preposition': 106,
  'listening-gerund-infinitive': 107
};

export const TASK_DATA_BY_ID: any = {
  1: 'TRUE_FALSE',
  2: 'GOAL',
  3: 'MISSING',
  4: 'MULTIPLE',
  5: 'FIND_INFORMATION',
  6: 'QUESTIONS',
  7: 'PREPOSITION',
  8: 'GERUND_INFINITIVE',
};

export const COMMON_TASK_DATA_BY_ID: any = {
  1: 'TRUE_FALSE',
  2: 'GOAL',
  3: 'MISSING',
  4: 'MULTIPLE',
  5: 'FIND_INFORMATION',
  6: 'QUESTIONS',
  7: 'PREPOSITION',
  8: 'GERUND_INFINITIVE',
  100: 'LISTENING_TRUE_FALSE',
  101: 'LISTENING_GOAL',
  102: 'LISTENING_MISSING',
  103: 'LISTENING_MULTIPLE',
  104: 'LISTENING_FIND_INFORMATION',
  105: 'LISTENING_QUESTIONS',
  106: 'LISTENING_PREPOSITION',
  107: 'LISTENING_GERUND_INFINITIVE'
};

export const READING_TASK_DATA_BY_ID: any = {
  1: 'TRUE_FALSE',
  2: 'GOAL',
  3: 'MISSING',
  4: 'MULTIPLE',
  5: 'FIND_INFORMATION',
  6: 'QUESTIONS',
  7: 'PREPOSITION',
  8: 'GERUND_INFINITIVE',
};

export const LISTENING_TASK_DATA_BY_ID: any = {
  100: 'LISTENING_TRUE_FALSE',
  101: 'LISTENING_GOAL',
  102: 'LISTENING_MISSING',
  103: 'LISTENING_MULTIPLE',
  104: 'LISTENING_FIND_INFORMATION',
  105: 'LISTENING_QUESTIONS',
  106: 'LISTENING_PREPOSITION',
  107: 'LISTENING_GERUND_INFINITIVE'
};

export const DEFAULT_USER = 72;

export enum EXERCISES_DOMAINS {
  SCIENCE = 'science',
  BUSINESS = 'business',
  CULTURE = 'culture',
}

export const EXERCISES_DOMAINS_BY_ID: any = {
  2: 'SCIENCE',
  3: 'BUSINESS',
  4: 'CULTURE',
};

export const EXERCISES_DOMAINS_ID_BY_SLUG: any = {
  'science': 2,
  'business': 3,
  'culture': 4,
};


export enum EXERCISES_LEVELS {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2',
}

export const EXERCISES_LEVELS_ARR =  [
  {
    name: 'A1',
    id: 'A1'
  }
]

export const EXERCISES_LEVELS_BY_ID: any = {
  3: 'A1',
};
