import React from 'react';

import {
  InfoItemContainer,
  InfoItemText,
  InfoItemDivider,
  InfoItemValue,
} from './InfoItem.Styles';

interface Props {
  className?: string;
  infoText?: string;
  infoValue?: any;
}

export const InfoItem: React.FC<Props> = ({ className, infoText, infoValue }) => {
  return (
    <InfoItemContainer className={`info-item ${className}`}>
      <InfoItemText className="info-item__text">{infoText}</InfoItemText>
      <InfoItemDivider className="info-item__divider" />
      <InfoItemValue className="info-item__value">{infoValue}</InfoItemValue>
    </InfoItemContainer>
  );
};
