import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from "../../const/paths.constants";
import { MobileMenuComponent } from './MobileMenuComponent.Styles';
import { AppStateType } from '../../store';
import { setMobileMenuOpen } from "../../store/app/actions";
import {useTranslation} from "react-i18next";
import {UserReducerState} from "../../store/user/reducers";

type MobileMenuProps = {
  user: UserReducerState;
  mobileMenuOpened: boolean;
  setMobileMenuOpen: (value: boolean) => void;
};

const MobileMenu: React.FC<MobileMenuProps> = (props: MobileMenuProps) => {
  const { user, mobileMenuOpened, setMobileMenuOpen } = props;
  const { t } = useTranslation();

  return (
    <MobileMenuComponent id="mobileMenu" className={`off-canvas position-right is-transition-push ${mobileMenuOpened ? 'is-open' : 'is-closed'}`}>
      <div className="is-drilldown">
        <ul className="menu">
          <li className="nav-link">
            <Link
              className="link"
              to={PATHS.FEATURES}
              onClick={() => setMobileMenuOpen(false)}
            >{t('header.roadmap')}</Link>
          </li>

          <li className="nav-link">
            <Link
              className="link"
              to={PATHS.BLOG}
              onClick={() => setMobileMenuOpen(false)}
            >{t('header.blog')}</Link>
          </li>

          <li className="nav-link">
            <Link
              className="link"
              to={PATHS.JOTTER}
              onClick={() => setMobileMenuOpen(false)}
            >JOTTER</Link>
          </li>

          <li className="nav-link">
            <Link
              className="link"
              to={PATHS.SYNCHRONIST}
              onClick={() => setMobileMenuOpen(false)}
            >SYNCHRONIST</Link>
          </li>
        </ul>

        <div className="btns">
          {
            user.loggedIn ? (
              <div className="btn-wrap">
                <Link
                  className="btn -full"
                  to={PATHS.DASHBOARD}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  {t('header.dashboard')}
                </Link>
              </div>
            ) : (
              <>
                <div className="btn-wrap">
                  <Link
                    className="btn"
                    to={PATHS.LOGIN}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {t('footer.login')}
                  </Link>
                </div>
                <div className="btn-wrap">
                  <Link
                    className="btn -full"
                    to={PATHS.REGISTRATION}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {t('footer.signup')}
                  </Link>
                </div>
              </>
            )
          }
        </div>
      </div>
    </MobileMenuComponent>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { app, user } = state;
  return {
    user,
    mobileMenuOpened: app.mobileMenuOpened,
  };
};

export default connect(mapStateToProps, { setMobileMenuOpen })(MobileMenu);
