import cloneDeep from 'lodash/cloneDeep';
import types, { AppActionTypes } from '../actionTypes';
import orderBy from 'lodash/orderBy';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import {Shedule} from "../../api";
import {TASK_DATA_BY_ID} from "../../const/exercises.constants";
import _ from "lodash";

export type PracticeOption = {
  name: string;
  current: boolean;
  selected: boolean;
  explanation: string
};

export type PracticeQuestions = {
  title: string;
  error: boolean;
  options: PracticeOption[];
  answer?: string;
};

export type PracticeTaskLevel = {
  filelink: string;
  title: string;
  text: string;
  level: string;
};

export type PracticeTask = {
  id: number;
  title: string;
  text: string;
  source: string;
  levelId: number;
  topicId: number;
  taskId: number;
  fileLink: string;
  questions: PracticeQuestions[];
  completed?: boolean;
  trueResult?: number;
  falseResult?: number;
  factTimeSec?: number;
  author?: string;
  levelB?: PracticeTaskLevel;
  levelZero?: PracticeTaskLevel;
  taskTextTranslate: '';
  taskTextSimplificate: '';
  tLang: '';
  listeningTask: number;
};

export type PracticeReducerState = {
  tasks: {
    list: PracticeTask[];
    page: number;
    loaded: boolean;
    loadedType: number | undefined;
    languageId: number;
  };
  shedulets: {
    list: Shedule[];
    loaded: boolean;
    languageId: number;
  };
};

const INITIAL_STATE: PracticeReducerState = {
  tasks: {
    list: [],
    page: 1,
    loaded: false,
    loadedType: 0,
    languageId: 0
  },
  shedulets: {
    list: [],
    loaded: false,
    languageId: 0
  }
};

// const toNormalCreatedTasks = (tasks: any) => {
//   return tasks.map((task: any) => {
//     const levelZero = _.find(task.exttask, {level: 'O'});
//     const levelB = _.find(task.exttask, {level: 'B'});
//     return {
//       id: task.Id,
//       title: task.Title.replace(/&amp;quot;/g, '"').replace(/&#x27/g, "'").replace(/&#39;/g, "'"),
//       text: task.Text.replace(/&amp;quot;/g, '"').replace(/&#x27/g, "'").replace(/&#39;/g, "'"),
//       levelId: 3, // task.LevelId,
//       topicId: task.TopicId,
//       taskId: task.TaskType,
//       fileLink: task.FileLink,
//       author: task.exttask[0]?.author,
//       levelZero: levelZero ? levelZero : null,
//       levelB: levelB ? levelB : null,
//       source: task.Source,
//       questions: task.Question.length ? task.Question.map((quest: any) => {
//         const options = quest.Options.length ? quest.Options.map((opt: any) => {
//           return {
//             name: opt?.Option.replace(/&amp;quot;/g, '"').replace(/&#x27/g, "'").replace(/&#39;/g, "'") || '',
//             current: !!opt?.Answer,
//             selected: false,
//             explanation: opt?.Explanation
//           }
//         }) : [];
//
//         return {
//           title: quest.Title ? quest.Title.replace(/&amp;quot;/g, '"').replace(/&#x27/g, "'").replace(/&#39;/g, "'") : '',
//           error: false,
//           options,
//         }
//       }) : [],
//     }
//   });
// }

const toNormalTasks = (tasks: any) => {
  return tasks.map((task: any) => {
    const levelZero = _.find(task.exttask, {level: 'O'});
    const levelB = _.find(task.exttask, {level: 'B'});
    return {
      id: task.Id,
      title: task.task_title.replace(/&amp;quot;/g, '"').replace(/&#x27/g, "'").replace(/&#39;/g, "'"),
      text: task.task_text.replace(/&amp;quot;/g, '"').replace(/&#x27/g, "'").replace(/&#39;/g, "'"),
      levelId: 3, // task.level_id_id,
      topicId: task.topic_id_id,
      taskId: task.task_type,
      fileLink: task.filelink,
      author: task.exttask[0]?.author,
      levelZero: levelZero ? levelZero : null,
      levelB: levelB ? levelB : null,
      source: task.task_source,
      listeningTask: task.listeningtask,
      questions: task.questions.length ? task.questions.map((quest: any) => {
        const options = quest.Options.length ? quest.Options.map((opt: any) => {
          return {
            name: opt?.Option.replace(/&amp;quot;/g, '"').replace(/&#x27/g, "'").replace(/&#39;/g, "'") || '',
            current: !!opt?.Answer,
            selected: false,
            explanation: opt?.Explanation
          }
        }) : [];

        return {
          title: quest.Title ? quest.Title.replace(/&amp;quot;/g, '"').replace(/&#x27/g, "'").replace(/&#39;/g, "'") : '',
          error: false,
          options,
        }
      }) : [],
      completed: !!task.IsCompleted,
      trueResult: task.TrueResult ? task.TrueResult : 0,
      falseResult: task.FalseResult ? task.FalseResult : 0,
      factTimeSec: task.FactTimeSec ? task.FactTimeSec : 0,
      taskTextTranslate: '',
      taskTextSimplificate: '',
      tLang: ''
    }
  });
}

const toNormalShedulets = (shedulets: any): Shedule[] => {
  return shedulets.map((shedule: any) => {
    return {
      id: shedule.Id,
      userId: shedule.UserId,
      taskId: shedule.TaskId,
      eventTitle: shedule.EventTitle,
      eventDate: shedule.EventDate,
      creationDate: shedule.CreationDate,
      isNotification: shedule.IsNotification,
      reflection: shedule.Reflection,
      notificationFrequensy: shedule.NotificationFrequensy,
      description: shedule.TaskTitle,
      taskType: TASK_DATA_BY_ID[shedule.TaskTypeId]
    }
  });
}

const practiceReducers = (state = INITIAL_STATE, action: AppActionTypes): PracticeReducerState => {
  switch (action.type) {
    case types.GET_TASK_REQUEST:
      const getTasksRequest = cloneDeep(state.tasks);
      getTasksRequest.loaded = true;
      getTasksRequest.loadedType = action.payload?.Task_Type;
      getTasksRequest.languageId = action.payload?.languageId || 0;
      return {
        ...state,
        tasks: getTasksRequest,
      };
    case types.GET_TASK_SUCCESS:
      const getTasksSuccess = cloneDeep(state.tasks);
      const newTaskList = toNormalTasks(action.payload);
      const newTaskListOrdered = orderBy(newTaskList, 'id', 'desc');

      const completedTasks = newTaskListOrdered.filter((task: any) => !!task.completed);
      const unCompletedTasks = newTaskListOrdered.filter((task: any) => !task.completed);
      const taskList = [...unCompletedTasks, ...completedTasks];

      getTasksSuccess.list = taskList || getTasksSuccess.list;
      return {
        ...state,
        tasks: getTasksSuccess,
      };

    case types.GET_TASK_FAILURE:
      return {
        ...state,
      };
    case types.CREATE_TASK_REQUEST:
      return {
        ...state,
      };
    case types.CREATE_TASK_SUCCESS:
      const newTasksSuccess = cloneDeep(state.tasks);
      const newTasks = toNormalTasks([action.payload]);
      newTasksSuccess.list = [...newTasks, ...newTasksSuccess.list];
      newTasksSuccess.loadedType = action.payload?.TaskType;
      return {
        ...state,
        tasks: newTasksSuccess,
      };

    case types.CREATE_TASK_FAILURE:
      return {
        ...state,
      };

    case types.TASK_RESULT_REQUEST:
      return {
        ...state,
      };

    case types.TASK_RESULT_SUCCESS:
      const newTaskResultSuccess = cloneDeep(state.tasks);
      const taskResult = action.payload;
      const taskIndex = findIndex(newTaskResultSuccess.list, (task) => task.id === taskResult.taskId)
      newTaskResultSuccess.list[taskIndex].completed = true;
      newTaskResultSuccess.list[taskIndex].trueResult = taskResult.trueResult;
      newTaskResultSuccess.list[taskIndex].falseResult = taskResult.falseResult;
      newTaskResultSuccess.list[taskIndex].factTimeSec = taskResult.factTimeSec;

      return {
        ...state,
        tasks: newTaskResultSuccess,
      };

    case types.TASK_RESULT_FAILURE:
      return {
        ...state,
      };

    case types.GET_SHEDULETS_REQUEST:
      const getSheduletsRequest = cloneDeep(state.shedulets);
      getSheduletsRequest.loaded = true;
      getSheduletsRequest.languageId = action.payload.Language_id;
      return {
        ...state,
        shedulets: getSheduletsRequest
      };

    case types.GET_SHEDULETS_SUCCESS:
      const getSheduletsSuccess = cloneDeep(state.shedulets);
      getSheduletsSuccess.list = toNormalShedulets(action.payload);
      return {
        ...state,
        shedulets: getSheduletsSuccess,
      };

    case types.GET_SHEDULETS_FAILURE:
      return {
        ...state,
      };
    case types.ADD_SHEDULETS_REQUEST:
      return {
        ...state,
      };

    case types.ADD_SHEDULETS_SUCCESS:
      const addSheduletsSuccess = cloneDeep(state.shedulets);
      addSheduletsSuccess.list = [...addSheduletsSuccess.list, ...toNormalShedulets([action.payload])];
      return {
        ...state,
        shedulets: addSheduletsSuccess
      };

    case types.ADD_SHEDULETS_FAILURE:
      return {
        ...state,
      };
    case types.UPDATE_SHEDULETS_REQUEST:
      return {
        ...state,
      };

    case types.UPDATE_SHEDULETS_SUCCESS:
      const updateSheduletsSuccess = cloneDeep(state.shedulets);
      const sheduleIndex = findIndex(updateSheduletsSuccess.list, (shedule) => shedule.id === action.payload.Id);
      updateSheduletsSuccess.list[sheduleIndex] = toNormalShedulets([action.payload])[0];

      return {
        ...state,
        shedulets: updateSheduletsSuccess,
      };

    case types.UPDATE_SHEDULETS_FAILURE:
      return {
        ...state,
      };
    case types.REMOVE_SHEDULETS_REQUEST:
      return {
        ...state,
      };

    case types.REMOVE_SHEDULETS_SUCCESS:
      const removeSheduletsSuccess = cloneDeep(state.shedulets);
      remove(removeSheduletsSuccess.list, {id: action.payload});
      return {
        ...state,
        shedulets: removeSheduletsSuccess,
      };

    case types.REMOVE_SHEDULETS_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default practiceReducers;
