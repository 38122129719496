import styled from 'styled-components';

export const ButtonContainer: any = styled.button`
  display: inline-block;
  text-align: center;
  color: var(--dark-gray);
  border-radius: var(--border-radius);
  border: 3px solid var(--red);
  padding: 12px 56px;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: var(--poppins);
  text-transform: uppercase;
  background: none;

  &:focus {
  }

  &:hover {
    color: var(--white);
    background-color: var(--dark);
    border: 3px solid var(--dark);
  }

  &:active {
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  &.loading-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
`;
