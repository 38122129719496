import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';

import {
  Menu,
  MenuList,
  MenuListItem,
  MenuListLink,
  SidebarWrapper,
} from './Sidebar.Styles';
import {PATHS} from "../../const/paths.constants";
import { Icon } from "../../elements";
import {UserReducerState} from "../../store/user/reducers";
import {AppStateType} from "../../store";
import {connect} from "react-redux";

type Props = {
  user: UserReducerState;
};
const Sidebar: React.FC<Props> = (props: Props) => {
  const { user } = props;
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <SidebarWrapper>
      <div className="logo" >
        <Link className="logo-link" to={PATHS.HOME}>Didactist</Link>
      </div>

      <Menu className="menu">
        <MenuList className="menu__list">
          <MenuListItem className={pathname === PATHS.DASHBOARD ? 'menu__item -active' : 'menu__item'}>
            <MenuListLink to={PATHS.DASHBOARD} className='menu__link'>
              <span className="menu__text">{t('navBar.dashboard')}</span>
            </MenuListLink>
          </MenuListItem>
          <MenuListItem className={pathname === PATHS.PRACTICE ? 'menu__item -active' : 'menu__item'}>
            <MenuListLink to={PATHS.PRACTICE} className='menu__link'>
              <span className="menu__text">{t('navBar.reading')}</span>
            </MenuListLink>
          </MenuListItem>
          <MenuListItem className={pathname === PATHS.LISTENING ? 'menu__item -active' : 'menu__item'}>
            <MenuListLink to={PATHS.LISTENING} className='menu__link'>
              <span className="menu__text">{t('navBar.listening')}</span>
            </MenuListLink>
          </MenuListItem>
          {
            !!user.userData?.isAdmin ? (
              <MenuListItem className={pathname === PATHS.PROCESSING ? 'menu__item -active' : 'menu__item'}>
                <MenuListLink to={PATHS.PROCESSING} className='menu__link'>
                  <span className="menu__text">{t('navBar.processing')}</span>
                </MenuListLink>
              </MenuListItem>
            ) : null
          }

          <MenuListItem
            className={
              pathname === '/settings'
                ? 'menu__item menu__item_settings -active'
                : 'menu__item menu__item_settings'
            }
          >
            <MenuListLink to="/settings" className="menu__link">
              <Icon name="settings" size="18" />
              <span className="menu__text">{t('navBar.settings')}</span>
            </MenuListLink>
          </MenuListItem>
        </MenuList>
      </Menu>
    </SidebarWrapper>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { user } = state;
  return {
    user,
  };
};
export default connect(mapStateToProps, {})(Sidebar)
