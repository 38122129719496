import styled from 'styled-components';

export const ExercisesContent: any = styled.div`

`;

export const CardContainer = styled.div`
  display: flex;
  margin: 0 0 15px;

  .task-grid {
    width: 100%;
  }
`;

export const Card = styled.div`
  position: relative;
  min-width: 220px;
  min-height: 290px;
  padding: 25px 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  background-color: var(--light-blue);
  border-radius: var(--border-radius);

  .task-item__btn {
    width: 100%;
  }
  
  .task-item__label {
    display: block;
    width: 100%;
    height: 58px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: var(--poppins);
    color: var(--dark-gray);
    background-color: transparent;
    min-height: 0 !important;
    line-height: 58px;
    text-transform: uppercase;
    transition: opacity 0.3s ease-in-out;
    transform: none;
    transform-origin: unset;
    top: 0;
    left: 0;
    max-width: 100%;
    margin-top: 1em;

    &.Mui-focused {
      opacity: 0;
      color: var(--dark-gray);
    }

    &.MuiFormLabel-filled {
      display: none;
    }
  }
  
  .plus {
    display: inline-block;
    height: 70px;
    width: 70px;
    position: relative;
    overflow: hidden;
    margin-bottom: 1rem;

    &:before, &:after {
      content: " ";
      position: absolute;
      background: var(--black);
      transition: all .5s ease .1s;
    }
    
    &:before {
      width: 2px;
      margin-left: -1px;
      left: 50%;
      top: 5px;
      bottom: 5px;
    }

    &:after {
      height: 2px;
      margin-top: -1px;
      top: 50%;
      left: 5px;
      right: 5px;
    }
    
    &-text {
      color: var(--black);
      transition: all .5s ease .1s;
    }
  }
  
  &:before {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: -1px;
    left: 0;
    visibility: hidden;
    background: var(--red);
    -webkit-transition: all .5s ease .1s;
    -o-transition: all .5s ease .1s;
    transition: all .5s ease .1s;
    transform: scale3d(0, 1, 0);
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 0;
    border-radius: var(--border-radius);
  }

  &.-add {
    .task-item__btn {
      display: flex;
      flex-direction: column;
      border: 0;
      width: 100%;
      height: 100%;
      z-index: 2;

      &:hover {
        background: none;
      }
    }
    
    &:hover {
      &:before {
        visibility: visible;
        transform: scale3d(1, 1, 1);
        opacity: 1;
      }

      .plus {
        &:before, &:after {
          background: var(--white);
          transform: scale3d(1, 1, 1);
        }

        &-text {
          color: var(--white);
          transform: scale3d(1, 1, 1);
        }
      }
    }
    
    &.-loading {
      pointer-events: none;
      align-items: center;
      justify-content: center;
      
      svg {
        width: 100px;
        height: 100px;
      }
    }
  }
  
  &.-config {
    .task-item__btn {
      background-color: var(--red);
      color: var(--white);
      
      &:hover {
        background-color: var(--dark);
        border: 3px solid var(--dark);
      }
    }
  }
  
  &.-completed {
    background-color: var(--dark-gray);
  }
`;

