import styled from 'styled-components';

export const ContainerBlock: any = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;

  @media only screen and (max-width: 40.0625em) {
    padding: 1rem;
    
    &.-no-border {
      padding: 1rem 0;
    } 
  }
`;
