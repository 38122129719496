import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '../Button/Button';

import {
  TaskCard,
  TaskCardBody,
  TaskCardContent,
  TaskCardProperty,
  TaskCardSubtitle,
  TaskCardTitle,
  TaskCardWrapper,
} from './TaskComponent.Styles';
import { Profitability } from "../ProfitComponent/ProfitComponent";

export type Task = Profitability & {
  redirectUrl: string;
  subTitle: string;
  valueText: string;
  description: string;
  className?: string;
};

interface Props {
  item: Task;
  className?: string;
  onClick?: () => void;
}

export const TaskComponent: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <TaskCardWrapper className={`task ${item.className}`}>
      <div className="overlay">
        <TaskCardTitle className="products-item__title">
          <React.Fragment>
            {t(item.title)}
          </React.Fragment>
        </TaskCardTitle>
        <p className="desc">{t(item.valueText)}</p>
        <p className="dev">{t('common.developing')}</p>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" className="blur">
        <defs>
          <filter id="blur">
            <feGaussianBlur stdDeviation="5" />
          </filter>
        </defs>
      </svg>
      <TaskCard className="task__item task-item">
        <TaskCardBody>
          <TaskCardTitle className="task-item__title">
            {t(item.title)}
          </TaskCardTitle>
          <TaskCardSubtitle className="task-item__subtitle">
            {t(item.subTitle)}
          </TaskCardSubtitle>

          <TaskCardContent className="task-item__content">
            <p>{t(item.valueText)}</p>
          </TaskCardContent>

          <TaskCardProperty className="task-item__property">
            {t(item.description)}
          </TaskCardProperty>
        </TaskCardBody>
        <Button
          as={Link}
          className="task-item__btn"
          to={item.redirectUrl}
        >
          {t('common.explore')}
        </Button>
      </TaskCard>
    </TaskCardWrapper>
  );
};
