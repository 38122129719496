import styled from 'styled-components';

export const UserInfoBlock: any = styled.div`
  display: flex;
  align-items: center;


  &.partners-box__user {
    margin-bottom: 30px;
  }

`;

export const UserInfoBox: any = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  font-family: var(--poppins);

  @media only screen and (max-width: 40.0625em) {
    display: none;
  }
`;

export const UserInfoImg: any = styled.div`
  flex: 0 0 auto;
  width: 60px;
  height: 60px;
  padding: 6px;
  margin-right: 28px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  @media only screen and (max-width: 40.0625em) {
    width: 3rem;
    height: 3rem;
    margin-right: 0;
  }
`;

export const UserInfoName: any = styled.div`
  margin: 0 0 7px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  span {
    display: block;
  }

  @media (max-width: 992px) {
    span {
      display: inline-block;
    }
  }
`;

export const UserInfoId: any = styled.span`
  display: inline-block;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #8a8c98;
`;
