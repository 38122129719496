import React, {useCallback, useEffect, useState} from 'react';
import {
  Button,
  Container,
  MainContent,
} from '../../elements';
import {useTranslation} from "react-i18next";
import {AppStateType} from "../../store";
import {connect} from "react-redux";
import {UserReducerState} from "../../store/user/reducers";
import API from "../../api/executor";
import {ApiHandlerResponse} from "../../api/calls";
import {
  CardFooter,
  CardList,
  CardListItem,
  CardListText,
  CardListValue,
  CardTitle,
  TasksContent
} from "../../components/Tasks/Tasks.Styles";
import {Card, CardContainer} from "./ProcessingTasks.Styles";
import {PATHS} from "../../const/paths.constants";
import useBackgroundAudio from "../../hooks/useBackgroundAudio";
import {useNavigate} from "react-router-dom";

export interface Props {
  user: UserReducerState;
}
const Processing: React.FC<Props> = (props: Props) => {
  const { user } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [tasks, setTasks] = useState<any[]>([]);
  const { audio, audioLink, setAudio, setAudioLink } = useBackgroundAudio();


  useEffect(() => {
    if (user.userData?.isAdmin) {
      if (!loading && !loaded) {
        setLoaded(true);
        handleGetListeningExtTasks(user.userData.id || 0).then(() => {

        })
      }
    }
  }, [user, loading, loaded]);

  const handleGetListeningExtTasks = async (userId: number) => {
    const getListeningExtTasksData: { userId: number } = {
      userId
    }

    setLoading(true);

    const sendListeningExtTasks = (payload: { userId: number }) => API.call('getListeningExtTasks', getListeningExtTasksData);


    await sendListeningExtTasks(getListeningExtTasksData).then((answer: ApiHandlerResponse<'getListeningExtTasks'>) => {
      console.log('answer', answer);
      if (answer.length) {
        setTasks(answer);
      }
    }).catch((error: any) => {
      console.log('error', error);
    }).finally(() => {
      setLoading(false);
    });
  };

  const playFileLink = useCallback(
    (e: React.ChangeEvent<any>, file: string) => {
      e.preventDefault();
      e.stopPropagation();
      if (audio) {
        audio.pause();
      }
      const newAudio: HTMLAudioElement = new Audio(file);
      newAudio.play();
      setAudio(newAudio);
      setAudioLink(file);
    }, [audio]
  );

  const stopFileLink = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      e.stopPropagation();
      audio?.pause();
      setAudio(null);
      setAudioLink(null);
    }, [audio]
  );

  const onSetExercise = (taskId: number) => {
    navigate(PATHS.PROCESSING_TASK.replace(':task', taskId.toString()));
  };

  return (
    <>
      <MainContent className="content-main home-page">
        <Container>
          <TasksContent className="tasks">
            <CardContainer>
              <div className="grid-x grid-margin-x task-grid">
                {
                  tasks.length ? tasks.map((task: any, index: number) => (
                    <Card
                      className={`cell small-12 xlarge-4 task-item`}
                      key={`generated-task-${index + 1}`}
                      onClick={() => onSetExercise(task.Id)}
                    >
                      <CardTitle>{task.Title}</CardTitle>
                      <CardList>
                        {
                          !!task.FileLink ? (
                            <CardListItem>
                              <CardListText>{t(`exercises.common.speaker`)}</CardListText>
                              <CardListValue>{task.Author}</CardListValue>
                            </CardListItem>
                          ) : null
                        }

                        {
                          !!task.FileLink ? (
                            <CardListItem>
                              <CardListText>{t(`exercises.common.original`)}</CardListText>
                              <CardListValue>
                                {
                                  audio && audio.src && audioLink === task.FileLink ? (
                                    <button className="speaker" onClick={(e) => stopFileLink(e)}>
                                      <img alt="speacker" src="/img/pause_icon.svg"/>
                                    </button>
                                  ) : (
                                    <button className="speaker" onClick={(e) => playFileLink(e, task.FileLink)}>
                                      <img alt="speacker" src="/img/speaker_icon.svg"/>
                                    </button>
                                  )
                                }

                              </CardListValue>
                            </CardListItem>
                          ) : null
                        }
                      </CardList>
                      <CardFooter>
                        <Button
                          className="task-item__btn md"
                        >
                          Edit task
                        </Button>
                      </CardFooter>
                    </Card>
                  )) : null
                }
              </div>
            </CardContainer>
          </TasksContent>
        </Container>
      </MainContent>
    </>
  );
};

const mapState = (state: AppStateType) => {
  const { user } = state;
  return {
    user,
  };
};

export default connect(mapState)(Processing);

