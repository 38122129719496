import styled from 'styled-components';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const CalendarPanel: any = styled.div`
  display: flex;
  padding: 1rem 1rem;
  background: rgb(237, 246, 255);
  box-shadow: rgba(0, 0, 0, 0.04) 0 0 11px;
  border-radius: var(--border-radius);

  .calendar {
    &-block {
      display: flex;
      flex-direction: column;
      
      &__calendar {
        display: flex;
        flex-direction: column;
      }
      
      &__btns {
        display: flex;
        gap: 12px;
        padding: 16px 24px;
        border-top: 1px solid var(--dark-white);
        margin-top: -5px;
      }
      
      &__btn {
        width: 100%;
        padding: 9px 24px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
    
    &-header {
      display: flex;
      gap: 12px;
      margin-top: 79px;
      justify-content: center;
      padding: 0 24px;
    }
    
    &-shortcuts {
      &__btn {
        background: var(--white);
        border: 1px solid var(--light-gray);
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: var(--border-radius);
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding: 9px 16px;
        color: var(--dark-gray);
        cursor: pointer;
      }
    }
  }
`;

export const CalendarMain: any = styled(DateCalendar)`
  .MuiPickersCalendarHeader-root {
    top: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    
    .MuiPickersCalendarHeader-labelContainer {
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;
      text-align: center;
      pointer-events: none;
    }
    
    .MuiPickersCalendarHeader-label {
      font-family: var(--inter);
      margin-right: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--dark-gray);
      text-transform: capitalize;
    }
    
    .MuiPickersCalendarHeader-switchViewButton {
      display: none;
    }
    
    .MuiPickersArrowSwitcher-root {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  
  .MuiPickersFadeTransitionGroup-root {
    .MuiPickersSlideTransition-root {
      min-height: 284px;
    }

    .MuiPickersArrowSwitcher-root {
      padding: 0 16px;
      padding-top: 22px;
      padding-bottom: 11px;

      .MuiTypography-root {
        font-family: var(--inter);
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--dark-gray);
      }
    }

    .MuiDayCalendar-header {
      margin-top: 13px;
      margin-bottom: 4px;
      
      .MuiTypography-root {
        font-family: var(--inter);
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-gray);
        height: 40px;
        text-transform: capitalize;
      }
    }

    .MuiDayCalendar-weekContainer {
      margin-bottom: 8px;
    }

    .MuiDayCalendar-monthContainer {
      .MuiPickersDay-root {
        font-family: var(--inter);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-gray);
        width: 36px;
        height: 36px;

        &.Mui-selected {
          background: var(--dark-gray);
          color: var(--white);

          &:hover {
            background: var(--dark-gray);
            color: var(--white);
          }
        }
        
        &.MuiPickersDay-today {
          background: var(--light-gray);
          color: #fff;
          border-color: transparent;

          &.Mui-selected {
            background: var(--dark-gray);
          }
        }
        
        &:hover {
          background: #EEF4FF;
          color: var(--dark-gray);
        }
      }
    }

    .MuiDateRangePickerDay-rangeIntervalDayHighlight {
      background: var(--light-blue);

      .MuiPickersDay-root {
        color: var(--dark-purple);
      }
    }

    .MuiDateRangePickerDay-hiddenDayFiller {
      background: var(--white);
    }

    .MuiDateRangeCalendar-root {
      > div:first-child {
        display: none;
      }
    }

    .MuiDateRangeCalendar-monthContainer {
      padding: 0 8px;
    }
  }
`;

export const CalendarTooltip: any = styled(Tooltip)`
  &.sheduled:before {
    position: absolute;
    content: '•';
    color: var(--nefrit);
    font-size: 20px;
    top: -2px;
    right: 3px;
  }

  & .MuiTooltip-popper {
    background-color: var(--white);
  }
`;

