import React from 'react';

import { FooterComponent } from './Footer.styles';
import {useTranslation} from "react-i18next";
import {SocialNetworks} from "../../elements";

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FooterComponent>
      <div className="footer">
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 medium-6 xlarge-6">
              <div className="footer-h1"><img alt="Didactist" src="/img/d.png" />idactist</div>
              <div className="footer-p">{t('footer.ultimate')}</div>
            </div>
            <div className="cell small-6 medium-3 xlarge-3">
              {/*<ul className="footer-ul">*/}
              {/*  <li>*/}
              {/*    <Link*/}
              {/*      className="home__choose-submit"*/}
              {/*      to={PATHS.FEATURES}*/}
              {/*    >*/}
              {/*      {t('footer.features')}*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </div>
            <div className="cell small-6 medium-3 xlarge-3 footer-right">
              {/*<div className="footer-title">Try it<span className="red">!</span>*/}
              {/*</div>*/}
              {/*<div className="footer-autoris">*/}
              {/*  <Link*/}
              {/*    className="footer-link"*/}
              {/*    to={PATHS.LOGIN}*/}
              {/*  >*/}
              {/*    {t('footer.login')}*/}
              {/*  </Link>*/}

              {/*  <Link*/}
              {/*    className="footer-link"*/}
              {/*    to={PATHS.REGISTRATION}*/}
              {/*  >*/}
              {/*    {t('footer.signup')}*/}
              {/*  </Link>*/}
              {/*</div>*/}
              <SocialNetworks />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyrite">
        © 2023 {t('footer.copyright')}
      </div>
    </FooterComponent>
  );
};

export default Footer;
