import {
  CreateTaskParams,
  TaskResultParams,
  GetTaskParams,
  GetSheduletsParams,
  AddNotificationParams,
  UpdateNotificationParams,
  RemoveNotificationParams
} from '../../api';

const GET_TASK_REQUEST = 'GET_TASK_REQUEST';
const GET_TASK_SUCCESS = 'GET_TASK_SUCCESS';
const GET_TASK_FAILURE = 'GET_TASK_FAILURE';
const CREATE_TASK_REQUEST = 'CREATE_TASK_REQUEST';
const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
const CREATE_TASK_FAILURE = 'CREATE_TASK_FAILURE';
const TASK_RESULT_REQUEST = 'TASK_RESULT_REQUEST';
const TASK_RESULT_SUCCESS = 'TASK_RESULT_SUCCESS';
const TASK_RESULT_FAILURE = 'TASK_RESULT_FAILURE';
const GET_SHEDULETS_REQUEST = 'GET_SHEDULETS_REQUEST';
const GET_SHEDULETS_SUCCESS = 'GET_SHEDULETS_SUCCESS';
const GET_SHEDULETS_FAILURE = 'GET_SHEDULETS_FAILURE';
const ADD_SHEDULETS_REQUEST = 'ADD_SHEDULETS_REQUEST';
const ADD_SHEDULETS_SUCCESS = 'ADD_SHEDULETS_SUCCESS';
const ADD_SHEDULETS_FAILURE = 'ADD_SHEDULETS_FAILURE';
const UPDATE_SHEDULETS_REQUEST = 'UPDATE_SHEDULETS_REQUEST';
const UPDATE_SHEDULETS_SUCCESS = 'UPDATE_SHEDULETS_SUCCESS';
const UPDATE_SHEDULETS_FAILURE = 'UPDATE_SHEDULETS_FAILURE';
const REMOVE_SHEDULETS_REQUEST = 'REMOVE_SHEDULETS_REQUEST';
const REMOVE_SHEDULETS_SUCCESS = 'REMOVE_SHEDULETS_SUCCESS';
const REMOVE_SHEDULETS_FAILURE = 'REMOVE_SHEDULETS_FAILURE';

type GetTaskActionType = {
  type: typeof GET_TASK_SUCCESS | typeof GET_TASK_FAILURE;
  payload?: any;
};

export type GetTaskRequest = {
  type: typeof GET_TASK_REQUEST;
  payload?: GetTaskParams;
};

type CreateTaskActionType = {
  type: typeof CREATE_TASK_SUCCESS | typeof CREATE_TASK_FAILURE;
  payload?: any;
};

export type CreateTaskRequest = {
  type: typeof CREATE_TASK_REQUEST;
  payload?: CreateTaskParams;
};

type TaskResultActionType = {
  type: typeof TASK_RESULT_SUCCESS | typeof TASK_RESULT_FAILURE;
  payload?: any;
};

export type TaskResultRequest = {
  type: typeof TASK_RESULT_REQUEST;
  payload: TaskResultParams;
};

type GetSheduletsActionType = {
  type: typeof GET_SHEDULETS_SUCCESS | typeof GET_SHEDULETS_FAILURE;
  payload?: any;
};

export type GetSheduletsRequest = {
  type: typeof GET_SHEDULETS_REQUEST;
  payload: GetSheduletsParams;
};

type AddSheduletsActionType = {
  type: typeof ADD_SHEDULETS_SUCCESS | typeof ADD_SHEDULETS_FAILURE;
  payload?: any;
};

export type AddSheduletsRequest = {
  type: typeof ADD_SHEDULETS_REQUEST;
  payload: AddNotificationParams;
};

type UpdateSheduletsActionType = {
  type: typeof UPDATE_SHEDULETS_SUCCESS | typeof UPDATE_SHEDULETS_FAILURE;
  payload?: any;
};

export type UpdateSheduletsRequest = {
  type: typeof UPDATE_SHEDULETS_REQUEST;
  payload: UpdateNotificationParams;
};

type RemoveSheduletsActionType = {
  type: typeof REMOVE_SHEDULETS_SUCCESS | typeof REMOVE_SHEDULETS_FAILURE;
  payload?: any;
};

export type RemoveSheduletsRequest = {
  type: typeof REMOVE_SHEDULETS_REQUEST;
  payload: RemoveNotificationParams;
};

export type GetTaskActionTypes = GetTaskActionType | GetTaskRequest;
export type CreateTaskActionTypes = CreateTaskActionType | CreateTaskRequest;
export type TaskResultActionTypes = TaskResultActionType | TaskResultRequest;
export type GetSheduletsActionTypes = GetSheduletsActionType | GetSheduletsRequest;
export type AddSheduletsActionTypes = AddSheduletsActionType | AddSheduletsRequest;
export type UpdateSheduletsActionTypes = UpdateSheduletsActionType | UpdateSheduletsRequest;
export type RemoveSheduletsActionTypes = RemoveSheduletsActionType | RemoveSheduletsRequest;

export type PracticeActionTypes =
  | GetTaskActionTypes
  | CreateTaskActionTypes
  | TaskResultActionTypes
  | GetSheduletsActionTypes
  | AddSheduletsActionTypes
  | UpdateSheduletsActionTypes
  | RemoveSheduletsActionTypes;

export default {
  GET_TASK_REQUEST,
  GET_TASK_SUCCESS,
  GET_TASK_FAILURE,
  CREATE_TASK_REQUEST,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_FAILURE,
  TASK_RESULT_REQUEST,
  TASK_RESULT_SUCCESS,
  TASK_RESULT_FAILURE,
  GET_SHEDULETS_REQUEST,
  GET_SHEDULETS_SUCCESS,
  GET_SHEDULETS_FAILURE,
  ADD_SHEDULETS_REQUEST,
  ADD_SHEDULETS_SUCCESS,
  ADD_SHEDULETS_FAILURE,
  UPDATE_SHEDULETS_REQUEST,
  UPDATE_SHEDULETS_SUCCESS,
  UPDATE_SHEDULETS_FAILURE,
  REMOVE_SHEDULETS_REQUEST,
  REMOVE_SHEDULETS_SUCCESS,
  REMOVE_SHEDULETS_FAILURE,
} as const;
