import { Switch } from '@mui/material';
import styled from 'styled-components';

export const SwitchStyles: any = styled(Switch)`
  align-items: center;
  padding: 0;
  margin-right: 10px;
  width: 45px;
  height: 24px;
  border-radius: 37px;
  overflow: hidden;

  .MuiIconButton-root {
    padding: 0;
  }

  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(20px);
  }

  .MuiSwitch-switchBase {
  }

  .MuiSwitch-thumb {
    width: 20px;
    height: 20px;
    background-color: var(--red);
  }

  .MuiSwitch-track {
    background-color: var(--gray);
    border-radius: 37px;
    opacity: 1;
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: var(--black)!important;
    opacity: 1;
  }

  .Mui-checked {
    color: var(--black);
  }
`;
