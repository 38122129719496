import styled from 'styled-components';

export const LoaderStyles: any = styled.div`
  display: flex;
  justify-content: center;

  svg {
    width: 28px;
    height: 28px;
    stroke: var(--red);
  }
`;
