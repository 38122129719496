import { Skeleton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ProfitCard,
  ProfitCardText,
  ProfitCardTitle,
} from './ProfitComponent.Styles';

export type Profitability = {
  title: string;
  tooltip?: string;
  value: number | string;
  className?: string;
};

interface Props {
  selected?: boolean;
  item: Profitability;
  onClick?: () => void;
}

export const ProfitComponent: React.FC<Props> = (props: Props) => {
  const { selected, item, onClick } = props;
  const { t } = useTranslation();

  return (
    <ProfitCard
      onClick={onClick}
      className={`${item.className}`}
      classes={{
        root: `${selected ? 'profit-card active' : 'profit-card'}`,
      }}
      data-title={item?.tooltip ? t(item?.tooltip) : null}
    >
      <div className="overlay">
        <ProfitCardTitle className="profit-card__title">{t(item.title)}</ProfitCardTitle>
        <p>{t('common.developing')}</p>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" className="blur">
        <defs>
          <filter id="blur">
            <feGaussianBlur stdDeviation="5" />
          </filter>
        </defs>
      </svg>
      <div className="profit-card__container">
        <ProfitCardTitle className="profit-card__title">{t(item.title)}</ProfitCardTitle>
        <ProfitCardText className="profit-card__text">
          {!item.value ? (
            <Skeleton
              variant="text"
              animation="wave"
              width={110}
              height={45}
              style={{ display: 'inline-block', marginRight: 10 }}
            />
          ) : (
            <span className="profit-card__value">{`${item.value || 0}`}</span>
          )}
        </ProfitCardText>
      </div>

    </ProfitCard>
  );
};
