import cloneDeep from 'lodash/cloneDeep';

import { UserData } from '../../api';
import { STORAGE_KEYS } from '../../const/storage_keys.constants';

import types, { AppActionTypes } from '../actionTypes';
import {StatisticsListTask} from "../../api/codecs.users";

export type UserReducerState = {
  loggedIn: boolean;
  newPasswordSanded: boolean;
  errors: UserData;
  userData: UserData | null;
  statistics: {
    list: StatisticsListTask[];
    loaded: boolean;
    languageId: number;
  };
  auth: {
    accessToken: string;
    refreshToken: string;
  };
  learningLanguage: number;
};

const auth = JSON.parse(localStorage.getItem(STORAGE_KEYS.AUTH) || '{}');
const user = JSON.parse(localStorage.getItem(STORAGE_KEYS.USER) || '{}');
const learningLanguage = localStorage.getItem(STORAGE_KEYS.LEARNING_LANGUAGE) || '1';

const INITIAL_STATE: UserReducerState = {
  loggedIn: !!auth.accessToken,
  newPasswordSanded: false,
  errors: {},
  userData: user,
  statistics: {
    list: [],
    loaded: false,
    languageId: 0,
  },
  auth: {
    accessToken: auth.accessToken,
    refreshToken: auth.refreshToken,
  },
  learningLanguage: Number(learningLanguage)
};

const usersReducers = (state = INITIAL_STATE, action: AppActionTypes): UserReducerState => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        userData: action.payload || null,
        loggedIn: true,
      };

    case types.LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        errors: action.payload || {},
      };
    case types.REGISTRATION_REQUEST:
      return {
        ...state,
      };
    case types.REGISTRATION_SUCCESS:
      return {
        ...state,
        errors: action.payload || {},
      };
    case types.REGISTRATION_FAILURE:
      return {
        ...state,
        errors: action.payload || {},
      };
    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
      };
    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        newPasswordSanded: true,
      };
    case types.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
      };
    case types.LOGOUT_REQUEST:
      return {
        ...state,
      };
    case types.LOGOUT_SUCCESS:
      const logoutData = cloneDeep(INITIAL_STATE);
      logoutData.loggedIn = false;
      logoutData.auth = {
        accessToken: '',
        refreshToken: '',
      };
      return logoutData;
    case types.LOGOUT_FAILURE:
      return {
        ...state,
      };
    case types.GET_STATISTICS_REQUEST:
      const getStatisticsRequest = cloneDeep(state.statistics);
      getStatisticsRequest.loaded = true;
      getStatisticsRequest.languageId = action.payload?.languageId || 0;

      return {
        ...state,
        statistics: getStatisticsRequest,
      };
    case types.GET_STATISTICS_SUCCESS:
      const getStatisticsSuccess = cloneDeep(state.statistics);
      const newStatisticsList = action.payload;

      getStatisticsSuccess.list = newStatisticsList || getStatisticsSuccess.list;

      return {
        ...state,
        statistics: getStatisticsSuccess,
      };
    case types.GET_STATISTICS_FAILURE:
      return {
        ...state,
      };
    case types.SET_LEARNING_LANGUAGE:
      localStorage.setItem(STORAGE_KEYS.LEARNING_LANGUAGE, String(action.payload.learningLanguage))
      return {
        ...state,
        learningLanguage: action.payload.learningLanguage
      };
    case types.UPDATE_USER_REQUEST:
      return {
        ...state,
      };
    case types.UPDATE_USER_SUCCESS:
      const newUserData = state.userData || {};
      newUserData.first_name = action.payload.FirstName;
      newUserData.last_name = action.payload.LastName;
      newUserData.phone = action.payload.Phone;
      localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(newUserData));
      return {
        ...state,
        userData: newUserData,
        errors: action.payload || {},
      };
    case types.UPDATE_USER_FAILURE:
      return {
        ...state,
        errors: action.payload || {},
      };
    default:
      return state;
  }
};

export default usersReducers;
