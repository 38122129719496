import styled from 'styled-components';

export const MobileMenuComponent:any = styled('div')`
  padding: 4rem 0 0 0;
  .is-drilldown {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 1.7rem 1em;
    height: calc(100% - 64px);
  }
  
  .menu {
    margin-bottom: 3px;
    
    .nav-link {
      a {
        display: block;
        position: relative;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #060931;
        padding: 0.7rem;
      }
    }
  }
  
  .btns {
    display: block;
    position: relative;
    margin-top: auto;
    margin-bottom: 50px;
    
    .btn {
      padding: 16px 24px;
      text-align: center;
      color: var(--dark-gray);
      border-radius: var(--border-radius);
      border: 3px solid var(--red);
      transition: background-color 0.3s, border-color 0.3s, color 0.3s;
      cursor: pointer;
      outline: none;
      font-size: 16px;
      line-height: 28px;
      font-weight: 600;
      letter-spacing: 1px;
      font-family: var(--poppins);
      text-transform: uppercase;
      width: 100%;
      
      &-wrap {
        margin-top: 0.5rem;
      }
      
      &.-full {
        background: var(--red);
        color: var(--white);
      }
    }
  }
`;
