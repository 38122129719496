import React from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import { Burger, HeaderComponent, UserBar } from './DashboardHeader.styles';
import { PATHS } from "../../const/paths.constants";
import { STATIC_URL } from '../../const/general.constants';
import { AppStateType} from "../../store";
import { setSidebarOpen } from "../../store/app/actions";
import { logout } from '../../store/user/actions';
import { LanguageSelector, UserInfo, LearningLanguageSelector } from "../../elements";
import { UserReducerState } from "../../store/user/reducers";
import { useTranslation } from "react-i18next";

type HeaderProps = {
  user: UserReducerState;
  isSidebarOpen: boolean;
  setSidebarOpen: (payload: boolean) => void;
  logout: () => void;
};

const DashboardHeader: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { user, isSidebarOpen, setSidebarOpen, logout } = props;
  const { userData } = user;
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const array = pathname
    .split('/')
    .filter((val: string) => !!val)
    .filter((val: string) => !Number(val) && Number(val) !== 0)
    .map((route: string, i: number, arr: string[]) => {
      return {
        url: arr.filter((item: string, index: number) => index <= i).join('/'),
        route,
      };
    });

  const toogleSidebar = (event: any) => {
    event.stopPropagation();
    setSidebarOpen(!isSidebarOpen);
  };

  const handleLogOut = () => {
    logout();
  };

  return (
    <HeaderComponent>
      <div className="header">
        <div className="header-title__wrap">
          <span className="header-title">{t(`navBar.${array[array.length - 1].route}`)}</span>
        </div>
        <div className="header-logo" >
          <Link className="header-logo__link" to={PATHS.HOME}>Didactist</Link>
        </div>

        <UserBar>
          <div className="header-learning">
            <span className="header-learning__title">{t('learning.title')}:</span>
            <LearningLanguageSelector className="dashboard" />
          </div>

          <div className="header-lang">
            <span className="header-learning__title">{t('learning.my_title')}:</span>
            <LanguageSelector className="dashboard" />
          </div>

          <Link to={PATHS.SETTINGS}>
            <UserInfo
              className=""
              src={
                user.userData?.photo
                  ? `${STATIC_URL}${user.userData?.photo}`
                  : '/img/header/user-placeholder.svg'
              }
              alt={`${userData?.first_name ?? ''} ${userData?.last_name ?? ''}`}
              userName={`${userData?.email || ''}`}
            />
          </Link>

          <button className="header-logout" onClick={handleLogOut}>
            <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
              <path
                className="border"
                d="M12.4585 22.8826H3.1146C2.5415 22.8826 2.07642 22.4174 2.07642 21.8444V3.15664C2.07642 2.58354 2.54155 2.11846 3.1146 2.11846H12.4585C13.0326 2.11846 13.4967 1.65439 13.4967 1.08027C13.4967 0.506152 13.0326 0.0419922 12.4585 0.0419922H3.1146C1.39741 0.0419922 0 1.43945 0 3.15664V21.8443C0 23.5615 1.39741 24.9589 3.1146 24.9589H12.4585C13.0326 24.9589 13.4967 24.4949 13.4967 23.9207C13.4967 23.3466 13.0326 22.8826 12.4585 22.8826Z"
              />
              <path
                className="arrow"
                d="M24.6906 11.7606L18.3783 5.53131C17.9713 5.12848 17.3131 5.13371 16.9103 5.54172C16.5075 5.94972 16.5116 6.6069 16.9207 7.00973L21.4317 11.4615H9.34385C8.76973 11.4615 8.30566 11.9256 8.30566 12.4997C8.30566 13.0738 8.76973 13.538 9.34385 13.538H21.4317L16.9207 17.9898C16.5117 18.3926 16.5085 19.0498 16.9103 19.4578C17.1138 19.6633 17.3816 19.7672 17.6495 19.7672C17.9132 19.7672 18.1769 19.6675 18.3783 19.4681L24.6906 13.2389C24.8879 13.0437 25 12.7779 25 12.4997C25 12.2215 24.8889 11.9568 24.6906 11.7606Z"
              />
            </svg>
          </button>
          <Burger
            className={`burger ${isSidebarOpen ? 'active' : ''}`}
            onClick={toogleSidebar}
          >
            <svg viewBox="0 0 100 100">
              <path
                className="line line1"
                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
              />
              <path className="line line2" d="M 40,50 H 80" />
              <path
                className="line line3"
                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
              />
            </svg>
          </Burger>
        </UserBar>
      </div>
    </HeaderComponent>
  );
};

const mapState = (state: AppStateType) => {
  const { user, app } = state;
  return {
    user,
    isSidebarOpen: app.isSidebarOpen,
  };
};

export default connect(mapState, { setSidebarOpen, logout })(DashboardHeader);
