import React from 'react';
import { connect } from 'react-redux';
import {MainContainer, SiteWrapper} from './Layout.Styles';
import { Alert, Modal } from '../../../elements';
import { AppStateType } from '../../../store';
import { setAlert } from '../../../store/loadingsErrors/actions';
import Header from "../../Header/Header";
import Footer from "../../Footer";
import MobileMenu from "../../MobileMenu";

interface Props {
  alert?: any;
  modal: any;
  children?: any;
  mobileMenuOpened: boolean;
  setAlert: (message: string | null, messageType: string | null) => void;
}


const AuthLayout: React.FC<Props> = (props: Props) => {
  const { alert, modal, children, mobileMenuOpened, setAlert } = props;

  return (
    <SiteWrapper className="auth">
      <MainContainer className={`off-canvas-content has-transition-push has-position-right ${mobileMenuOpened ? 'is-open-right' : ''}`}>
        <Header />
        <MobileMenu />
        {children}
        <Footer />
      </MainContainer>
      <Alert alert={alert} pathname={children?.props?.location?.pathname} setAlert={setAlert} />
      <Modal
        opened={modal?.opened}
        closeModal={() => modal?.closeModal()}
        className={modal?.className}
        hasCloseBtn={modal?.hasCloseBtn}
        children={modal?.content(modal?.contentParams)}
      />
    </SiteWrapper>
  );
};

const mapState = (state: AppStateType) => {
  const { loadings, app } = state;
  return {
    alert: loadings.alert,
    mobileMenuOpened: app.mobileMenuOpened,
    modal: app.modal,
  };
};

export default connect(mapState, { setAlert })(AuthLayout);
