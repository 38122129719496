import styled from 'styled-components';

export const BreadCrumbList: any = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const BreadCrumbListItem: any = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--red);

  &:not(:last-child)::after {
    content: '';
    display: inline-block;
    width: 5px;
    height: 10px;
    margin: 0 15px;
    background: url('/img/header/icon_arrow.svg') center / contain no-repeat;
  }

  a {
    font-weight: 600;
    transition: none;
  }
`;
