// import { FormControlLabel } from '@mui/material';
import React, { useRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  Container,
  MainContent,
  Input,
  PhoneInput,
  Button,
  Loader,
  // Switch,
} from '../../elements';

import {
  SettingsPage, SettingsUser,
} from './Settings.Styles';
import { UserInfoBlock, UserInfoImg } from '../../elements/UserInfo/UserInfo.Styles';
import { STATIC_URL } from "../../const/general.constants";
import { UserReducerState } from "../../store/user/reducers";
import cloneDeep from "lodash/cloneDeep";
import {AppStateType} from "../../store";
import {selectErrorByKey, selectLoadingByKey} from "../../store/loadingsErrors/selectors";
import types from "../../store/actionTypes";
import {RegistrationParams, UserParams} from "../../api";
import {updateUser} from "../../store/user/actions";

export interface SettingsProps {
  user: UserReducerState;
  loading: boolean;
  updateUser: (payload: UserParams) => void;
}

const Settings: React.FC<SettingsProps> = (props: SettingsProps) => {
  const {
    user,
    loading,
    updateUser
  } = props;
  const { t } = useTranslation();
  const { userData } = user;

  console.log('userData', userData);

  const [values, setValues] = useState<{ [key: string]: string }>({
    firstName: userData?.first_name || '',
    lastName: userData?.last_name || '',
    email: userData?.email || '',
    phone: userData?.phone || '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [newsletter, setNewsletter] = useState<boolean>(false);

  const toggleNewsletter = useCallback(() => {
    setNewsletter(!newsletter);
  }, [newsletter, setNewsletter]);

  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const onChangeAvatar = () => {
    if (hiddenFileInput.current?.click) {
      hiddenFileInput.current.click();
    }
  };

  const handleUploadAvatar = (event: React.ChangeEvent<any>) => {
    const fileUploaded = event.target.files[0];
    const formData = [
      {
        name: 'photo',
        value: fileUploaded,
      },
    ];

    const updateData = {
      data: formData,
      type: 'form',
    };

    console.log(updateData);
  };

  let getFormErrors: (data: { [p: string]: string }) => any;
  getFormErrors = (data: { [key: string]: string }) => {
    const {email} = data;
    const newErrors: any = {
    };

    // if (!email) newErrors.email = 'settings.page.form.email.errors.empty';
    // if (email && !validateEmail(email)) newErrors.email = 'settings.page.form.email.errors.valid';

    return newErrors;
  };

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: any = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onChangePhone = (field: string, value: string, isValid: boolean) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!isValid && value) {
      setErrors({
        ...errors,
        [field]: 'settings.page.form.phone.errors.valid',
      });
    }

    if (!value) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (isValid && value) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }
  };

  const onBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: any = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      const newErrors: RegistrationParams = getFormErrors(values);
      setErrors(newErrors);

      const data: UserParams = {
        Id: user.userData?.id || 0,
        Phone: values.phone,
        FirstName: values.firstName,
        LastName: values.lastName,
        Rating: user.userData?.rating,
        Skills: user.userData?.skills,
        TeacherId: 11,
        LevelId: 3,
      };

      if (!checkErrors(newErrors)) {
        updateUser(data);
      }
    },
    [getFormErrors, updateUser, user.userData?.id, values]
  );

  return (
    <MainContent className='content-main home-page'>
      <Container>
        <SettingsPage className="settings">
          <SettingsUser>
            <UserInfoBlock>
              <UserInfoImg className="user-info__img">
                <img
                  src={
                    user?.userData?.photo
                      ? `${STATIC_URL}${user?.userData?.photo}`
                      : 'img/header/user-placeholder.svg'
                  }
                  alt="user"
                />
              </UserInfoImg>
            </UserInfoBlock>

            <button type="button" className="setting-user__link" onClick={onChangeAvatar}>
              Change photo
            </button>
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg"
              ref={(instance: HTMLInputElement) => (hiddenFileInput.current = instance)}
              onChange={handleUploadAvatar}
              className="setting-user__input"
            />
          </SettingsUser>
          <div className="grid-x grid-margin-x profile">
            <div className="cell small-12 xlarge-6">
              <form className="setting-form" onSubmit={onSubmit}>
                <Input
                  className='setting-form__input'
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  placeholder={`${t('settings.page.form.firstName.placeholder')}`}
                  error={errors.firstName}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                <Input
                  className='setting-form__input'
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  placeholder={`${t('settings.page.form.lastName.placeholder')}`}
                  error={errors.lastName}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                <Input
                  className='setting-form__input'
                  type="email"
                  name="email"
                  value={values.email}
                  placeholder={`${t('settings.page.form.email.placeholder')}`}
                  error={errors.email}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled
                />
                <PhoneInput
                  className='setting-form__input'
                  placeholder={t('settings.page.form.phone.placeholder')}
                  value={values.phone}
                  name="phone"
                  error={errors.phone}
                  onChange={(value: string, isValid: boolean) =>
                    onChangePhone('phone', value, isValid)}
                />
                <div className="profile__button-wrap">
                  <Button
                    className='profile__button loading-btn'
                    type="submit"
                    disabled={loading}
                  >
                    {t('settings.btns.save')}
                    {loading ? <Loader /> : null}
                  </Button>
                </div>
              </form>
            </div>
            {/*<div className="cell small-12 xlarge-6">*/}
            {/*  <div className="switchers">*/}
            {/*    <FormControlLabel*/}
            {/*      className="switch"*/}
            {/*      control={*/}
            {/*        <Switch*/}
            {/*          className=""*/}
            {/*          checked={newsletter}*/}
            {/*          onChange={toggleNewsletter}*/}
            {/*          name="checkedB"*/}
            {/*          color="primary"*/}
            {/*        />*/}
            {/*      }*/}
            {/*      label={t(`settings.subscribe.newsletter.${newsletter}`)}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </SettingsPage>
      </Container>
    </MainContent>
  );
};

const mapState = (state: AppStateType) => {
  const { user } = state;
  return {
    user,
    loading: selectLoadingByKey(state, types.REGISTRATION_REQUEST),
    error: selectErrorByKey(state, types.REGISTRATION_REQUEST),
  };
};

export default connect(mapState, {updateUser})(Settings);
