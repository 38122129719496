import { useContext } from 'react'
import { AudioContext } from '../contexts/BackgroundAudio'

const useBackgroundAudio = () => {
  const audioContext = useContext(AudioContext)

  if (audioContext === undefined) {
    throw new Error('Audio context undefined')
  }

  return audioContext
}

export default useBackgroundAudio
