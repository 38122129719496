import { Select } from '@mui/material';
import styled from 'styled-components';

export const LanguageSelectorStyles: any = styled(Select)`
  background-color: transparent;
  border-radius: 5px;
  width: auto;

  .MuiSelect-select {
    //padding: 0.25rem;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    min-height: 0!important;
    color: var(--light-gray);
    background-color: transparent;
  }

  .MuiSelect-selectMenu {
    height: auto;
    border-radius: 0;
  }

  .MuiMenu-paper {
    border-radius: var(--border-radius);
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.04);
  }

  .MuiList-root {
    background: var(--white);
    border-radius: 13px;
  }

  .MuiList-padding {
    padding: 11px 8px;
  }

  .MuiListItem-button {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--light-gray);
    border-radius: 8px;

    &::after {
      display: none;
    }

    &:focus,
    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }

    &.Mui-selected {
      background-color: transparent;
      text-decoration: underline;
    }
  }

  .MuiMenuItem-root {
    min-height: auto;
  }

  &.header__lang {
    .MuiSelect-select {
      padding: 0.25rem;
    }

    > .MuiSelect-icon {
      display: none;
    }
  }
  
  .MuiSelect-icon {
    fill: var(--light-gray);
  }

  &.dashboard {
    color: var(--black);

    .MuiSelect-select {
      color: var(--black);
    }

    .MuiSelect-icon {
      fill: var(--black);
    }
  }
`;
