import styled from 'styled-components';

export const HeaderComponent: any = styled.header`
  position: sticky;
  z-index: 999;
  top: 0;
  width: 100vw;
  background: var(--dark);
  color: var(--light-gray);
  font-family: var(--libero);
  
  section {
    padding-bottom: 0;
  }
  .title-bar {
    padding: 0.5rem 0;
    color: #ffffff;
    justify-content: flex-start;
    align-items: center;

    .menu-icon {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 27px;
      height: 19px;
      background: 0 0;
      border: 0;
      cursor: pointer;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      outline: 0;
      overflow: hidden;

      & .x, .y, .z {
        display: block;
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        background: rgb(227, 227, 227);
        width: 100%;
        height: 3px;
        transition: all 200ms ease-out;
      }

      & .x {
        top: -17px;
      }

      & .z {
        top: 16px;
      }
    }
  }


  .top-bar {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 0;
    height: 4.375rem;

    .top-bar-left {
      display: flex;
      justify-content: center;
      min-height: 100%;
      margin-right: auto;
      margin-left: 5px;
    }

    .top-bar-middle {
      min-height: 100%;
      margin-right: 0.65em;
    }

    .top-bar-right {
      min-height: 100%;
    }

    .top-bar-left, .top-bar-middle, .top-bar-right {
      flex: 0 0 auto;
      max-width: 100%;
    }

    &.stacked-for-xxlarge {
      flex-wrap: wrap;

      .top-bar-left, .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  .navigation {
    &--collapsed {
      display: flex;
      justify-content: space-between;
    }

    &--expanded {
      display: none;
    }

    &__logo {
      width: 7rem;
      height: 3rem;

      &-link {
        font-family: Pacifico, sans-serif;
        font-size: 34px;
        line-height: 34px;
        color: var(--light-gray);

        &:hover {
          color: var(--red);
        }
      }
    }

    .navigation-lang {
      padding: 1rem 0;
      
      &__mobile {
        margin-left: auto;
      }
    }
  }

  .btns {
    display: flex;
    height: 4.375rem;
    align-items: center;
    gap: 8px;

    &-wrap {
      position: relative;
    }
  }


  .menu {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    & .link {
      font-weight: 400;
      font-size: 18px;
      line-height: 1;
      text-decoration: none;
      display: block;
      padding: 0.7rem 0.52rem;
      transition: 0.5s;
      
      &:hover {
        color: var(--red);
      }
    }

    &.dropdown {
      height: 4.375rem;
      align-items: center;

      > li {

        &.-active {
          .link {
            color: var(--red);
            transition: 0.5s;
          }
        }
      }
    }

    .has-submenu {
      .sub-link {
        &:after {
          margin-left: 4px;
          content: '';
          font-weight: 300;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          transition: 0.5s;
          position: relative;
          width: 0.5rem;
          height: 0.75rem;
          background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgNTEyIj48cGF0aCBmaWxsPSIjNDM0MzQzIiBkPSJNMTQzIDM1Mi4zTDcgMjE2LjNjLTkuNC05LjQtOS40LTI0LjYgMC0zMy45bDIyLjYtMjIuNmM5LjQtOS40IDI0LjYtOS40IDMzLjkgMGw5Ni40IDk2LjQgOTYuNC05Ni40YzkuNC05LjQgMjQuNi05LjQgMzMuOSAwbDIyLjYgMjIuNmM5LjQgOS40IDkuNCAyNC42IDAgMzMuOWwtMTM2IDEzNmMtOS4yIDkuNC0yNC40IDkuNC0zMy44IDB6Ii8+PC9zdmc+);
          background-repeat: no-repeat;
        }
      }

      &:hover {
        .sub-link {
          cursor: pointer;
          
          &:after {
            transform: rotate(180deg);
          }
        }
      }

      &:hover .submenu {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
        pointer-events: auto;
      }
    }
  }

  .submenu {
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    background-color: #fff;
    z-index: 2;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: transform .4s ease;
    transform: translateY(-10px);
    min-width: 200px;
    text-align: center;
    color: var(--dark-gray);

    &:hover {
      opacity: 1;
      visibility: visible;
    }
  }

  @media only screen and (min-width: 40em) {
  }

  @media only screen and (min-width: 48em) {
    .navigation {

      &--collapsed {
        display: none;
      }

      &--expanded {
        display: flex;
      }
    }

    .menu {
      .services-panel {
        width: 53rem;
      }
    }
  }

  @media only screen and (min-width: 48em) {
    .navigation {
      &__logo {
      }
    }
  }

  @media only screen and (min-width: 64em) {

  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .services-panel {
      margin-left: -9em;
    }
  }

  @media only screen and (min-width: 74.9375em) {
    .menu.dropdown {
      height: 4.375rem;
    }

    .menu .link {
      font-size: 18px;
      padding: 1rem 1.5rem;
    }

    .top-bar .top-bar-left {
      margin-left: 15px;
    }

    .navigation .navigation-lang__btn {
      padding: 6px 19px 6px 8px;
    }

    .top-bar .top-bar-middle {
      margin-right: 3em;
    }

    .btns {
      height: 4.375rem;
      gap: 16px;
    }
  }
`;
