import types from '../actionTypes';
import {StatisticsParams, UserParams} from "../../api";

const login = (payload: any) => ({
  type: types.LOGIN_REQUEST,
  payload,
});

const registration = (payload: any) => ({
  type: types.REGISTRATION_REQUEST,
  payload,
});

const forgotPassword = (payload: any) => ({
  type: types.FORGOT_PASSWORD_REQUEST,
  payload,
});

const logout = () => ({
  type: types.LOGOUT_REQUEST,
});

const fetchStatistics = (payload: StatisticsParams) => ({
  type: types.GET_STATISTICS_REQUEST,
  payload,
});

const setLearningLanguage = (payload: { learningLanguage: number }) => ({
  type: types.SET_LEARNING_LANGUAGE,
  payload,
});

const updateUser = (payload: UserParams) => ({
  type: types.UPDATE_USER_REQUEST,
  payload,
});

export {
  login,
  registration,
  forgotPassword,
  logout,
  fetchStatistics,
  setLearningLanguage,
  updateUser,
};
