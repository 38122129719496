import { Card } from '@mui/material';
import styled from 'styled-components';

export const ProfitCard: any = styled(Card)`
  width: 100%;
  margin-right: 1rem;
  padding: 30px 18px;
  background: var(--dark);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  border-radius: var(--border-radius);
  cursor: pointer;
  position: relative;

  &[data-title] {
    &:before {
      content: attr(data-title);
      position: absolute;
      top: 10px;
      right: 10px;
      max-width: 250px;
      padding: 10px;
      font-size: 10px;
      line-height: 15px;
      color: var(--white);
      background: #292f39;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      border-radius: var(--border-radius);
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
      z-index: 1;
    }

    &:hover {
      &:before {
        opacity: 1;
        visibility: visible;
        content: attr(data-title);
      }
    }
  }

  &.active {
    .profit-card__text {
      background-image: linear-gradient(180deg, rgba(226, 174, 255, 0.66) 0%, #fc54ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: var(--white);

      .profit-card__value {
        font-weight: 500;
      }
    }
  }

  .blur {
    display: none;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background: rgba(196, 196, 196, 0.01);

    h3 {
      margin: 0 0 5px;
    }

    p {
      margin: 0;
      max-width: 210px;
      font-family: var(--poppins);
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
    }
  }

  &.-inactive {
    .profit-card__container {
      filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='5');
      -webkit-filter: url(#blur);
      filter: url(#blur);
      -webkit-filter: blur(5px);
      filter: blur(5px);
    }

    .overlay {
      display: flex;
    }
  }

  @media only screen and (max-width: 40.0625em) {
    width: calc(50% - 0.5rem);
    min-width: calc(50% - 0.5rem);
    margin-right: 0;
    margin-bottom: 0;
    padding: 2rem 1rem;

    &[data-title] {
      &:before {
        display: none;
      }
    }
  }
`;

export const ProfitComponentLoading: any = styled(Card)`
  width: calc(100% / 3 - 1rem);
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 30px 18px;
  background: #EDF6FF!important;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.04)!important;
  border-radius: var(--border-radius);
  cursor: pointer;
  position: relative;
` ;

export const ProfitCardTitle: any = styled.h3`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #d1d1d1;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const ProfitCardText: any = styled.div`
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 45px;
  background-image: linear-gradient(180deg, #e9b9e9 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: var(--white);

  span {
    vertical-align: middle;
  }

  .profit-card__value {
    font-weight: 400;
  }

  .profit-card__currency {
    font-weight: 300;
  }

  @media (max-width: 992px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
