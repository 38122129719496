import React, {useCallback, useState} from 'react';
import { MenuItem, Input } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { LearningLanguageSelectorStyles } from './LearningLanguageSelector.Styles';
import {LEARNING_LANGUAGES_ARR, LearningLanguagesInterface} from "../../const/learning_languages.constants";
import {UserReducerState} from "../../store/user/reducers";
import {connect} from "react-redux";
import {setLearningLanguage} from "../../store/user/actions";
import {AppStateType} from "../../store";


interface Props {
  user: UserReducerState;
  className?: string;
  setLearningLanguage: (payload: { learningLanguage: number }) => void;
}

const LearningLanguageSelector: React.FC<Props> = (props: Props) => {
  const { className, user, setLearningLanguage } = props;
  const { t } = useTranslation();
  const [activeLang, setActiveLang] = useState<number>(user.learningLanguage);


  const changeLanguage = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const newLang: number = Number(event.target.value);
      setActiveLang(newLang)
      setLearningLanguage( { learningLanguage: Number(event.target.value) } )
    },
    [setLearningLanguage, setActiveLang]
  );

  const menuProps = {
    disablePortal: true,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getcontentanchorel: null,
  };

  return (
    <LearningLanguageSelectorStyles
      className={className}
      name="learningLang"
      IconComponent={ExpandMore}
      MenuProps={menuProps}
      value={activeLang}
      onChange={changeLanguage}
      input={<Input disableUnderline />}
    >
      {LEARNING_LANGUAGES_ARR.filter(l => !l.inactive).map((l: LearningLanguagesInterface) => (
        <MenuItem value={l.index} key={l.index}>
          {t(`learning.langs.${l.name}`)}
        </MenuItem>
      ))}
    </LearningLanguageSelectorStyles>
  );
};

const mapState = (state: AppStateType) => {
  const { user } = state;
  return {
    user,
  };
};

export default connect(mapState, { setLearningLanguage })(LearningLanguageSelector);
