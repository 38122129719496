import React from 'react';

import {
  Progress,
  ProgressBg,
  ProgressPanel,
} from './ProgressBar.Styles';

type ProgressBarProps = {
  max?: number;
  value?: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ max = 100, value = 0 }) => {
  return (
    <Progress className="progress">
      <progress max={max} value={value} />
      <ProgressBg className="progress-bg">
        <ProgressPanel className="progress-bar" style={{ width: `${value}%` }} />
      </ProgressBg>
    </Progress>
  );
};

export default React.memo(ProgressBar);
