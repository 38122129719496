import styled from 'styled-components';

export const SettingsPage: any = styled.div`
  .setting {
    &-form {
      &__input {
        margin-bottom: 20px;
      }
    }
  }
  .settings__title {
    margin: 0 0 30px;
  }

  p {
    font-size: 12px;
    line-height: 15px;
    color: var(--white);
  }

  .setting__input {
    max-width: 313px;
  }

  .setting__ref-input {
    max-width: 359px;
  }

  .setting__ref-link {
    margin: 15px 0 54px;
    font-size: 12px;
    line-height: 15px;
    color: var(--white);
    text-decoration: underline;
    cursor: pointer;
  }

  .setting-input__text {
    margin: 55px 0 30px;
    font-size: 12px;
    line-height: 15px;
    color: #d1d1d1;
  }

  .setting__btn {
    margin-top: 35px;
  }

  .settings__wallet-label {
    display: block;
    margin: 30px 0 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #d1d1d1;
  }

  .settings__wallet-text {
    margin: 0 0 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    @media (max-width: 576px) {
      width: 100%;
      overflow-x: scroll;
    }
  }

  .setting__btn_secondary {
    min-width: 185px;
    font-weight: 400;
  }

  .setting__input_mb {
    margin-bottom: 50px;
  }

  .settings__update-text {
    font-weight: 500;
    font-size: 14px;
    color: #16e9d1;
  }

  .setting__turnover-info {
    padding: 18px 14px;
    background: #373d47;
    border-radius: var(--border-radius);

    p {
      margin: 0;
      font-size: 20px;
      line-height: 24px;
      color: #d1d1d1;
    }

    span {
      display: block;
      margin: 8px 0;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      background-image: linear-gradient(180deg, rgba(226, 174, 255, 0.66) 0%, #fc54ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  
  .setting-user__link {
    color: var(--black);
  }

  .setting-input__text {
    color: var(--black);
  }

  .settings__wallet-label {
    color: var(--black);
  }

  .settings__wallet-text {
    color: var(--black);
  }

  .setting__turnover-info {
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.04);
    background: #fafafa;

    p {
      color: var(--black);
    }

    span {
      background-image: linear-gradient(180deg, rgba(226, 174, 255, 0.66) 0%, #fc54ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }


  @media (max-width: 768px) {
    .setting__input {
      max-width: 100%;
    }

    .setting__ref-input {
      max-width: 100%;
    }

    .setting__btn {
      width: 100%;
    }
  }

  @media (max-width: 374px) {
    .settings__wallet-text {
      font-size: 12px;
    }
  }
`;

export const SettingsUser: any = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 34px;

  .setting-user {
    cursor: pointer;

    &__img {
      width: 60px;
      height: 60px;
      padding: 6px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 20px;
      border: 1px solid var(--ametis);
      box-shadow: 0 0 4px var(--ametis);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &__link {
      background: none;
      border: 0;
      font-size: 12px;
      line-height: 15px;
      color: var(--black);
      text-decoration: underline;
      cursor: pointer;
    }

    &__input {
      display: none;
    }
  }
`;

export const SettingsFormBox: any = styled.form`
  display: flex;

  @media (max-width: 992px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

export const SettingsItem: any = styled.div`
  width: 100%;
  max-width: 325px;

  &:not(:last-child) {
    margin-right: 130px;
  }

  @media (max-width: 1200px) {
    &:not(:last-child) {
      margin-right: 25px;
      margin-bottom: 55px;
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;

    &.ref-item {
      max-width: 100%;
    }

    &:not(:last-child) {
      margin-right: 0;
    }
  }
`;
