import styled from 'styled-components';

export const Level: any = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
  border-radius: var(--border-radius);

  &:last-child {
    margin-bottom: 0;
  }
  
  &.-inactive {
    opacity: 0.5;
  }

  &[data-title] {
    &:before {
      content: attr(data-title);
      position: absolute;
      top: 100%;
      left: 40px;
      padding: 16px;
      font-size: 12px;
      line-height: 15px;
      color: var(--white);
      background: #292f39;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      border-radius: var(--border-radius);
      opacity: 0;
      visibility: hidden;
      transform: translateY(-10px);
      transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, transform 0.5s ease-in-out;
      z-index: 3;
    }

    &:hover {
      &:before {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        content: attr(data-title);
      }
    }
  }

  // Green
  &.completed {
    box-shadow: 0 0 12px rgba(0, 255, 240, 0.6);

    .level__right {
      svg {
        fill: #95d9ca;
      }
    }

    &[data-title] {
      &:before {
        color: #95d9ca;
      }
    }
  }

  &[data-title] {
    &:before {
      color: var(--black);
      background: #fafafa;
      box-shadow: 0 0 11px rgba(0, 0, 0, 0.04);
    }
  }

  .level__text p {
    color: #1b1b1b;
  }

  .level__text span {
    color: #78808d;
  }

  .level__right {
    color: #1b1b1b;
  }

  .progress-bar {
    background: linear-gradient(180deg, #dde7ff 0%, #f3f7ff 100%);
  }

  .progress-bg {
    background-color: #FBFCFF;
  }

  // Green
  &.completed {
    box-shadow: 0 0 12px #85ebd1;

    .level__right {
      svg {
        fill: #85ebd1;
      }
    }

    &[data-title] {
      &:before {
        color: #85ebd1;
      }
    }
  }

  @media only screen and (max-width: 40.0625em) {
    margin-bottom: 1rem;

    &[data-title] {
      &:before {
        display: none;
      }
    }
  }
`;
export const LevelBox: any = styled.a`
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: var(--border-radius);
  z-index: 2;
  padding: 1rem;

  &:hover {
    cursor: pointer;

    ~ .tooltip {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
`;
export const LevelLeft: any = styled.div`
  flex: 1 1 auto;
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 5px;
`;
export const LevelRight: any = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  font-size: 12px;
  line-height: 15px;
  color: var(--white);

  p {
    margin: 0;
    font-weight: 700;
  }

  span {
    display: block;
    text-align: right;
    font-weight: 400;
    color: #a6a6a6;
  }
`;

export const LevelText: any = styled.div`
  flex: 1;

  p {
    margin: 0;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: var(--white);
  }

  span {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #a6a6a6;
  }
`;
