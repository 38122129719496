import React, {useCallback} from "react";
import {AccentsStyle} from "./Accents.styles"
import {CardListItem, CardListText, CardListValue} from "../../Tasks/Tasks.Styles";
import useBackgroundAudio from "../../../hooks/useBackgroundAudio";

interface ModalKeyProps {
  accents: any[];
}

const Accents: React.FC<ModalKeyProps> = (props: ModalKeyProps) => {
  const { accents} = props;
  const { audio, audioLink, setAudio, setAudioLink } = useBackgroundAudio();

  const playFileLink = useCallback(
    (e: React.ChangeEvent<any>, file: string) => {
      e.preventDefault();
      e.stopPropagation();
      if (audio) {
        audio.pause();
      }
      const newAudio: HTMLAudioElement = new Audio(file);
      newAudio.play();
      setAudio(newAudio);
      setAudioLink(file);
    }, [audio]
  );

  const stopFileLink = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      e.stopPropagation();
      audio?.pause();
      setAudio(null);
      setAudioLink(null);
    }, [audio]
  );

  return (
    <AccentsStyle>
      {
        accents.map((accent: any, index: number) => (
          <CardListItem
            key={`accent-${accent.exttask}-${index}`}
          >
            <CardListText>{accent.title}</CardListText>
            <CardListValue>
              {
                audio && audio.src && audioLink === accent?.filelink ? (
                  <button className="speaker" onClick={(e) => stopFileLink(e)}>
                    <img alt="speacker" src="/img/pause_icon.svg"/>
                  </button>
                ) : (
                  // @ts-ignore
                  <button className="speaker" onClick={(e) => playFileLink(e, accent.filelink)}>
                    <img alt="speacker" src="/img/speaker_icon.svg"/>
                  </button>
                )
              }
            </CardListValue>
          </CardListItem>
        ))
      }
    </AccentsStyle>
  );
};

export default Accents;
