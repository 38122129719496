import styled from 'styled-components';

export const SocialNetworksStyles = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex: 0 0 auto;
    width: 30px;
    height: 30px;
    fill: #727a87;
    transition: fill 0.3s ease-in-out;
  }

  .link:not(:last-child) {
    margin-right: 20px;
  }

  .link {
    width: 32px;
    height: 32px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      svg {
        fill: #eaeaea;
      }
    }
  }

  &.header__social {
    margin-right: 40px;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  &.registration__social {
    margin-top: 85px;
  }

  &.sidebar__social {
    display: none;
    margin-top: 20px;

    a:not(:last-child) {
      margin-right: 10px;
    }

    .link {
      padding: 5px;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &.-light {
    svg {
      fill: #727a87;
    }

    .link {
      &:hover {
        svg {
          fill: #1b1b1b;
        }
      }
    }

    .MuiListItem-button a {
      color: var(--brown);
    }
  }

  @media (max-width: 1440px) {
    &.header__social {
      margin-right: 20px;

      .link:not(:last-child) {
        margin-right: 20px;
      }

      .link {
        padding: 5px;
      }
    }

    &.registration__social {
      margin-top: 25px;

      .link:not(:last-child) {
        margin-right: 20px;
      }

      .link {
        padding: 5px;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  @media (max-width: 992px) {
    &.header__social {
      margin-right: 0;
      order: 1;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &.registration__social {
      margin-top: 25px;

      .link:not(:last-child) {
        margin-right: 20px;
      }

      .link {
        padding: 5px;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  @media (max-width: 576px) {
    &.header__social {
      display: none;
    }

    &.sidebar__social {
      display: flex;
      justify-content: space-between;
    }
  }
`;
