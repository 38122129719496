import styled from 'styled-components';

export const InfoItemContainer: any = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &.balance {
    margin-top: 42px;

    span:last-child {
      font-size: 18px;
      color: #ff89cb;
    }
  }

  &.white {
    span:last-child {
      color: var(--white);
    }
  }

  &.text-xs {
    span:first-child {
      font-family: var(--poppins);
      font-weight: 300;
      font-size: 12px;
      lin
      e-height: 18px;
    }
  }

  &.disable-divider {
    div {
      display: none;
    }
  }

  &.fw-300 {
    span:first-child {
      font-weight: 300;
    }
  }

  &.fw-700 {
    span:first-child {
      font-weight: 700;
    }
  }

  &.text-pink {
    span:last-child {
      color: #ff89cb;
    }
  }

  &.old {
    span:last-child {
      font-size: 14px;
      line-height: 17px;
      text-decoration: line-through;
      color: rgba(255, 255, 255, 0.67);
    }
  }

  &.total {
    span:first-child {
      color: var(--white);
    }

    span:last-child {
      color: #ffedff;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &.montserrat {
    span:last-child {
      font-family: var(--montserrat);
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const InfoItemText: any = styled.span`
  flex: 0 0 auto;
  font-size: 14px;
  line-height: 17px;
  color: var(--black);

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const InfoItemDivider: any = styled.div`
  flex: 1 1 auto;
  height: 1px;
  background-image: linear-gradient(
    -90deg,
    var(--red) 0%,
    rgba(255, 255, 255, 0.06) 71.01%,
    rgba(255, 255, 255, 0) 99.52%
  );
`;

export const InfoItemValue: any = styled.span`
  white-space: nowrap;
  flex: 0 0 auto;
  min-width: 60px;
  text-align: right;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: var(--black);

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
