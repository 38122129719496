import React from 'react';
import { useParams } from "react-router-dom";
import {
  MainContent,
  Container,
} from '../../elements';
import {
  TasksContent,
} from "../../components/Tasks/Tasks.Styles";
import {
  TrueFalse,
  Goal,
  Missing,
  Multiple,
  FindInformation,
  Questions,
  Preposition,
  GerundInfinitive
} from "../../components/Tasks";
import {EXERCISES_ALIAS, EXERCISES_SKILLS} from "../../const/exercises.constants";

const Exercises: React.FC<any> = () => {
  let { task } = useParams();

  const Exercise = () => {
    switch (task) {
      case EXERCISES_ALIAS.LISTENING_TRUE_FALSE:
        return <TrueFalse exerciseSkill={EXERCISES_SKILLS.LISTENING} />;
      case EXERCISES_ALIAS.LISTENING_GOAL:
        return <Goal exerciseSkill={EXERCISES_SKILLS.LISTENING} />;
      case EXERCISES_ALIAS.LISTENING_MISSING:
        return <Missing exerciseSkill={EXERCISES_SKILLS.LISTENING} />;
      case EXERCISES_ALIAS.LISTENING_MULTIPLE:
        return <Multiple exerciseSkill={EXERCISES_SKILLS.LISTENING} />;
      case EXERCISES_ALIAS.LISTENING_FIND_INFORMATION:
        return <FindInformation exerciseSkill={EXERCISES_SKILLS.LISTENING} />;
      case EXERCISES_ALIAS.LISTENING_QUESTIONS:
        return <Questions exerciseSkill={EXERCISES_SKILLS.LISTENING} />;
      case EXERCISES_ALIAS.LISTENING_PREPOSITION:
        return <Preposition exerciseSkill={EXERCISES_SKILLS.LISTENING} />;
      case EXERCISES_ALIAS.LISTENING_GERUND_INFINITIVE:
        return <GerundInfinitive exerciseSkill={EXERCISES_SKILLS.LISTENING} />;
      default:
        return null;
    }
  };

  return (
    <>
      <MainContent className="content-main home-page">
        <Container>
          <TasksContent className="tasks">
            <Exercise />
          </TasksContent>
        </Container>
      </MainContent>
    </>
  );
};

export default Exercises;
