import React from 'react';

import { ScrollBarStyles } from './ScrollBar.Styles';

interface Props {
  className?: string;
  style?: object;
  children?: any;
}

export const ScrollBar: React.FC<Props> = ({ className, style, children }) => {
  return (
    <ScrollBarStyles
      className={className}
      style={{ height: '100%', width: '100%', ...style }}
      autoHide={false}
    >
      {children}
    </ScrollBarStyles>
  );
};
