import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { HeaderComponent } from './Header.styles';
import { PATHS } from "../../const/paths.constants";
import { AppStateType} from "../../store";
import { setMobileMenuOpen } from "../../store/app/actions";
import { LanguageSelector } from "../../elements";
import { useTranslation } from "react-i18next";
import {UserReducerState} from "../../store/user/reducers";

type HeaderProps = {
  user: UserReducerState;
  mobileMenuOpened: boolean;
  setMobileMenuOpen: (value: boolean) => void;
};

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { user, mobileMenuOpened, setMobileMenuOpen } = props;
  const { t } = useTranslation();

  return (
    <HeaderComponent>
      <div className="grid-container">
        <div className="navigation">
          {/* Mobile */}
          <nav className="grid-x align-middle navigation--collapsed title-bar">
            <div className="navigation__logo -left">
              <a className="navigation__logo-link" href="/">Didactist</a>
            </div>

            <div className="navigation-lang__mobile">
              <LanguageSelector />
            </div>

            <button
              className="menu-icon right"
              onClick={() => setMobileMenuOpen(!mobileMenuOpened)}
            >
              <div className="x" />
              <div className="y" />
              <div className="z" />
            </button>
          </nav>
          {/* Desktop */}
          <nav className="align-middle stacked-for-medium navigation--expanded top-bar" id="navigation--expanded">
            <div className="navigation__logo -left">
              <a className="navigation__logo-link" href="/">Didactist</a>
            </div>
            <div className="navigation__middle top-bar-right">
              <ul className="menu dropdown" role="menubar">

                <li className="has-submenu" role="menuitem">
                  <span className="link sub-link">{t('header.features')}</span>
                  <ul className="submenu">
                    <li role="menuitem">
                      <Link className="link" target="_blank" to={PATHS.JOTTER}>JOTTER</Link>
                    </li>
                    <li role="menuitem">
                      <Link className="link" target="_blank" to={PATHS.SYNCHRONIST}>SYNCHRONIST</Link>
                    </li>
                  </ul>
                </li>

                <li role="menuitem">
                  <Link className="link" to={PATHS.FEATURES}>{t('header.roadmap')}</Link>
                </li>

                <li role="menuitem">
                  <Link className="link" target="_blank" to={PATHS.BLOG}>{t('header.blog')}</Link>
                </li>
                {
                  user.loggedIn ? (
                    <li role="menuitem">
                      <Link className="link" to={PATHS.DASHBOARD}>{t('header.dashboard')}</Link>
                    </li>
                  ) : (
                    <li role="menuitem">
                      <Link className="link" to={PATHS.LOGIN}>{t('header.login')}</Link>
                    </li>
                  )
                }

              </ul>
            </div>
            <div className="navigation__lang-wrap">
              <LanguageSelector />
            </div>
          </nav>
        </div>
      </div>
    </HeaderComponent>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { app, user } = state;
  return {
    user,
    mobileMenuOpened: app.mobileMenuOpened,
  };
};

export default connect(mapStateToProps, { setMobileMenuOpen })(Header);
