import styled from 'styled-components';

export const MobileSidebarWrapper: any = styled.div`
  position: sticky;
  z-index: 999;
  bottom: 0;
  width: 100vw;
  height: 56px;
  font-family: var(--montserrat);

  &::before {
    content: '';
    position: absolute;
    left: 1rem;
    top: 0;
    width: calc(100% - 2rem);
    height: 1px;
    background: radial-gradient(
            circle,
            var(--red) 50.52% 0%,
            rgba(255, 255, 255, 0) 100%
    );
  }
  
  .mobileMenu {
    &-wrap {
      display: flex;
      flex-wrap: wrap;
      padding: 6px 4px;
      width: 100%;
      height: 100%;
      background: var(--white);
    }

    &-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      fill: var(--black);
      
      svg {
        width: 20px;
        min-width: 20px;
        fill: var(--black);
        margin-bottom: 6px;
        transition: fill 0.3s ease-in-out, stroke 0.3s ease-in-out;
      }
    }

    &-item {
      flex: 1;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 1rem;
      margin: 0.5rem 1rem;
      text-align: center;
      color: var(--dark-gray);
      border-radius: var(--border-radius);
      border: 3px solid var(--red);
      transition: background-color 0.3s, border-color 0.3s, color 0.3s;
      cursor: pointer;
      outline: none;
      text-transform: uppercase;

      &.-active {
        background: var(--red);
        
        svg {
          fill: var(--white);
        }
        
        .mobileMenu-text {
          color: var(--white);
        }
      }
    }
  }

  @media only screen and (min-width: 40.0625em) {
    display: none;
  }
`;
