import styled from 'styled-components';

export const HomeSlide: any = styled.div`
  padding: 120px 0 160px 0;
  background-image: url(/img/round-and-i-smooth.png), linear-gradient(180deg, hsla(0, 0%, 100%, 0.3), hsla(0, 0%, 100%, 0.3)), url(/img/bg-first.jpg);
  background-position: bottom left, 0 0, 0 0;
  background-size: 140px, auto, cover;
  background-repeat: no-repeat, repeat, no-repeat;
  height: calc(100vh - 70px);
  min-height: 720px;

  .home {
    display: flex;

    &__row {
      display: block;
      align-content: center;
      justify-content: center;
      align-items: center;
    }

    &__kvadraty {
      position: absolute;
      right: 30px;
      top: 30px;
      width: 192px;
      height: 225px;
      background-image: url(/img/kvadraty-smooth.png);
      background-repeat: no-repeat;
      background-position: right 10px;
      background-size: 140px;
    }

    &__block {
      width: fit-content;
      margin: 30px auto 0;
    }

    &__name {
      font-family: 'Poppins', sans-serif;
      color: #280e0e;
      font-size: 108px;
      line-height: 80px;
      font-weight: 400;
      margin: -40px 0 20px;

      &-over {
        text-align: right;
        color: #565656;
        font-size: 14px;
        line-height: 22px;
        font-weight: 600;
        letter-spacing: 0;
        margin-bottom: 0;
        margin-right: 4px;
        text-transform: uppercase;
      }

      &-img {
        width: 140px;
        vertical-align: baseline;
      }

      &-under {
        text-align: center;
        max-width: 500px;
        color: #565656;
        font-size: 14px;
        line-height: 22px;
        font-weight: 600;
        margin-top: 26px;
        margin-left: 4px;
        text-transform: uppercase;
      }
    }

    &__btn {
      padding: 12px 100px;
      
      &-wrap {
        display: flex;
        justify-content: center;
        margin: 40px 0;
      }
    }
  }
  
  .sinch {
    position: absolute;
    right: calc(50% - 167px);
    top: 10px;
    width: 334px;
    height: 100px;
    
    &-link {
      position: relative;
      width: 100%;
      height: 100%;
    }
    
    &-logo {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 40.0625em) {
    min-height: 100vh;
    background-size: 120px, auto, cover;
    padding: 50px 0 160px;
    
    .home {
      &__kvadraty {
        width: 64px;
        height: 75px;
        right: 1em;
        background-position: right top;
        background-size: contain;
      }
      
      &__name {
        margin: 0;
        font-size: 70px;
        text-align: center;
        
        &-over {
          margin-right: 1em;
        }
        
        &-under {
          margin: 0 1em;
        }
        
        &-img {
          width: 80px;
        }
      }
    }

    .sinch {
      right: calc(50% - 83.6px);
      width: 167px;
      height: 50px;
    }
  }

  @media (max-width: 20em) {
    height: calc(140vh - 70px);
    
    .home {
      &__name {
        font-size: 50px;

        &-img {
          width: 65px;
        }

        &-under {
          font-size: 12px;
        }
      }
      
      &__btn {
        &-wrap {
          margin: 20px 0;
        }
      }
    }
  }
`;

export const HomeChoose: any = styled.div`

  .home {
    &__choose {
      padding: 60px 24px 70px;

      &-title {
        font-size: 30px;
        margin-bottom: 16px;
        text-align: center;
      }

      &-text {
        margin-bottom: 26px;
        text-align: center;
        white-space: break-spaces;
      }

      &-parametr {
        width: 100%;;
        margin: 0 auto;
      }
      
      &-btns {
        display: flex;
        justify-content: center;
      }
      
      &-btn {
        width: 25%;
        background: var(--black);
        color: var(--light-gray);
        text-transform: uppercase;
        font-size: 14px;
        text-align: center;
        letter-spacing: 1px;
        border-radius: 0;
        padding: 16px 0;
        outline: 0;
        box-shadow: none;
        border: 1px solid var(--white);
        cursor: pointer;
        
        &.-active {
          background: var(--red);
          color: var(--white);
          font-weight: 600;
        }

        &.-inactive {
          pointer-events: none;
        }

        &:disabled, &[disabled], &.-disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
      
      &-subbtns {
        display: none;
        
        &.-active {
          display: block;
        }
      }
      
      &-inners {
        display: flex;
        margin-top: 30px;
      }
      
      &-inner {
        margin-right: 30px;
        width: 31.6666%;
        
        &:last-child {
          margin-right: 0;
        }
      }
      
      &-name {
        text-align: left;
        font-size: 20px;
        line-height: 30px;
        padding: 0 0 10px;
        font-weight: 600;
        text-transform: uppercase;
      }
      
      &-subbtn {
        width: 100%;
        margin-bottom: 10px;
        padding: 16px 20px;
        text-align: left;
        background-color: var(--white);
        border: 1px solid rgba(178,34,34,.4196078431);
        font-size: 18px;
        color: var(--black);
        cursor: pointer;

        &:hover {
          color: #b22222;
          box-shadow: 0 0 3px #b222222b;
          background: #fdf8f8;
        }
        
        &.-active {
          color: #000;
          background-color: #b2222208;
          border: 1px solid #b22222;
        }

        &.-inactive {
          background: 0;
          border-color: #ccc;
          color: #ccc;
          pointer-events: none;
        }
        
        &:disabled, &[disabled], &.-disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
      
      &-submit {
        display: flex;
        text-align: center;
        color: var(--dark-gray);
        border-radius: var(--border-radius);
        border: 3px solid var(--red);
        padding: 12px 30px;
        transition: background-color 0.3s, border-color 0.3s, color 0.3s;
        cursor: pointer;
        outline: none;
        font-size: 16px;
        line-height: 28px;
        font-weight: 600;
        letter-spacing: 1px;
        font-family: var(--poppins);
        text-transform: uppercase;
        background: none;
        
        &_wrap {
          display: flex;
          justify-content: center;
          margin: 50px 0;
        }

        &:disabled,
        &[disabled] {
          cursor: not-allowed;
          pointer-events: none;
          color: var(--red);
        }
      }
    }
  }

  @media (max-width: 40.0625em) {
    .home {
      &__choose {
        &-parametr {
          width: 100%;
        }

        &-btns {
          flex-wrap: wrap;
        }

        &-btn {
          width: 50%;
        }

        &-inners {
          flex-direction: column;
        }

        &-inner {
          display: flex;
          flex-wrap: wrap;
          margin-left: -5px;
          margin-right: -5px;
          width: calc(100% + 10px);
        }
        
        &-name {
          width: 100%;
          margin-left: 5px;
        }

        &-subbtn {
          width: calc(50% - 10px);
          margin: 5px;
          padding: 6px 10px;
        }
      }
    }
  }

  @media (max-width: 20em) {
    .home {
      &__choose {
        &-title {
          font-size: 28px;
        }
      }
    }
  }
`;

export const HomeMechanic: any = styled.div`
  background: #e7e7e7;
  color: #000;
  
  .mechanics {
    &-title {
      margin-bottom: 40px;
      font-size: 60px;
    }
    
    &-text {
      position: relative;
      display: block;
      padding: 10px 10px 10px 26px;
    }
    
    &-max {
      
    }
    
    &-inner {
      display: flex;
      align-items: flex-start;
      padding-left: 0;
      padding-right: 0;
      
      &.show-hide {
        display: none;
      }
    }
    
    &-name {
      font-weight: 600;
      text-transform: uppercase;
    }
    
    &-imag {
      border: 3px solid #9d323c;
      border-radius: 10px;
      margin-bottom: 50px;
      position: relative;
      margin-right: -11px;

      &:before {
        content: "";
        border: solid transparent;
        position: absolute;
        border-width: 19px;
      }
      
      &.-right {
        &:before {
          border-left-color: #9d323c;
          left: 100%;
          top: 50%;
          margin-top: -19px;
        }
      }

      &.-left {
        &:before {
          border-right-color: #9d323c;
          right: 100%;
          top: 50%;
          margin-top: -19px;
        }
      }
      
      &.-bottom {
        &:before {
          border-top-color: #9d323c;
          top: 100%;
          left: 50%;
          margin-left: -19px;
        }
      }
    }
    
    &-img {
      max-width: 100%;
      height: auto;
    }
    
    &-num {
      font-size: 16px;
      text-align: center;
      display: block;
    }
    
    &-description {
      font-size: 14px;
      margin-bottom: 0.2rem;
    }
  }

  .xlarge-show {
    display: none;
  }

  @media (max-width: 40.0625em) {
    .mechanics {
      &-title {
        text-align: center;
      }

      &-inner {
        &.small-hide {
          display: none;
        }

        &.show-hide {
          display: flex;
        }
      }
      
      &-name {
        margin-top: 0;
      }

      &-imag {
        &:before {
          display: none;
        }
      }
    }
  }

  @media (max-width: 74.9375em) {
    .mechanics {
      &-imag {
        &:before {
          content: "";
          border: 19px solid transparent;
          position: absolute;
          border-left-color: rgb(157, 50, 60);
          left: 100% !important;
          top: 50% !important;
          margin-top: -19px;
          margin-left: 0!important;
          border-top-color: transparent!important;
          border-right-color: transparent!important;
        }

        &.-left {
          &:before {
            display: none;
          }
        }
      }
    }
  }

  @media (min-width: 74.9375em) {
    .small-show {
      display: none;
    }
    
    .xlarge-show {
      display: flex;
    }
  }

  @media (max-width: 20em) {
    .mechanics {
      &-title {
        font-size: 44px;
      }
    }
  }
`;

export const HomeUniqueness: any = styled.div`
  background: #ddd;
  padding: 60px 0;
  
  .uniqueness {
    &-title {
      font-size: calc(1.3rem + .6vw);
      margin-bottom: 40px
    }
    
    &-text {
      margin-bottom: 40px;
      font-size: 60px;
    }
    
    &-row {
      align-items: center;
    }
    
    &-inner {
      padding: 40px 20px;
    }
    
    &-first {
      font-size: 26px;
      color: #fff;
      background: #b02a37;
      padding: 10px 20px;
      font-weight: 500;
      line-height: 54px;
    }
    
    &-img {
      max-width: 100%;
      height: auto;
    }
  }

  @media (max-width: 40.0625em) {
    .uniqueness {
      &-text {
        text-align: center;
      }
    }
  }

  @media (max-width: 20em) {
    .uniqueness {
      &-text {
        font-size: 44px;
      }
    }
  }
`;

export const HomeChallenges: any = styled.div`
  background: #fff;
  padding: 60px 0;
  
  .challenges {
    &-title {
      font-size: calc(1.3rem + .6vw);
      margin-bottom: 40px
    }
    
    &-col {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      margin: 10px 0;
      padding-right: 18px;
    }
    
    &-inner {
      background: #fff;
      display: block;
      padding: 14px 10px;
      margin: 6px;
      width: 100%;
      position: relative;
      border: 2px solid #495057;
      box-shadow: 10px 10px #6c757d99;
      
      &.-arrow {
        &:before {
          content: "";
          border: solid transparent;
          position: absolute;
          left: 100%;
          top: 50%;
          border-left-color: #000;
          border-width: 19px;
          margin-top: -19px;
        }
      }
      
      &.-big {
        font-size: 24px;
        font-weight: 400;
        text-align: left;
        border: 6px solid #852730;
      }
    }
  }

  @media (max-width: 40.0625em) {
    .challenges {
      &-inner {
        box-shadow: 5px 5px #6c757d99;
        
        &.-arrow {
          &:before {
            content: "";
            border: solid transparent;
            position: absolute;
            top: 100%;
            left: 50%;
            border-top-color: #000;
            border-width: 19px;
            margin-left: -19px;
            margin-top: 0;
          }
        }
      }
    }
  }

  @media (max-width: 20em) {
    .challenges {
      &-title {
      }
    }
  }
`;

export const HomeProblems: any = styled.div`
  background: #000;
  color: #fff;
  padding: 70px 0;
  
  .problems {
    &-title {
      font-size: calc(1.3rem + .6vw);
      margin-bottom: 40px
    }

    &-text {
      padding-bottom: 30px;
      font-size: calc(1.375rem + 1.5vw);
    }
    
    &-row {
      display: flex;
      align-items: center;
      width: 100%;
    }
    
    &-swing {
      padding: 40px 100px;
      background: #000;
      width: 100%;
      
      &__bg {
        position: relative;
        padding-bottom: 50px;
        
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: -5px;
          left: 10px;
          width: 97%;
          height: 100%;
          border-bottom: 16px solid #b22222;
          border-radius: 0;
          transform: rotate(357deg);
          pointer-events: none;
        }
      }
      
      &__text {
        padding-top: 30px;
      }
      
      &__title {
        font-size: 24px;
      }
      
      &__ul {
        margin-bottom: 0;
      }
      
      &__li {
        
      }
    }
    
    &-img {
      width: 100px;
      height: 100px;
      
      &__wrap {
        &.-top {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          margin-bottom: -20px;
          text-align: center;
        }
        
        &.-bottom {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  @media (max-width: 40.0625em) {
    .problems {
      &-swing {
        padding: 0;
        &__bg {
          &::after {
            left: 0;
          }
        }
      }
    }
  }

  @media (max-width: 20em) {
    .problems {
      &-title {
      }
    }
  }
`;

export const HomeBenefits: any = styled.div`
  width: 100%;
  padding: 60px 0 100px 0;
  background: #ddd;
  
  .benefits {
    &-maintitle {
      font-size: calc(1.3rem + .6vw);
      margin-bottom: 40px
    }

    &-maintext {
      padding-bottom: 30px;
      font-size: calc(1.375rem + 1.5vw);
    }
    
    &-col {
      margin-bottom: 70px;
    }
    
    &-title {
      color: #ffffff;
      font-size: 20px;
      margin: 0;
      
      &__wrap {
        background: #dc3545;
        border-radius: 10px;
        margin: 0 13px;
        padding: 10px 20px;
      }
    }

    &-text {
      color: #000000;
      margin: 0;
      margin-top: 10px;
      
      &__wrap {
        border: 1px solid #dc3545;
        border-radius: 10px;
        padding: 10px;
        margin-top: -10px;
        height: 100%;
      }
    }
  }

  @media (max-width: 20em) {
    .benefits {
      &-title {
      }
    }
  }
`;

export const HomeAddons: any = styled.div`
  background: #ffffff;
  padding: 60px 0;
  
  .addons {
    &-maintitle {
      font-size: calc(1.3rem + .6vw);
      margin-bottom: 50px;
    }

    &-maintext {
      padding-bottom: 30px;
      font-size: calc(1.375rem + 1.5vw);
    }
    
    &-row {
      justify-content: space-between;
    }
    
    &-col {
      margin-bottom: 50px;
    }

    &-list {
      background: #dc3545;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      margin: 0 13px;
      padding: 10px 20px;
      margin-top: -7px;
      z-index: 5;
      display: block;
      position: relative;
      
      &__title {
        font-size: 26px;
        color: #ffffff;
      }
      
      &__ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      
      &__li {
        color: #ffffff;
      }
    }
  }

  @media (max-width: 40.0625em) {
    .addons {
      &-img {
        height: 50px;
      }
    }
  }

  @media (max-width: 20em) {
    .addons {
      &-title {
      }
    }
  }
`;

export const LearnLen: any = styled.div`
  padding-top: 3rem;
  
  .learnLan {
    &-title {
      margin: 0 0 25px;
      font-size: 25px;
      line-height: 30px;
      color: var(--black);
      font-weight: 700;
    }
    
    &-container {
      display: flex;
      padding-top: 0.5rem;
      margin-bottom: 20px;
      overflow: hidden;
      flex: 0 0 100%;
      align-items: center;
      justify-content: center;
    }
  }

  @media only screen and (max-width: 40.0625em) {
    .learnLan {
      &-title {
        display: block;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
      }

      &-container {
        
      }
    }
  }
`;
