import React, { useRef } from 'react';

import { ModalStyles } from './Modal.Styles';
import useBackgroundAudio from "../../hooks/useBackgroundAudio";

interface Props {
  opened?: boolean;
  closeModal?: () => void;
  className?: string;
  hasCloseBtn?: boolean
  children?: any;
}

export const Modal: React.FC<Props> = (props: Props) => {
  const { opened, closeModal, hasCloseBtn, className, children } = props;
  const modalRef = useRef<HTMLDivElement>(null);
  const { remove } = useBackgroundAudio();

  const closeClickBackdrop = (e: any) => {
    e.stopPropagation();
    remove();
    if (!closeModal) return false;
    if (e.target === modalRef.current) return closeModal();
  };

  const closeClick = (e: any) => {
    e.stopPropagation();
    remove();
    if (!closeModal) return false;
    return closeModal();
  };

  if (!opened) {
    return null;
  }

  return (
    <ModalStyles
      ref={modalRef}
      className={className}
      onClick={closeClickBackdrop}
    >
      <div className={`modal__dialog ${opened ? 'active' : ''}`}>
        {
          hasCloseBtn ? (
            <button className="modal__close-btn" onClick={closeClick} />
          ) : null
        }
        {children}
      </div>
    </ModalStyles>
  );
};
