import { Title } from '../../elements';
import styled from 'styled-components';
import {Select} from "@mui/material";

export const TasksContent: any = styled.div`

`;

export const TaskContent: any = styled.div`

`;

export const TaskInfo: any = styled.div`
  margin-bottom: 30px;
  width: 100%;
  max-width: 480px;

  p {
    margin: 0 0 20px;
    font-size: 14px;
    line-height: 20px;
    color: var(--black);
  }
`;

export const TaskExercises = styled.div`
  @media (max-width: 992px) {
    margin-right: -16px;
  }
`;

export const TaskExercisesTitle = styled(Title)`
  margin: 0 0 35px;
  font-weight: 600;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  margin: 0 0 15px;
  
  .task-grid {
    width: 100%;
  }
`;

export const ConfigSelectorStyles: any = styled(Select)`
  background-color: transparent;
  width: auto;
  text-align: center;
  text-transform: uppercase;
  border-radius: var(--border-radius);
  border: 3px solid var(--red);
  padding: 10px 56px;
  transition: background-color 0.3s ease 0s, border-color 0.3s ease 0s, color 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  background: none;
  position: relative;
  margin-bottom: 1em;

  .MuiSelect-select {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: var(--poppins);
    color: var(--dark-gray);
    min-height: 0!important;
    background-color: transparent;
  }

  .MuiSelect-selectMenu {
    height: auto;
    border-radius: 0;
  }

  .MuiMenu-paper {
    border-radius: var(--border-radius);
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.04);
  }

  .MuiList-root {
    background: var(--white);
    border-radius: 13px;
  }

  .MuiList-padding {
    padding: 11px 8px;
  }

  .MuiListItem-button {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--light-gray);
    border-radius: 8px;

    &::after {
      display: none;
    }

    &:focus,
    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }

    &.Mui-selected {
      background-color: transparent;
      text-decoration: underline;
    }
  }

  .MuiMenuItem-root {
    min-height: auto;
  }
` ;

export const Card = styled.div`
  position: relative;
  min-width: 220px;
  min-height: 290px;
  padding: 25px 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  background-color: var(--light-blue);
  border-radius: var(--border-radius);

  .task-item__btn {
    width: 100%;
  }
  
  .task-item__label {
    display: block;
    width: 100%;
    height: 58px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: var(--poppins);
    color: var(--dark-gray);
    background-color: transparent;
    min-height: 0 !important;
    line-height: 58px;
    text-transform: uppercase;
    transition: opacity 0.3s ease-in-out;
    transform: none;
    transform-origin: unset;
    top: 0;
    left: 0;
    max-width: 100%;
    margin-top: 1em;

    &.Mui-focused {
      opacity: 0;
      color: var(--dark-gray);
    }

    &.MuiFormLabel-filled {
      display: none;
    }
  }
  
  .plus {
    display: inline-block;
    height: 70px;
    width: 70px;
    position: relative;
    overflow: hidden;
    margin-bottom: 1rem;

    &:before, &:after {
      content: " ";
      position: absolute;
      background: var(--black);
      transition: all .5s ease .1s;
    }
    
    &:before {
      width: 2px;
      margin-left: -1px;
      left: 50%;
      top: 5px;
      bottom: 5px;
    }

    &:after {
      height: 2px;
      margin-top: -1px;
      top: 50%;
      left: 5px;
      right: 5px;
    }
    
    &-text {
      color: var(--black);
      transition: all .5s ease .1s;
    }
  }
  
  &:before {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: -1px;
    left: 0;
    visibility: hidden;
    background: var(--red);
    -webkit-transition: all .5s ease .1s;
    -o-transition: all .5s ease .1s;
    transition: all .5s ease .1s;
    transform: scale3d(0, 1, 0);
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 0;
    border-radius: var(--border-radius);
  }

  &.-add {
    .task-item__btn {
      display: flex;
      flex-direction: column;
      border: 0;
      width: 100%;
      height: 100%;
      z-index: 2;

      &:hover {
        background: none;
      }
    }
    
    &:hover {
      &:before {
        visibility: visible;
        transform: scale3d(1, 1, 1);
        opacity: 1;
      }

      .plus {
        &:before, &:after {
          background: var(--white);
          transform: scale3d(1, 1, 1);
        }

        &-text {
          color: var(--white);
          transform: scale3d(1, 1, 1);
        }
      }
    }
    
    &.-loading {
      pointer-events: none;
      align-items: center;
      justify-content: center;
      
      svg {
        width: 100px;
        height: 100px;
      }
    }
  }
  
  &.-config {
    .task-item__btn {
      background-color: var(--red);
      color: var(--white);
      
      &:hover {
        background-color: var(--dark);
        border: 3px solid var(--dark);
      }
    }
  }
  
  &.-completed {
    background-color: var(--dark-gray);
  }
`;

export const CardTitle = styled.h3`
  margin: 0 0 15px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--black);
  
  &.-completed {
    color: var(--white);
  }
`;

export const CardList = styled.ul`
  &.-completed {
    li {
      margin-bottom: 10px;
    }
    
    span {
      color: var(--white);
    }
  }
`;

export const CardListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const CardListText = styled.span`
  font-size: 12px;
  line-height: 15px;
  color: var(--black);

  &.-small {
    font-size: 10px;
    margin-left: 16px;
  }
`;

export const CardListValue = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: var(--black);
  
  .small {
    font-size: 12px;
  }
  
  .speaker {
    border: 0;
    background: none;
    cursor: pointer;
    width: 50px;
    
    &.-bold {
      font-weight: bold;
    }

    &.-small {
      width: 100px;
      font-size: 12px;
      text-align: right;
    }
  }
`;

export const CardFooter = styled.div`
  margin-top: auto;
  padding-top: 20px;

  &.-completed {
    & .task-item__btn {
      color: var(--white);
      border: 3px solid var(--white);

      &:hover {
        color: var(--dark-gray);
        background-color: var(--light-blue);
        border: 3px solid var(--red);
      }
    }
  }
`;

export const TrueFalseContent = styled.div`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  height: calc(100vh - 150px);
  background-color: var(--light-blue);
  border-radius: var(--border-radius);
  
  .exercise {
    &-text {
      color: var(--white);

      .text {
        margin-top: 2rem;
        padding-right: 1rem;
        text-indent: 2rem;
        
        &.-source {
          text-indent: 0;
          word-break: break-word;
        }
      }

      &__container {
        min-width: 0;
        min-height: 0;
        flex: 1 1 0;
        height: 100%;
        background: rgb(38, 38, 39);
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
      }
      
      &__wrap {
        width: 100%;
        height: 100%;
        padding: 1rem;
        padding-right: 0;
        
        .simplebar {
          &-vertical {
            right: 3px;
          }
        }
        
        &.-withpanel {
          height: calc(100% - 50px);
        }
      }

      &__inner {
        width: 100%;
        height: 100%;
        
        overflow-y: auto;
      }
    }
    
    &-panel {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 50px;
      background-color: var(--light-gray);
      border-top-left-radius: var(--border-radius);
      
      &__switcher {
        color: var(--black);
        
        &.-active {
          color: var(--red);
        }
        
        &.-disabled {
          pointer-events: none;
          opacity: 0.8;
        }
      }
    }
    
    &-audio {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      border-bottom: 1px solid #fff;
      margin-right: 1rem;
      
      &__expand {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 10px 0;
      }
      
      &__arrow {
        color: #AEFAFF;
        transform: rotate(180deg);
        
        &.-open {
          transform: rotate(0);
        }
      }
      
      &__translit {
        color: #AEFAFF;
        text-transform: uppercase;
      }
    }

    &-answers {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      min-width: 0;
      min-height: 0;
      flex: 1 1 0%;
      overflow: hidden;

      &__hint {
        margin: 8px 0;
        color: var(--dark-gray);
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        white-space: pre-line;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
      }
      
      &__wrap {
        display: flex;
        flex-direction: column;
        position: relative;
        min-width: 0;
        min-height: 0;
        flex: 1 1 0%;
        height: 100%;
        padding: 1rem;
        padding-right: 0;
      }

      &__inner {
        display: flex;
        flex-flow: column nowrap;
        height: 100%;
        overflow: hidden;
      }

      &__answer {
        padding-left: 40px;
        padding-right: 60px;
        text-align: left;
        padding-bottom: 40px;
        border-bottom: 1px solid var(--light-gray);
        margin-bottom: 40px;
        width: 100%;

        &:last-child {
          border: 0;
        }

        &.-submited {
          &.-error {
            color: #ff0000;

            & .-correct {
              background-color: #97df97;
            }

            & .-selected {
              background-color: #fc7c7c;
            }
          }
          &.-correct {
            color: #008000;

            & .-selected {
              background-color: #97df97;
            }
          }
        }
      }
      
      &__title {
        margin-bottom: 30px;
      }
      
      &__list {
        display: inline-flex;
        margin: 0 0px -8px;
        list-style: none;
        padding: 0;
        flex-flow: column wrap;
        -webkit-box-align: stretch;
        align-items: stretch;
        max-width: 100%;
        min-width: 168px;
        
        &_item {
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          border: 0;
          border-radius: var(--border-radius);
          background-color: var(--light-gray);
          color: var(--black);
          max-width: 100%;
          min-width: 75px;
          min-height: 40px;
          outline: 0;
          padding: 5px;
          transition-duration: 0.1s;
          transition-property: background-color, color, border-color, opacity, box-shadow;
          transition-timing-function: ease-out;
          cursor: pointer;
          opacity: 1;
          margin: 5px 0;

          &.-selected {
            background-color: #a6a6a6;
          }
        }
      }
      
      &__abc {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        position: relative;
        width: 24px;
        height: 24px;
        border-radius: 5px;
        font-size: 12px;
        background-color: var(--white);
        color: var(--black);
        margin-right: 5px;
      }

      &__word {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        flex: 1 1 0;
        min-width: 75px;
        text-align: left;
        padding-right: 10px;
      }

      &__close {
        position: absolute;
        right: 5px;
        top: 5px;
        width: 80px;
        height: 80px;
        z-index: 1;
        cursor: pointer;
        
        svg {
          height: 100%;
          width: 100%;
        }
        
        .ldl-scale {
          transform-origin: 50% 50%; transform: rotate(0deg) scale(0.8, 0.8);
        }
        
        .ldl-ani__one {
          transform-origin: 50px 50px; transform: rotate(0deg); animation: 1.11111s linear -0.740741s infinite normal forwards running spin;
        }
        
        .ldl-ani__two {
          transform-origin: 50px 50px; transform: rotate(0deg); animation: 1.11111s linear -0.925926s infinite normal forwards running spin;
        }
        
        .ldl-ani__three {
          transform-origin: 50px 50px; transform: rotate(0deg); animation: 1.11111s linear -1.11111s infinite normal forwards running spin;
        }

        @keyframes spin {
          from {
            animation-timing-function: cubic-bezier(0.5856,0.0703,0.4143,0.9297);
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
        
        &_time {
          position: absolute;
          top: calc(50% - 9px);
          right: calc(50% - 18px);
          font-weight: 400;
          font-size: 12px;
          line-height: 1.5;
          color: var(--black);
        }
      }

      .simplebar {
        &-vertical {
          right: 3px;
        }

        &-content {
          &-wrapper {
            padding-right: 1rem;
          }
        }
      }
    }

    &-btns {
      display: flex;
      justify-content: space-between;
      padding-top: 1rem;
      padding-right: 1rem;
    }
    
    &-btn {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.5;
      border: 0;
      background: var(--black);
      color: var(--white);
      padding: 10px;
      border-radius: 0;
      border-right: 1px solid var(--light-blue);
      
      &__right {
        display: flex;
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
      }
    }

    &-result {
      &.-error {
        color: #ff0000;
      }

      &.-success {
        color: #008000;
      }
    }
  }

  @media (max-width: 40.0625em) {
    flex-direction: column;
    max-height: 100%;
    
    .exercise {
      &-text {
        &__container {
          flex: 1;
          max-height: 50%;
          border-top-left-radius: var(--border-radius);
          border-top-right-radius: var(--border-radius);
          border-bottom-left-radius: 0;
        }
      }

      &-answers {
        &__wrap {
          flex: 1;
          max-height: 50%;
          border-top-right-radius: 0;
          border-bottom-right-radius: var(--border-radius);
          border-bottom-left-radius: var(--border-radius);
        }

        &__title {
          margin-bottom: 10px;
        }
      }
    }
  }
`;
