import styled from 'styled-components';

export const ExercisesContent: any = styled.div`

`;

export const MatchTheHeadingsContent: any = styled.div`
  .exercise {
    &-title {
      display: block;
      font-size: 2.5rem;
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;

      &__wrap {
        margin-top: 40px;
        margin-bottom: 30px;
      }
    }
    
    &-list {
      position: relative;
      display: block;
      padding: 0.5rem 1rem;
      color: var(--black);
      text-decoration: none;
      background-color: var(--white);
      border: 1px solid var(--gray);
      border-bottom: 0;
      
      &__wrap {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 40px;
      }

      &:first-child {
        border-top-right-radius: var(--border-radius);
        border-top-left-radius: var(--border-radius);
      }

      &:last-child {
        border-bottom-right-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
        border-bottom: 1px solid var(--gray);
      }
    }

    &-article {
      & .h3 {
        margin-bottom: 10px;
      }
    }

    &-description {
      margin-top: 40px;

      & .bolder {
        font-weight: 400;
        font-size: 120%;
        font-style: italic;
      }
    }

    &-form {
      
    }

    &-text {
      &__wrap {
        margin: 40px 0;
      }
    }
    
    &-input {
      margin-left: -1px;
      border-radius: 0.5rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      font-size: 1rem;
      padding: 0.5rem 1rem;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      margin-bottom: 0;
      
      &__wrap {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
      }
      
      &__label {
        font-size: 1rem;
        background: #000;
        color: #ffffffe0;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 400;
        border-radius: 0.5rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        line-height: 1.5;
        text-align: center;
        white-space: nowrap;
        border: 1px solid #ced4da;
      }
    }

    &-btn {
      &__wrap {
        margin: 40px 0;
      }
    }

    &-error {
      & .bolder {
        color: #ff0000;
      }
    }

    &-line {
      margin-bottom: 20px;

      &.-error {
        & .exercise-input {
          border: 1px solid #ff0000;
        }
      }

      &.-success {
        & .exercise-input {
          border: 1px solid #008000;
        }
      }
    }

    &-result {
      margin-bottom: 30px;

      &.-error {
        color: #ff0000;
      }

      &.-success {
        color: #008000;
      }
    }
  }
`;

export const TrueFalseContent: any = styled.div`
  display: flex;
  flex-flow: row nowrap;
  min-height: calc(100vh - 150px);
  background-color: var(--white);
  padding: 50px 0;

  .exercise {
    &-container {
      display: flex;
      width: 100%;
    }
    &-text {
      color: var(--white);
      
      .text {
        margin-top: 2rem;
        padding-right: 1rem;
      }

      &__wrap {
        min-width: 0;
        min-height: 0;
        flex: 1 1 0;
        padding: 1rem;
        padding-right: 0;
        background: rgb(38, 38, 39);
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
        height: calc(100vh - 250px);
        overflow: hidden;

        .simplebar {
          &-vertical {
            right: 3px;
          }
        }
      }

      &__inner {
        width: 100%;
        height: 100%;

        overflow-y: auto;
      }
    }

    &-answers {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      min-width: 0;
      min-height: 0;
      flex: 1 1 0%;
      overflow: hidden;

      &__wrap {
        display: flex;
        flex-direction: column;
        position: relative;
        min-width: 0;
        min-height: 0;
        flex: 1 1 0%;
        height: calc(100vh - 250px);
        padding: 1rem;
        padding-right: 0;
        background-color: var(--light-blue);
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
      }

      &__inner {
        display: flex;
        flex-flow: column nowrap;
        height: 100%;
        overflow: hidden;
      }

      &__title {
        margin-bottom: 30px;
      }
      
      &__hint {
        margin: 8px 0;
        color: var(--dark-gray);
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        white-space: pre-line;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
      }

      &__list {
        display: inline-flex;
        margin: 0 0px -8px;
        list-style: none;
        padding: 0;
        flex-flow: column wrap;
        -webkit-box-align: stretch;
        align-items: stretch;
        max-width: 100%;
        min-width: 168px;

        &_item {
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          border: 0;
          border-radius: var(--border-radius);
          background-color: var(--light-gray);
          color: var(--black);
          max-width: 100%;
          min-width: 75px;
          min-height: 40px;
          outline: 0;
          padding: 5px;
          transition-duration: 0.1s;
          transition-property: background-color, color, border-color, opacity, box-shadow;
          transition-timing-function: ease-out;
          cursor: pointer;
          opacity: 1;
          margin: 5px 0;
          
          &.-selected {
            background-color: #a6a6a6;
          }
        }
      }

      &__abc {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        position: relative;
        width: 24px;
        height: 24px;
        border-radius: 5px;
        font-size: 12px;
        background-color: var(--white);
        color: var(--black);
        margin-right: 5px;
      }

      &__word {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        flex: 1 1 0%;
        min-width: 75px;
        text-align: left;
        padding-right: 10px;
      }

      &__answer {
        padding-left: 40px;
        padding-right: 40px;
        text-align: left;
        padding-bottom: 40px;
        border-bottom: 1px solid var(--light-gray);
        margin-bottom: 40px;
        width: 100%;

        &:last-child {
          border: 0;
        }
        
        &.-submited {
          &.-error {
            color: #ff0000;

            & .-correct {
              background-color: #97df97;
            }

            & .-selected {
              background-color: #fc7c7c;
            }
          }
          &.-correct {
            color: #008000;

            & .-selected {
              background-color: #97df97;
            }
          }
        }
      }
      
      .simplebar {
        &-vertical {
          right: 3px;
        }
        
        &-content {
          &-wrapper {
            padding-right: 1rem;
          }
        }
      }
    }

    &-btns {
      display: flex;
      justify-content: space-between;
      padding-top: 1rem;
      padding-right: 1rem;
    }

    &-btn {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.5;
      border: 0;
      background: var(--black);
      color: var(--white);
      padding: 10px;
      border-radius: 0;
      border-right: 1px solid var(--light-blue);

      &__right {
        display: flex;
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
      }
    }

    &-result {
      &.-error {
        color: #ff0000;
      }

      &.-success {
        color: #008000;
      }
    }
  }

  @media (max-width: 40.0625em) {
    .exercise {
      padding: 1rem 0;
      
      &-container {
        flex-direction: column;
        max-height: calc(100vh - 6rem);
      }
      
      &-text {
        &__wrap {
          flex: 1;
          max-height: 50%;
          border-top-left-radius: var(--border-radius);
          border-top-right-radius: var(--border-radius);
          border-bottom-left-radius: 0;
        }
      }
      
      &-answers {
        &__wrap {
          flex: 1;
          max-height: 50%;
          border-top-right-radius: 0;
          border-bottom-right-radius: var(--border-radius);
          border-bottom-left-radius: var(--border-radius);
        }
        
        &__title {
          margin-bottom: 10px;
        }
      }
    }
  }
`;
