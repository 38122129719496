import React, { useState, useEffect, useCallback } from 'react';
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";

import {
  Card, CardContainer, CardFooter, CardList, CardListItem, CardListText, CardListValue, CardTitle, ConfigSelectorStyles,
  TaskContent, TaskExercises, TaskExercisesTitle,
  TaskInfo, TrueFalseContent,
} from "./Tasks.Styles";
import {Button, Loader, ModalCalendar, ScrollBar, Switch} from "../../elements";
import {Trans, useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getTasks, createTask, sendTaskResult, addShedulets} from "../../store/practice/actions";
import {AppStateType} from "../../store";
import {selectErrorByKey, selectLoadingByKey} from "../../store/loadingsErrors/selectors";
import types from "../../store/actionTypes";
import { UserReducerState } from "../../store/user/reducers";
import {GetTaskParams, CreateTaskParams, TaskResultParams, AddNotificationParams, TranslateParams} from "../../api";
import {PracticeOption, PracticeQuestions, PracticeReducerState, PracticeTask} from "../../store/practice/reducers";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
  BASE_EXERCISES_ALIAS,
  COMMON_TASK_DATA_BY_ID,
  EXERCISES_ALIAS,
  EXERCISES_DOMAINS,
  EXERCISES_DOMAINS_BY_ID, EXERCISES_DOMAINS_ID_BY_SLUG, EXERCISES_LEVELS, EXERCISES_SKILLS,
  TASK_ALIAS_BY_SLUG, TASK_ID_BY_SLUG
} from "../../const/exercises.constants";
import {ExpandMore} from "@mui/icons-material";
import {Input, InputLabel, MenuItem, FormControl, Collapse, FormControlLabel} from "@mui/material";
import {LEARNING_LANGUAGES_DOMAINS, LEARNING_LANGUAGES_EXERCISES} from "../../const/learning_languages.constants";
import {closeModal, openModal} from "../../store/app/actions";
import {Dayjs} from "dayjs";
import {PATHS} from "../../const/paths.constants";
import useBackgroundAudio from "../../hooks/useBackgroundAudio";
import {LANGUAGES} from "../../common/utils/i18n";
import API from "../../api/executor";

const LANGUAGE_LIST = Array.from(LANGUAGES.entries());
const LANGUAGES_TLANG_LIST = {}
LANGUAGE_LIST.forEach(l => {
  LANGUAGES_TLANG_LIST[l[0]] = l[1]
});
export interface Props {
  user: UserReducerState;
  practice: PracticeReducerState;
  loading: boolean;
  error: string | null;
  getTasks: (payload: GetTaskParams | any) => void;
  createTask: (payload: CreateTaskParams | any) => void;
  sendTaskResult: (payload: TaskResultParams | any) => void;
  openModal: (payload: any) => void;
  closeModal: () => void;
  addShedulets: (payload: AddNotificationParams) => void;
  exerciseSkill: EXERCISES_SKILLS;
}

const Preposition: React.FC<Props> = (props: Props) => {
  const { getTasks, createTask, sendTaskResult, user, practice, loading, closeModal, openModal, addShedulets, exerciseSkill } = props;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const locationPath = location.pathname.split('/')[1];
  const { task, exnumber } = useParams();
  const { audio, audioLink, setAudio, setAudioLink } = useBackgroundAudio();
  const [exercise, setExercise] = useState<PracticeTask | undefined>(undefined);
  const [submited, setSubmited] = useState<boolean>(false);
  const [counter, setCounter] = useState(0);
  const [taskConfig, setTaskConfig] = useState<boolean>(false);
  const [taskConfigDomain, setTaskConfigDomain] = useState<EXERCISES_DOMAINS | undefined>(undefined);
  const [taskConfigLevel] = useState<EXERCISES_LEVELS>(EXERCISES_LEVELS.A1);
  const [calendarData, setCalendarData] = useState<Dayjs | null>(null);
  const [translitOpen, setTranslitOpen] = useState<boolean[]>([false, false, false]);
  const [hasUser, setHasUser] = useState<boolean>(false);
  const [timerVisible, setTimerVisible] = useState<boolean>(false);
  const [textSimplification, setTextSimplification] = useState<boolean>(false);
  const [textSimplificationLoading, setTextSimplificationLoading] = useState<boolean>(false);
  const [simplificatedText, setSimplificatedText] = useState<string>('');

  const [textTranslation, setTextTranslation] = useState<boolean>(false);
  const [textTranslationLoading, setTextTranslationLoading] = useState<boolean>(false);
  const [translatedText, setTranslatedText] = useState<string>('');

  useEffect(() => {
    if (user.userData?.id) {
      if (!hasUser) {
        setHasUser(true);
        setTimerVisible(true);
      }
      if (practice.tasks.loadedType !== TASK_ID_BY_SLUG[`${task}`]) {
        getTasks({
          Task_Type: TASK_ID_BY_SLUG[`${task}`],
          UserId: user.userData?.id,
          languageId: user.learningLanguage
        });
      } else {
        if (!exercise && exnumber && practice?.tasks) {
          const newExercise = find(practice.tasks.list, { id: Number(exnumber) });
          setExercise(newExercise || undefined);
        }
      }
    } else {
      if (!practice.tasks.list.length) {
        navigate(PATHS.HOME);
      }

      if (!exercise && !!practice.tasks.list.length) {
        setExercise(practice.tasks.list[0]);
      }
    }

    if (user.learningLanguage !== practice.tasks.languageId && practice.tasks.loadedType === TASK_ID_BY_SLUG[`${task}`]) {

      if (!LEARNING_LANGUAGES_EXERCISES[user.learningLanguage].includes(TASK_ID_BY_SLUG[`${task}`])) {
        navigate(locationPath);
      } else {
        getTasks({
          Task_Type: TASK_ID_BY_SLUG[`${task}`],
          UserId: user.userData?.id,
          languageId: user.learningLanguage
        });

        setExercise(undefined);
        setTaskConfigDomain(LEARNING_LANGUAGES_DOMAINS[exerciseSkill][user.learningLanguage][0]);
        navigate(`/${locationPath}/${TASK_ID_BY_SLUG[task || '']}`);
      }
    }
  }, [practice, exnumber, exercise, user, getTasks, setExercise, setTaskConfigDomain, navigate, task, locationPath, hasUser]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (exercise && timerVisible) {
      timer = setInterval(() => setCounter((counter) => counter + 1), 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [exercise, timerVisible, counter, setCounter]);

  useEffect(() => {
    setTranslatedText('');
    setTextTranslation(false);
  }, [i18n.language]);

  const onCreateNewTask = () => {
    createTask({
      topicId: EXERCISES_DOMAINS_ID_BY_SLUG[taskConfigDomain || ''],
      task_type: TASK_ID_BY_SLUG[`${task}`],
      UserId: user.userData?.id,
      level: taskConfigLevel,
      language: user.learningLanguage
    });
    setTaskConfig(false);
  };

  const onSetExercise = (exercise: PracticeTask | null) => {
    if (exercise) {
      setExercise(exercise);
      setCounter(0);
      navigate(`/${locationPath}/${EXERCISES_ALIAS[COMMON_TASK_DATA_BY_ID[exercise.taskId]]}/${exercise.id}`);
    } else {
      setExercise(undefined);
      navigate(`/${locationPath}/${task}`);
    }

  };

  const indexToAlpha = (num: number = 1) => {
    const A = 'A'.charCodeAt(0);
    let numberToCharacter = (number: number) => {
      return String.fromCharCode(A + number);
    };
    return numberToCharacter(num);
  };

  const onChange = (qustInd: number, optionInd: number) => {
    if (submited) return;
    const newExercise: PracticeTask | undefined = cloneDeep(exercise);

    if (newExercise) {
      newExercise.questions[qustInd].options = newExercise.questions[qustInd].options.map((opt: PracticeOption) => {
        opt.selected = false;
        return opt;
      });

      newExercise.questions[qustInd].options[optionInd].selected = true;
    }

    setExercise(newExercise);
  };

  const onSubmit = () => {
    if (submited) return;
    const newExercise: PracticeTask | undefined = cloneDeep(exercise);
    let currectAnswers: number = 0;

    if (newExercise) {
      newExercise.questions = newExercise.questions.map((quest: PracticeQuestions) => {
        let current: boolean = false;

        quest.options.forEach((opt: PracticeOption) => {
          if (opt.current && opt.selected) {
            currectAnswers += 1;
            current = true;
          }
        });

        quest.error = !current;

        return quest;
      });

      newExercise.completed = true;
      newExercise.trueResult = currectAnswers;
      newExercise.falseResult = newExercise.questions.length - currectAnswers;
      console.log('newExercise', newExercise);
    }

    setExercise(newExercise);
    setSubmited(true);
    setTimerVisible(false);
    if (hasUser) {
      sendTaskResult({
        completed: newExercise?.completed,
        trueResult: newExercise?.trueResult,
        falseResult: newExercise?.falseResult,
        factTimeSec: counter,
        taskId: newExercise?.id,
        studentId: user.userData?.id,
        languageId: user.learningLanguage
      });
      setCounter(0);
    }
  };

  const onComplete = () => {
    setSubmited(false);
    setTimerVisible(false);
    onSetExercise(null);
    setTextSimplification(false);
    setSimplificatedText('');
    setTranslatedText('');
    setTextTranslation(false);
  };

  const modalDashboard = () => (
    <div className="modal-content">
      <p className="modal-text">
        <Trans>{t('modal.dashboard.description')}</Trans>
      </p>
      <div className="modal-btn__wrap">
        <Button
          className="modal-btn"
          type="button"
          onClick={() => close()}
        >
          {t('modal.dashboard.btn')}
        </Button>
      </div>
    </div>
  );

  const close = () => {
    closeModal();
    navigate(PATHS.DASHBOARD);
  }

  const modalInstruction = () => (
    <div className="modal-content">
      <h4 className="modal-title">{t('modal.instruction.title')}</h4>
      <p className="modal-text">{t(`tasks.tasks.practice.items.${BASE_EXERCISES_ALIAS[TASK_ALIAS_BY_SLUG[task || '']]}.instruction`)}</p>
    </div>
  );

  const showInstruction = () => {
    openModal({
      closeModal: closeModal,
      className: "modal instruction",
      hasCloseBtn: true,
      content: modalInstruction
    })
  }

  const menuProps = {
    disablePortal: true,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getcontentanchorel: null,
  };

  const onSetCalendarDate = (timestamp: Dayjs | null) => {
    setCalendarData(timestamp);
    const addSheduletsParams: AddNotificationParams = {
      UserId: user.userData?.id || 0,
      Language_id: user.learningLanguage,
      TaskId: exercise?.id || 0,
      EventDate: timestamp?.format('YYYY-MM-DDTHH:mm:ss') || '',
      IsNotification: true
    }
    addShedulets(addSheduletsParams);
  };

  const playFileLink = useCallback(
    (e: React.ChangeEvent<any>, file: string) => {
      e.preventDefault();
      e.stopPropagation();
      if (audio) {
        audio.pause();
      }
      const newAudio: HTMLAudioElement = new Audio(file);
      newAudio.play();
      setAudio(newAudio);
      setAudioLink(file);
    }, [audio]
  );

  const stopFileLink = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      e.stopPropagation();
      audio?.pause();
      setAudio(null);
      setAudioLink(null);
    }, [audio]
  );

  const stopTimer = () => {
    setTimerVisible(false);
  };

  const toggleTextSimplification = useCallback(async () => {
    setTextTranslation(false);
    if (!simplificatedText) {
      setTextSimplificationLoading(true);
      const translationData: TranslateParams = {
        Id: exercise?.id || 0,
        task_text: exercise?.text || '',
        task_title: exercise?.title || '',
      };
      const getSimplificate = (payload: TranslateParams) => API.call('getSimplificate', payload);

      await getSimplificate(translationData).then((answer: any) => {
        setSimplificatedText(answer.task_text_simplificate);
        setTextSimplification(!textSimplification);
      }).catch((error: any) => {
        console.log('error', error);
      }).finally(() => {
        setTextSimplificationLoading(false);
      });
    } else {
      setTextSimplification(!textSimplification);
    }
  }, [exercise?.id, exercise?.text, exercise?.title, simplificatedText, textSimplification]);


  const toggleTextTranslation = useCallback(async () => {
    setTextSimplification(false);
    if (!translatedText) {
      setTextTranslationLoading(true);
      const translationData: TranslateParams = {
        Id: exercise?.id || 0,
        task_text: exercise?.text || '',
        task_title: exercise?.title || '',
        TLang: LANGUAGES_TLANG_LIST[i18n.language],
      };
      const getTranslate = (payload: TranslateParams) => API.call('getTranslate', payload);

      await getTranslate(translationData).then((answer: any) => {
        setTranslatedText(answer.task_text_translate);
        setTextTranslation(!textTranslation);
      }).catch((error: any) => {
        console.log('error', error);
      }).finally(() => {
        setTextTranslationLoading(false);
      });
    } else {
      setTextTranslation(!textTranslation);
    }
  }, [exercise?.id, exercise?.text, exercise?.title, i18n.language, textTranslation, translatedText]);

  return (
    <TaskContent className="task">
      {
        !exercise && (
          <>
            <TaskInfo>
              <p>{t(`tasks.tasks.practice.items.${BASE_EXERCISES_ALIAS[TASK_ALIAS_BY_SLUG[task || '']]}.instruction`)}</p>
            </TaskInfo>
          </>

        )
      }

      {
        !exercise && (
          <TaskExercises>
            <TaskExercisesTitle as="h2">{t('exercises.common.generated_tasks')}</TaskExercisesTitle>
            <div className="task-container">
              <CardContainer>
                <div className="grid-x grid-margin-x task-grid">
                  {
                    taskConfig ? (
                      <Card
                        className={`cell small-12 xlarge-4 task-item -config`}
                      >
                        <FormControl fullWidth>

                          <InputLabel className="task-item__label" id="domain-label">{t('exercises.common.topic')}</InputLabel>

                          <ConfigSelectorStyles
                            className="task-item__selector"
                            name="domain"
                            IconComponent={ExpandMore}
                            MenuProps={menuProps}
                            value={taskConfigDomain}
                            onChange={(event: React.ChangeEvent<{ name?: string; value: EXERCISES_DOMAINS }>) => setTaskConfigDomain(event.target.value)}
                            input={<Input disableUnderline />}
                          >
                            {LEARNING_LANGUAGES_DOMAINS[exerciseSkill][user.learningLanguage].map((d: string) => (
                              <MenuItem value={d} key={d}>
                                {t(`tasks.tasks.domain.items.${d}.title`)}
                              </MenuItem>
                            ))}
                          </ConfigSelectorStyles>
                        </FormControl>

                        <Button
                          className="task-item__btn"
                          onClick={() => onCreateNewTask()}
                          disabled={!taskConfigDomain}
                        >
                          {t('exercises.common.add')}
                        </Button>
                      </Card>
                    ) : (
                      <Card
                        className={`cell small-12 xlarge-4 task-item -add ${loading ? '-loading' : ''}`}
                      >
                        {
                          loading ? (
                            <Loader />
                          ) : (
                            <Button
                              className="task-item__btn md"
                              onClick={() => setTaskConfig(true)}
                            >
                              <div className="plus"/>
                              <span className="plus-text">{t('exercises.common.add')}</span>
                            </Button>
                          )
                        }
                      </Card>
                    )
                  }
                  {practice.tasks.list.length ? practice.tasks.list.map((practiceTask: PracticeTask, index: number) => (
                    <Card
                      className={`cell small-12 xlarge-4 task-item ${practiceTask.completed ? '-completed' : ''}`}
                      key={`generated-task-${index + 1}`}
                      onClick={() => onSetExercise(practiceTask)}
                    >
                      <CardTitle className={`${practiceTask.completed ? '-completed' : ''}`}>{practiceTask.title}</CardTitle>
                      <CardList className={`${practiceTask.completed ? '-completed' : ''}`}>
                        {
                          !!practiceTask.fileLink ? (
                            <CardListItem>
                              <CardListText>{t(`tasks.tasks.card.speaker`)}</CardListText>
                              <CardListValue>{practiceTask.author}</CardListValue>
                            </CardListItem>
                          ) : null
                        }
                        <CardListItem>
                          <CardListText>{t('exercises.common.topic')}</CardListText>
                          <CardListValue>{t(`tasks.tasks.domain.items.${EXERCISES_DOMAINS[EXERCISES_DOMAINS_BY_ID[practiceTask.topicId]]}.title`)}</CardListValue>
                        </CardListItem>
                        {
                          !!practiceTask.fileLink ? (
                            <CardListItem>
                              <CardListText>{t(`exercises.common.original`)}</CardListText>
                              <CardListValue>
                                {
                                  audio && audio.src && audioLink === practiceTask.fileLink ? (
                                    <button className="speaker" onClick={(e) => stopFileLink(e)}>
                                      <img alt="speacker" src="/img/pause_icon.svg"/>
                                    </button>
                                  ) : (
                                    <button className="speaker" onClick={(e) => playFileLink(e, practiceTask.fileLink)}>
                                      <img alt="speacker" src="/img/speaker_icon.svg"/>
                                    </button>
                                  )
                                }

                              </CardListValue>
                            </CardListItem>
                          ) : null
                        }

                        {
                          !!practiceTask.levelZero?.filelink ? (
                            <CardListItem>
                              <CardListText>{t(`exercises.common.resound`)}</CardListText>
                              <CardListValue>
                                {
                                  audio && audio.src && audioLink === practiceTask.levelZero.filelink ? (
                                    <button className="speaker" onClick={(e) => stopFileLink(e)}>
                                      <img alt="speacker" src="/img/pause_icon.svg"/>
                                    </button>
                                  ) : (
                                    // @ts-ignore
                                    <button className="speaker" onClick={(e) => playFileLink(e, practiceTask.levelZero.filelink)}>
                                      <img alt="speacker" src="/img/speaker_icon.svg"/>
                                    </button>
                                  )
                                }

                              </CardListValue>
                            </CardListItem>
                          ) : null
                        }

                        {
                          !!practiceTask.levelB?.filelink ? (
                            <CardListItem>
                              <CardListText>{t(`exercises.common.resoundB`)}</CardListText>
                              <CardListValue>
                                {
                                  audio && audio.src && audioLink === practiceTask.levelB?.filelink ? (
                                    <button className="speaker" onClick={(e) => stopFileLink(e)}>
                                      <img alt="speacker" src="/img/pause_icon.svg"/>
                                    </button>
                                  ) : (
                                    // @ts-ignore
                                    <button className="speaker" onClick={(e) => playFileLink(e, practiceTask.levelB.filelink)}>
                                      <img alt="speacker" src="/img/speaker_icon.svg"/>
                                    </button>
                                  )
                                }

                              </CardListValue>
                            </CardListItem>
                          ) : null
                        }

                        {
                          !!practiceTask.source ? (
                            <CardListItem>
                              <CardListText>{t(`exercises.common.source`)}</CardListText>
                              <CardListValue>
                                <a href={practiceTask.source} target="_blank" >
                                  {t(`exercises.common.link`)}
                                </a>
                              </CardListValue>
                            </CardListItem>
                          ) : null
                        }

                        {
                          practiceTask.completed ? (
                            <>
                              <CardListItem>
                                <CardListText>{t('exercises.common.successfully')}</CardListText>
                                <CardListValue>{practiceTask.trueResult}/{(practiceTask?.trueResult || 0) + (practiceTask.falseResult || 0)}</CardListValue>
                              </CardListItem>
                              <CardListItem>
                                <CardListText>{t('exercises.common.spentTime')}</CardListText>
                                <CardListValue>{`${((practiceTask?.factTimeSec || 0) > 60 ? ((practiceTask?.factTimeSec || 0) / 60) > 60 ? (practiceTask?.factTimeSec || 0) / 3600 : (practiceTask?.factTimeSec || 0) / 60 : (practiceTask.factTimeSec || 0)).toFixed(2).replace(/\.?0+$/, '')} ${(practiceTask.factTimeSec || 0) > 60 ? ((practiceTask.factTimeSec || 0) / 60) > 60 ? 'h' : 'min' : 'sec'}`}</CardListValue>
                              </CardListItem>
                            </>
                          ) : null
                        }
                      </CardList>
                      <CardFooter className={`${practiceTask.completed ? '-completed' : ''}`}>
                        <Button
                          className="task-item__btn md"
                        >
                          {practiceTask.completed ? t('common.remake') : t('common.perform')}
                        </Button>
                      </CardFooter>
                    </Card>
                  )) : null}
                </div>
              </CardContainer>
            </div>
          </TaskExercises>
        )
      }

      {
        exercise && (
          <TrueFalseContent className="exercise">
            <div className="exercise-text__container">
              {
                exerciseSkill === EXERCISES_SKILLS.READING ? (
                  <div className="exercise-panel">
                    <div className={`exercise-panel__switcher ${textTranslation ? '-active' : ''} ${textTranslationLoading || textSimplificationLoading ? '-disabled' : ''}`}>
                      <FormControlLabel
                        className="exercise-panel__switch"
                        control={
                          <Switch
                            className=""
                            checked={textTranslation}
                            onChange={toggleTextTranslation}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label={t('exercises.common.translate')}
                      />
                    </div>
                    <div className={`exercise-panel__switcher ${textSimplification ? '-active' : ''} ${textTranslationLoading || textSimplificationLoading ? '-disabled' : ''}`}>
                      <FormControlLabel
                        className="exercise-panel__switch"
                        control={
                          <Switch
                            className=""
                            checked={textSimplification}
                            onChange={toggleTextSimplification}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label={t('exercises.common.simplificate')}
                      />
                    </div>
                  </div>
                ) : null
              }
              <div className={`exercise-text__wrap ${exerciseSkill === EXERCISES_SKILLS.READING ? '-withpanel' : ''}`}>
                <div className="exercise-text__inner">
                  <ScrollBar className="exercise-scrollbar">
                    <div className="exercise-text">
                      <p
                        className="h2"
                        dangerouslySetInnerHTML={{__html: exercise.title}}
                      />
                      {
                        !!exercise.fileLink ? (
                          <>
                            <div className="exercise-audio">
                              <span
                                className="exercise-audio__title">{t(`langs.${i18n.language}`)}({t('exercises.common.original')})</span>
                              <audio controls src={exercise.fileLink}/>
                            </div>
                            <div
                              onClick={() => setTranslitOpen([!translitOpen[0], false, false])}
                              className="exercise-audio__expand"
                            >
                              <ExpandMore className={`exercise-audio__arrow ${translitOpen[0] ? '-open' : ''}`}/>
                              <span
                                className="exercise-audio__translit"
                              >{t('exercises.common.translit')}</span>
                            </div>
                            <Collapse
                              className="exercise-audio__collapse"
                              in={translitOpen[0]}
                              timeout="auto"
                            >
                              <>
                                <div
                                  className="text"
                                  dangerouslySetInnerHTML={{__html: exercise.text}}
                                />
                                <div className="text -source">
                                  <span>
                                    {t('exercises.common.source')}:
                                    {' '}
                                    <a
                                      href={exercise.source}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {exercise.source}
                                    </a>
                                  </span>
                                </div>
                              </>
                            </Collapse>
                            {
                              exercise.levelZero ? (
                                <>
                                  <div className="exercise-audio">
                                    <span
                                      className="exercise-audio__title">{t(`langs.${i18n.language}`)}({t('exercises.common.resound')})</span>
                                    <audio controls src={exercise.levelZero.filelink}/>
                                  </div>
                                  <div
                                    onClick={() => setTranslitOpen([false, !translitOpen[1], false])}
                                    className="exercise-audio__expand"
                                  >
                                    <ExpandMore className={`exercise-audio__arrow ${translitOpen[1] ? '-open' : ''}`}/>
                                    <span
                                      className="exercise-audio__translit"
                                    >{t('exercises.common.translit')}</span>
                                  </div>
                                  <Collapse
                                    className="exercise-audio__collapse"
                                    in={translitOpen[1]}
                                    timeout="auto"
                                  >
                                    <>
                                      <div
                                        className="text"
                                        dangerouslySetInnerHTML={{__html: exercise.levelZero.text}}
                                      />
                                    </>
                                  </Collapse>
                                </>
                              ) : null
                            }

                            {
                              exercise.levelB ? (
                                <>
                                  <div className="exercise-audio">
                                    <span
                                      className="exercise-audio__title">{t(`langs.${i18n.language}`)}({t('exercises.common.resoundB')})</span>
                                    <audio controls src={exercise.levelB.filelink}/>
                                  </div>
                                  <div
                                    onClick={() => setTranslitOpen([false, false, !translitOpen[2]])}
                                    className="exercise-audio__expand"
                                  >
                                    <ExpandMore className={`exercise-audio__arrow ${translitOpen[2] ? '-open' : ''}`}/>
                                    <span
                                      className="exercise-audio__translit"
                                    >{t('exercises.common.translit')}</span>
                                  </div>
                                  <Collapse
                                    className="exercise-audio__collapse"
                                    in={translitOpen[2]}
                                    timeout="auto"
                                  >
                                    <>
                                      <div
                                        className="text"
                                        dangerouslySetInnerHTML={{__html: exercise.levelB.text}}
                                      />
                                    </>
                                  </Collapse>
                                </>
                              ) : null
                            }
                          </>
                        ) : (
                          <>
                            <div
                              className="text"
                              dangerouslySetInnerHTML={{__html: textSimplification ? simplificatedText : textTranslation ? translatedText : exercise.text}}
                            />
                            <div className="text -source">
                              <span>
                                {t('exercises.common.source')}:
                                {' '}
                                <a
                                  href={exercise.source}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {exercise.source}
                                </a>
                              </span>
                            </div>
                          </>
                        )
                      }
                    </div>
                  </ScrollBar>
                </div>
              </div>
            </div>
            <div className="exercise-answers__wrap">
              {
                timerVisible ? (
                  <div className="exercise-answers__close" onClick={() => stopTimer()}>
                    <svg xmlSpace="preserve" viewBox="0 0 100 100" y="0" x="0" xmlns="http://www.w3.org/2000/svg" id="Layer_1" version="1.1" width="200px" height="200px">
                      <g className="ldl-scale">
                        <g className="ldl-ani">
                          <g className="ldl-layer">
                            <g className="ldl-ani ldl-ani__one">
                              <path
                                fill="#b22222"
                                d="M88.7 71.8c-3.6 5.6-8.4 10.3-14 13.7-5.6 3.4-11.9 5.4-18.2 5.9l-2.4.1h-2.4c-1.6 0-3.2-.2-4.7-.4-3.1-.4-6.2-1.3-9.1-2.4-5.8-2.3-11-5.9-15.1-10.4 4.5 4.1 9.9 7.1 15.7 8.7 2.9.8 5.8 1.3 8.8 1.4 1.5.1 2.9.1 4.4-.1.7-.1 1.5-.1 2.2-.2l2.2-.3c5.7-1.1 11.2-3.4 15.7-6.8 4.6-3.4 8.3-7.9 10.8-12.8.9-1.7 3-2.4 4.8-1.5 1.7.9 2.4 3 1.5 4.8-.1 0-.1.1-.2.3z"
                              />
                            </g>
                          </g>
                          <g className="ldl-layer">
                            <g className="ldl-ani ldl-ani__two">
                              <path
                                fill="#b22222"
                                d="M50.7 6.5c-2 .1-3.5 1.8-3.4 3.7.1 2 1.8 3.5 3.7 3.4 5.6-.3 11.2.7 16.5 3 1.3.5 2.6 1.2 3.8 1.9.6.3 1.2.7 1.8 1.1l1.8 1.2 1.7 1.4c.5.5 1.2.9 1.6 1.4 1 1 2.1 2 3 3.2 3.8 4.4 6.6 9.8 8.1 15.6l.4 2.2c.1.7.3 1.5.4 2.2.1 1.5.4 3 .4 4.5 0 3-.3 6.1-.9 9 .9-2.9 1.5-5.9 1.8-9 .2-1.5.1-3.1.1-4.6 0-.8-.1-1.5-.2-2.3l-.2-2.4c-.9-6.2-3.4-12.2-7-17.4-.8-1.4-1.9-2.5-2.9-3.8-.5-.6-1.1-1.2-1.7-1.7l-1.7-1.7-1.9-1.5c-.6-.5-1.2-1-1.9-1.5-1.3-.9-2.7-1.9-4.1-2.6-5.7-3.1-12.2-5-18.8-5.3h-.4z"
                              />
                            </g>
                          </g>
                          <g className="ldl-layer">
                            <g className="ldl-ani ldl-ani__three">
                              <path
                                fill="#b22222"
                                d="M32 14.4c-2.8 1.4-5.5 2.9-7.9 4.9l-.9.7-.9.8c-.6.5-1.2 1-1.7 1.6-1.1 1.2-2.2 2.3-3.1 3.5-1 1.2-1.9 2.5-2.7 3.9l-1.2 2.1L13 33c-.2.4-.3.7-.5 1.1l-1 2.2-.8 2.3c-.3.8-.5 1.5-.7 2.3-.2.8-.4 1.6-.6 2.3-.3 1.6-.6 3.2-.7 4.8-.2 1.6-.3 3.2-.2 4.9.1 6.5 1.8 13.1 4.9 19 .1.1.1.2.2.3 1.1 1.6 3.3 2.1 4.9 1 1.6-1.1 2.1-3.3 1-4.9-3-4.7-5-10.1-5.7-15.7-.2-1.4-.2-2.8-.2-4.3-.1-1.4.1-2.9.2-4.3l.3-2.1c.1-.7.2-1.4.4-2.1l.5-2.1.7-2.1c.1-.3.2-.7.4-1l.4-1c.3-.7.6-1.4.9-2 .7-1.3 1.3-2.6 2.2-3.8.8-1.3 1.7-2.4 2.6-3.6.5-.6 1-1.1 1.5-1.7l.7-.8.8-.8c2.1-2.1 4.6-3.9 7.1-5.5 2.6-1.6 5.4-2.8 8.3-3.8-2.9.5-5.8 1.4-8.6 2.8z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <span className="exercise-answers__close_time">{new Date(counter * 1000).toISOString().slice(14, 19)}</span>
                  </div>
                ) : null
              }
              <div className="exercise-answers__inner">
                <div className="exercise-answers">
                  <ScrollBar className="tasks-scrollbar">
                    {
                      exercise?.questions.length ? exercise?.questions.map((quest: PracticeQuestions, index: number) => (
                        <div
                          key={`questions-${index}`}
                          className={`exercise-answers__answer ${submited ? '-submited' : ''} ${quest.error ? '-error' : '-correct'}`}
                        >
                          <div className="exercise-answers__title">
                            <span>{quest.title}</span>
                          </div>
                          <div className={`exercise-answers__list `}>
                            {
                              quest.options.map((option: PracticeOption, ind: number) => (
                                <button
                                  key={`${index + 1}-${ind + 1}`}
                                  className={`exercise-answers__list_item ${option.current ? '-correct' : ''} ${option.selected ? '-selected' : ''}`}
                                  onClick={() => onChange(index, ind)}
                                >
                                  <div className="exercise-answers__abc">
                                    <span>{indexToAlpha(ind)}</span>
                                  </div>
                                  <div className="exercise-answers__word">
                                    <span>{option.name}</span>
                                  </div>
                                </button>
                              ))
                            }
                          </div>
                        </div>
                      )) : null
                    }
                  </ScrollBar>
                </div>
              </div>
              <div className="exercise-btns">
                {
                  submited ? (
                    <>
                      <div className="exercise-btn__left">
                        <div className={`exercise-result ${(exercise?.trueResult || 0) <= (exercise?.questions?.length || 0) / 2 ? '-error' : '-success'}`}>
                          {t('exercises.true.texts.result')} {exercise?.trueResult || 0}/{exercise?.questions?.length || 0}
                        </div>
                      </div>
                      {
                        hasUser ? (
                          <div className="exercise-btn__right">
                            <Button
                              className="exercise-btn"
                              onClick={() => onComplete()}
                            >
                              {t('common.complete')}
                            </Button>
                          </div>
                        ) : (
                          <div className="exercise-btn__right">
                            <Button
                              className="exercise-btn"
                              type="button"
                              onClick={
                                () => openModal({
                                  closeModal: closeModal,
                                  className: "modal dashboard",
                                  hasCloseBtn: true,
                                  content: modalDashboard
                                })
                              }
                            >
                              {t('header.dashboard')}
                            </Button>
                          </div>
                        )
                      }
                    </>
                  ) : (
                    <>
                      <div className="exercise-btn__left">
                        <Button
                          className="exercise-btn"
                          onClick={() => onSubmit()}
                        >
                          {t('common.ok')}
                        </Button>
                      </div>
                      <div className="exercise-btn__right">
                        <ModalCalendar
                          data={calendarData}
                          setData={onSetCalendarDate}
                          shedulets={[]}
                        />
                        <Button
                          className="exercise-btn"
                          onClick={showInstruction}
                        >
                          {t('common.instruction')}
                        </Button>
                        <Button
                          className="exercise-btn"
                          disabled
                        >
                          {t('common.answer')}
                        </Button>
                        <Button
                          className="exercise-btn"
                          disabled
                        >
                          {t('common.hint')}
                        </Button>
                      </div>
                    </>
                  )
                }
              </div>
            </div>
          </TrueFalseContent>
        )
      }
    </TaskContent>
  );
};

const mapState = (state: AppStateType) => {
  const { user, practice } = state;
  return {
    user,
    practice,
    loading: selectLoadingByKey(state, types.CREATE_TASK_REQUEST),
    error: selectErrorByKey(state, types.CREATE_TASK_REQUEST),
  };
};

export default connect(mapState, { getTasks, createTask, sendTaskResult, closeModal, openModal, addShedulets })(Preposition);
