import {makeHandler} from "./makeHandler";

export const getStatistics = makeHandler(
  'getStatistics',
  (data: StatisticsParams) => ({
    method: 'GET',
    path: `/api/Result/GetResults?StudentId=${data.StudentId}&languageid=${data.languageId}`,
  }),
  (res: StatisticsResponse) => res.data
);

export const updateUser = makeHandler(
  'updateUser',
  (data: UserParams) => ({
    method: 'POST',
    path: '/api/Login/UpdateUserProfile',
    data,
  }),
  (res: StatisticsResponse) => res.data
);

export type UserData = {
  /** Id */
  id?: number;
  levelId?: number;
  /** E-mail */
  email?: string;
  /** Номер мобильного телефона */
  phone?: string;
  /** Имя */
  first_name?: string;
  /** Фамилия */
  last_name?: string;
  /** Аватарка */
  photo?: string;
  rating?: string;
  skills?: string;
  teacherId?: number;
  description?: string;
  Message?: string;
  isAdmin?: boolean
};

export type StatisticsParams = {
  StudentId: number;
  languageId: number;
};

export type StatisticsListTask = {
  TrueResult: number,
  FalseResult: number,
  FactTimeSec: number,
  TaskType: number,
  CompletedTasks: number,
  GeneratedTasks: number
};

export type StatisticsResponse = {
  data: StatisticsListTask[]
};

export type UserParams = {
  Id: number;
  Phone?: string;
  Rating?: string;
  Skills?: string;
  TeacherId?: number,
  LevelId?: number,
  FirstName?: string;
  LastName?: string;
};

