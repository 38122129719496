import { all, call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import API from '../../api/executor';
import {
  ALERT_TYPES,
} from '../../const/app.constants';
import { setAlert, setLoading } from '../loadingsErrors/actions';
import {
  AddSheduletsRequest,
  CreateTaskRequest,
  GetSheduletsRequest,
  GetTaskRequest,
  RemoveSheduletsRequest,
  TaskResultRequest,
  UpdateSheduletsRequest,
} from './actionTypes';
import types from '../actionTypes';
import {fetchStatistics} from "../user/actions";

// calls
// @ts-ignore
const createTaskCall = (payload?: any) => API.call('createTask', payload);
const fetchTasksCall = (payload?: any) => API.call('fetchTasks', payload);
const taskResultCall = (payload?: any) => API.call('sendTaskResult', payload);
const updateTaskResultCall = (payload?: any) => API.call('updateTaskResult', payload);
const getSheduletsCall = (payload?: any) => API.call('schedulerGetShedulets', payload);
const addSheduletsCall = (payload?: any) => API.call('schedulerAddNotification', payload);
const updateSheduletsCall = (payload?: any) => API.call('schedulerUpdateNotification', payload);
const removeSheduletsCall = (payload?: any) => API.call('schedulerRemoverNotification', payload);

// call types
type GetTasks = SagaReturnType<typeof fetchTasksCall>;
type CreateTask = SagaReturnType<typeof createTaskCall>;
type GetShedulets = SagaReturnType<typeof getSheduletsCall>;
type AddShedulets = SagaReturnType<typeof addSheduletsCall>;
type UpdateShedulets = SagaReturnType<typeof updateSheduletsCall>;
type RemoveShedulets = SagaReturnType<typeof removeSheduletsCall>;

function* fetchTask({ payload }: GetTaskRequest) {
  yield put(setLoading(types.GET_TASK_REQUEST, true));
  try {
    const res: GetTasks = yield call(() => fetchTasksCall(payload));
    yield all([
      put({ type: types.GET_TASK_SUCCESS, payload: res }),
      put(setLoading(types.GET_TASK_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : null;
    yield all([
      put(setLoading(types.GET_TASK_REQUEST, false)),
      put(setAlert(error ? `error.${error}` : 'error.network.404', ALERT_TYPES.DANGER)),
      put({ type: types.GET_TASK_FAILURE }),
    ]);
  }
}

function* createTask({ payload }: CreateTaskRequest) {
  yield put(setLoading(types.CREATE_TASK_REQUEST, true));
  try {
    const res: CreateTask = yield call(() => createTaskCall(payload));

    yield all([
      put({ type: types.CREATE_TASK_SUCCESS, payload: res }),
      put(setLoading(types.CREATE_TASK_REQUEST, false)),
    ]);
  } catch (e: any) {
    if (e.errObj) {
      const error = e?.errObj ? e.errObj : null;

      yield all([
        put(setLoading(types.CREATE_TASK_REQUEST, false)),
        put(setAlert(error ? `error.${error}` : 'error.network.404', ALERT_TYPES.DANGER)),
        put({
          type: types.CREATE_TASK_FAILURE,
          payload: error,
        }),
      ]);
    }
  }
}

function* sendTaskResult({ payload }: TaskResultRequest) {
  const studentId = payload.studentId;
  const languageId = payload.languageId;
  const completed = payload.completed;
  yield put(setLoading(types.TASK_RESULT_REQUEST, true));
  try {
    if (completed) {
      yield call(() => updateTaskResultCall(payload));
    } else {
      yield call(() => taskResultCall(payload));
    }

    yield all([
      put(setLoading(types.TASK_RESULT_REQUEST, false)),
      put(fetchStatistics({ StudentId: studentId, languageId: languageId || 1 })),
      put({ type: types.TASK_RESULT_SUCCESS, payload: payload }),
    ]);
  } catch (e: any) {
    if (e.errObj) {
      const error = e?.errObj ? e.errObj : null;

      yield all([
        put(setLoading(types.TASK_RESULT_REQUEST, false)),
        put(setAlert(error ? `error.${error}` : 'error.network.404', ALERT_TYPES.DANGER)),
      ]);
    }
  }
}

function* getShedulets({ payload }: GetSheduletsRequest) {
  yield put(setLoading(types.GET_SHEDULETS_REQUEST, true));
  try {
    const res: GetShedulets = yield call(() => getSheduletsCall(payload));

    yield all([
      put(setLoading(types.GET_SHEDULETS_REQUEST, false)),
      put({ type: types.GET_SHEDULETS_SUCCESS, payload: res }),
    ]);
  } catch (e: any) {
    if (e.errObj) {
      const error = e?.errObj ? e.errObj : null;

      yield all([
        put(setLoading(types.GET_SHEDULETS_REQUEST, false)),
        put(setAlert(error ? `error.${error}` : 'error.network.404', ALERT_TYPES.DANGER)),
      ]);
    }
  }
}

function* addShedulets({ payload }: AddSheduletsRequest) {
  yield put(setLoading(types.ADD_SHEDULETS_REQUEST, true));
  try {
    const res: AddShedulets = yield call(() => addSheduletsCall(payload));

    yield all([
      put(setLoading(types.ADD_SHEDULETS_REQUEST, false)),
      put({ type: types.ADD_SHEDULETS_SUCCESS, payload: res }),
    ]);
  } catch (e: any) {
    if (e.errObj) {
      const error = e?.errObj ? e.errObj : null;

      yield all([
        put(setLoading(types.ADD_SHEDULETS_REQUEST, false)),
        put(setAlert(error ? `error.${error}` : 'error.network.404', ALERT_TYPES.DANGER)),
      ]);
    }
  }
}

function* updateShedulets({ payload }: UpdateSheduletsRequest) {
  yield put(setLoading(types.UPDATE_SHEDULETS_REQUEST, true));
  try {
    const res: UpdateShedulets = yield call(() => updateSheduletsCall(payload));

    yield all([
      put(setLoading(types.UPDATE_SHEDULETS_REQUEST, false)),
      put({ type: types.UPDATE_SHEDULETS_SUCCESS, payload: res }),
    ]);
  } catch (e: any) {
    if (e.errObj) {
      const error = e?.errObj ? e.errObj : null;

      yield all([
        put(setLoading(types.UPDATE_SHEDULETS_REQUEST, false)),
        put(setAlert(error ? `error.${error}` : 'error.network.404', ALERT_TYPES.DANGER)),
      ]);
    }
  }
}

function* removeShedulets({ payload }: RemoveSheduletsRequest) {
  yield put(setLoading(types.REMOVE_SHEDULETS_REQUEST, true));
  try {
    const res: RemoveShedulets = yield call(() => updateSheduletsCall(payload));

    yield all([
      put(setLoading(types.REMOVE_SHEDULETS_REQUEST, false)),
      put({ type: types.REMOVE_SHEDULETS_SUCCESS, payload: res.Id }),
    ]);
  } catch (e: any) {
    if (e.errObj) {
      const error = e?.errObj ? e.errObj : null;

      yield all([
        put(setLoading(types.REMOVE_SHEDULETS_REQUEST, false)),
        put(setAlert(error ? `error.${error}` : 'error.network.404', ALERT_TYPES.DANGER)),
      ]);
    }
  }
}

export default function* practiceSagas() {
  yield takeLatest(types.GET_TASK_REQUEST, fetchTask);
  yield takeLatest(types.CREATE_TASK_REQUEST, createTask);
  yield takeLatest(types.TASK_RESULT_REQUEST, sendTaskResult);
  yield takeLatest(types.GET_SHEDULETS_REQUEST, getShedulets);
  yield takeLatest(types.ADD_SHEDULETS_REQUEST, addShedulets);
  yield takeLatest(types.UPDATE_SHEDULETS_REQUEST, updateShedulets);
  yield takeLatest(types.REMOVE_SHEDULETS_REQUEST, removeShedulets);
}
