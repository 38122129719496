import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  LearningLanguagesWrapper,
} from './LearningLanguagesComponent.Styles';
import {LearningLanguagesInterface} from "../../const/learning_languages.constants";

interface Props {
  item: LearningLanguagesInterface;
  className?: string;
  activeLang: number;
  onClick?: (value: number) => void;
}

export const LearningLanguagesComponent: React.FC<Props> = ({ item, activeLang, className, onClick }) => {
  const { t } = useTranslation();

  const onClickHandler = (value: number) => {
    if (!onClick) return;
    onClick(value);
  };

  return (
    <LearningLanguagesWrapper
      className={`learnLan ${className ? className : ''} ${activeLang === item.index ? '-active' : ''} ${item.inactive ? '-disabled' : ''}`}
      onClick={() => onClickHandler(item.index)}
    >
      <div className="learnLan-item">
        <div>
          <div>
            <img className="learnLan-item__flag" src={`/img/flags/${item.flag.toLowerCase()}.svg`} />
          </div>
          <span className="learnLan-item__title">
            {t(`learning.langs.${item.name}`)}
          </span>
        </div>
      </div>
    </LearningLanguagesWrapper>
  );
};
