import { Card } from '@mui/material';
import styled from 'styled-components';

export const ContentMainGains: any = styled.div`
  display: flex;
  justify-content: space-between;
  //align-items: flex-start;

  @media only screen and (max-width: 40.0625em) {
    display: flex;
    flex-direction: column;
    height: auto;
  }
`;

export const Gains: any = styled(Card)`
  &.MuiCard-root {
    position: sticky;
    top: 40px;
    width: 325px;
    height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
    align-items: center;
    //min-height: 765px;
    padding: 2rem 1rem;
    background: #EDF6FF;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.04);
    border-radius: var(--border-radius);
    overflow-y: auto;
  }

  .gains-progress {
    margin-top: 18px;
    display: none;
    font-family: var(--poppins);
    font-size: 10px;
    line-height: 15px;
  }

  @media only screen and (max-width: 40.0625em) {
    &.MuiCard-root {
      display: none;
      position: relative;
      top: 0;
      padding: 1rem;
      width: 100%;
      height: calc(50vh - 4rem);
      margin-bottom: 2rem;
    }

    .gains-progress {
      display: block;
    }
  }
`;

export const GainsRank: any = styled.div`
  position: relative;
  width: 100%;
`;

export const ContentMainProfit: any = styled.div`
  width: calc(100% - 341px);
  max-width: calc(100vw - 251px - 325px - 3rem);
  padding-left: 1rem;

  @media only screen and (max-width: 40.0625em) {
    width: 100%;
    padding-left: 0;
    max-width: 100%;
  }
`;

export const ContentCalendar: any = styled.div`
  display: flex;
  overflow-x: auto;
  margin-right: -16px;
  scrollbar-width: none;
  gap: 16px;
  margin-bottom: 1rem;

  ::-webkit-scrollbar {
    display: none;
  }

  &.profit_order {
    overflow: initial;
    margin-right: 0;

    .profit__container {
      flex-wrap: nowrap;
    }
  }
`;

export const Profit: any = styled.div`
  overflow-x: auto;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  &.profit_order {
    overflow: initial;
    margin-right: 0;

    .profit__container {
      flex-wrap: nowrap;
    }
  }

  .profit-card {
    background: #EDF6FF;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.04);

    &[data-title] {
      &:before {
        color: var(--brown);
        background: var(--light-blue);
        box-shadow: 0 0 11px rgba(0, 0, 0, 0.04);
      }
    }
  }

  .profit-card__title {
    color: #1b1b1b;
  }

  .profit-card__text {
    background-image: linear-gradient(180deg, #cdcdcd 0%, #272727 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .profit-card.active .profit-card__text {
    background-image: linear-gradient(45.04deg, #ffd6ef -35.82%, var(--red) 100.3%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .profit-card__value {
    font-weight: 700;
  }

  .profit-card__currency {
    font-weight: 700;
  }

  @media only screen and (max-width: 40.0625em) {
    overflow: hidden;
    margin-right: 0;
    margin-bottom: 2rem;
    padding: 0 1rem;
  }
`;

export const ProfitContainer: any = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  @media only screen and (max-width: 40.0625em) {
    gap: 1rem
  }
`;


export const TasksTitle: any = styled.h1`
  margin: 0 0 25px;
  font-size: 25px;
  line-height: 30px;
  color: var(--black);
  font-weight: 700;

  @media only screen and (max-width: 40.0625em) {
    margin: 0 0 25px;
    font-size: 20px;
    line-height: 24px;
    padding: 0 1rem;
  }
`;

export const Tasks: any = styled.div`
  .tasks-item {
    background: linear-gradient(0deg, #f3f7ff, #f3f7ff);
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.04);

    &__title {
      color: #1b1b1b;
    }

    &__subtitle {
      color: #1b1b1b;
    }

    &__content {
      span {
        color: #3f3f3f;
      }
    }
  }

  &.tasks {
    margin-right: -1rem;
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 40.0625em) {
    &.tasks {
      width: 100%;
      margin-right: 0;
    }
    
    & .simplebar-content-wrapper {
      padding: 0 1rem;
    }
  }
`;

export const TasksContainer: any = styled.div`
  display: flex;
  padding-top: 0.5rem;
  margin-bottom: 20px;
  overflow: hidden;
  flex: 0 0 100%;
  flex-wrap: nowrap;
  width: calc(50% * 9.4);
  
  .task {
    flex: 0 0 10%;
  }
  
  &.-four {
    width: calc(50% * 3.74);

    .task {
      flex: 0 0 25%;
    }
  }
  
  &.-eight {
    width: calc(50% * 7.48);

    .task {
      flex: 0 0 12.5%;
    }
  }
  
  &.-five {
    width: calc(50% * 4.68);

    .task {
      flex: 0 0 20%;
    }
  }

  @media only screen and (max-width: 40.0625em) {
    margin: 0px -16px 45px 0px;
    overflow-x: auto;
    flex-wrap: nowrap;
    width: calc(100% * 9.4);

    &.-four {
      width: calc(100% * 3.74);
    }

    &.-eight {
      width: calc(100% * 7.48);
    }

    &.-five {
      width: calc(100% * 4.68);
    }

    .task {
      &:last-child {
        margin-right: 1rem;
      }
    }
  }
`;

