import CssBaseline from '@mui/material/CssBaseline';
import { StylesProvider } from '@mui/styles';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ScrollToTop from "./common/utils/scrollToTop";

import { GlobalStyle } from './App.Styles';
import { PATHS } from './const/paths.constants';
import AuthRoutes from './routes/authRoutes';
import GuestRoutes from './routes/guestRoutes';
import PrivateRoutes from './routes/privateRoutes';
import Home from './pages/Home/Home';
import Features from './pages/Features/Features';
import Exercises from './pages/Features/Exercises';
import Login from './pages/Auth/Login';
import Registration from './pages/Auth/Registration';
import Dashboard from './pages/Dashboard/Dashboard';
import Practice from './pages/Practice/Practice';
import Tasks from './pages/Practice/Tasks';
import Listening from './pages/Listening/Listening';
import ListeningTasks from './pages/Listening/Tasks';
import Settings from './pages/Settings/Settings';
import Processing from "./pages/Processing";
import ProcessingTasks from "./pages/Processing/ProcessingTasks";

function App() {
  return (
    <StylesProvider injectFirst>
      <GlobalStyle />
      <CssBaseline />
      <ScrollToTop />
      <Routes>
        <Route
          path="*"
          element={<Navigate to={PATHS.HOME} replace />}
        />

        <Route
          path={PATHS.DASHBOARD}
          element={
            <PrivateRoutes title="navBar.dashboard">
              <Dashboard />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.PRACTICE}
          element={
            <PrivateRoutes title="navBar.practice">
              <Practice />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.PRACTICE_TASKS}
          element={
            <PrivateRoutes title="navBar.tasks">
              <Tasks />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.PRACTICE_TASKS_EXERCISE}
          element={
            <PrivateRoutes title="navBar.tasks">
              <Tasks />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.LISTENING}
          element={
            <PrivateRoutes title="navBar.practice">
              <Listening />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.LISTENING_TASKS}
          element={
            <PrivateRoutes title="navBar.tasks">
              <ListeningTasks />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.LISTENING_TASKS_EXERCISE}
          element={
            <PrivateRoutes title="navBar.tasks">
              <ListeningTasks />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.SETTINGS}
          element={
            <PrivateRoutes title="navBar.settings">
              <Settings />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.PROCESSING}
          element={
            <PrivateRoutes title="navBar.processing">
              <Processing />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.PROCESSING_TASK}
          element={
            <PrivateRoutes title="navBar.processing">
              <ProcessingTasks />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.HOME}
          element={
            <GuestRoutes title="navBar.home">
              <Home />
            </GuestRoutes>
          }
        />
        <Route
          path={PATHS.FEATURES}
          element={
            <GuestRoutes title="navBar.features">
              <Features />
            </GuestRoutes>
          }
        />
        <Route
          path={PATHS.EXERCISES}
          element={
            <GuestRoutes title="navBar.exercises">
              <Exercises />
            </GuestRoutes>
          }
        />

        <Route
          path={PATHS.LOGIN}
          element={
            <AuthRoutes title="navBar.login">
              <Login />
            </AuthRoutes>
          }
        />
        <Route
          path={PATHS.REGISTRATION}
          element={
            <AuthRoutes title="navBar.registration">
              <Registration />
            </AuthRoutes>
          }
        />
      </Routes>

    </StylesProvider>
  );
}

export default App;
