import styled from 'styled-components';
import { NavLink } from 'react-router-dom';


export const SidebarWrapper: any = styled.aside`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  flex: 0 0 251px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: var(--white);
  min-height: 100vh;
  box-shadow: 0 4px 28px rgba(0, 0, 0, 0.01);
  font-family: var(--montserrat);

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      var(--red) 50.52%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .logo {
    width: 7rem;
    height: 3rem;

    &-link {
      font-family: Pacifico, sans-serif;
      font-size: 34px;
      color: var(--red);
    }
  }

  @media (max-width: 40.0625em) {
    display: none;
  }
`;

export const Menu: any = styled.nav`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1
`;

export const MenuList: any = styled.ul`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 3px;
  flex-grow: 1;
  
  .menu {
    &__line {
      width: 100%;
      height: 0;
      margin: 20px 0;
      border: 1px solid #EFEFEF;
    }
  }
`;

export const MenuListItem: any = styled.li`
  overflow: hidden;

  &:first-child {
    margin-top: 59px  
  }


  &:last-child {
    margin-top: auto;
  }
`;

export const MenuListLink: any = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;

  span {
    display: block;
  }

  svg {
    width: 28px;
    min-width: 28px;
    fill: var(--black);
    margin-right: 20px;
    transition: fill 0.3s ease-in-out, stroke 0.3s ease-in-out;
  }
  
  &.active {
    color: var(--red);
    font-weight: 700;
    background-repeat: no-repeat;

    svg {
      fill: var(--red);
    }
  }

  &:hover,
  &:focus {
    svg {
      fill: var(--red);
    }
  }
`;

