export enum ALERT_TYPES {
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
}

export enum ALERT_CLASSES {
  DANGER = 'alert-danger',
  SUCCESS = 'alert-success',
}



export const PerformanceByPerccent: any = (percent: number) => {
  if (percent === 100) {
    return 'A+'
  }

  if (percent > 92.5) {
    return 'A'
  }

  if (percent > 82.5) {
    return 'B+'
  }

  if (percent > 75) {
    return 'B'
  }

  if (percent > 67.5) {
    return 'B-'
  }

  if (percent > 57.5) {
    return 'C+'
  }

  if (percent > 50) {
    return 'C'
  }

  if (percent > 25) {
    return 'D'
  }

  return 'F'
};

export const REGISTRATION_INPUT_LIST = ['email', 'password', 'password_confirm'];

export enum SOCIAL_NETWORKS {
  LINKEDIN = 'LINKEDIN',
  INSTAGRAM = 'INSTAGRAM',
}

export const SOCIAL_NETWORKS_LANGUAGE = {
  en: {
    list: [
      SOCIAL_NETWORKS.LINKEDIN,
      SOCIAL_NETWORKS.INSTAGRAM,
    ],
    links: {
      LINKEDIN: 'https://www.linkedin.com/company/didactist/',
      INSTAGRAM: 'https://www.instagram.com/didactistcom/',
    },
  }
};
