import styled from 'styled-components';

export const TaskCardWrapper: any = styled.article`
  position: relative;
  flex: 0 0 20%;
  border-radius: var(--border-radius);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  //transform: translateZ(0.1px);
  will-change: transform;
  padding-right: 1rem;

  &:last-child {
    margin-right: 45px;
  }

  &:hover {
    transform: translateY(-7px);
  }

  .blur {
    display: none;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background: rgba(196, 196, 196, 0.01);

    h3 {
      margin: 0 0 5px;
      text-align: center;
    }

    .desc {
      margin: 0;
      font-family: var(--poppins);
      padding: 1rem;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #000000;
    }

    .dev {
      margin: 0;
      max-width: 210px;
      font-family: var(--poppins);
      color: var(--red);
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
    }
  }

  &.-inactive {
    .task-item {
      filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='5');
      -webkit-filter: url(#blur);
      filter: url(#blur);
      -webkit-filter: blur(5px);
      filter: blur(5px);
    }

    .overlay {
      display: flex;
    }
  }

  @media only screen and (max-width: 40.0625em) {
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding-right: 0;
    width: 100%;
    max-width: 100%;
    min-width: auto;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const TaskCard: any = styled.div`
  position: relative;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.12));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 350px;
  padding: 48px 38px 34px 38px;
  background: linear-gradient(0deg, #F5FAFF, #F5FAFF);

  @media only screen and (max-width: 40.0625em) {
    padding: 28px 15px 22px;
    min-height: 235px;
    height: 100%;

    .task-item__btn {
      width: 100%;
    }
  }
`;

export const TaskCardBody: any = styled.div``;

export const TaskCardTitle: any = styled.h3`
  margin: 0 0 1rem;
  font-family: var(--poppins);
  font-size: 32px;
  line-height: 33px;
  color: var(--black);
  text-transform: uppercase;
  font-weight: 700;

  @media only screen and (max-width: 40.0625em) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 1rem;
  }
`;

export const TaskCardSubtitle: any = styled.p`
  margin: 0 0 30px;
  font-family: var(--poppins);
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: var(--white);

  @media only screen and (max-width: 40.0625em) {
    margin: 0 0 12px;
    font-size: 12px;
    line-height: 18px;
  }
`;

export const TaskCardContent: any = styled.div`
  margin-bottom: 16px;

  p {
    margin: 0 0 4px;
    font-family: var(--poppins);
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--black);
  }

  span {
    font-family: var(--poppins);
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    color: #d1d1d1;
  }

  @media only screen and (max-width: 40.0625em) {
    margin-bottom: 2rem;
    
    p {
      font-weight: 400;
    }
  }
`;

export const TaskCardProperty: any = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: var(--light-gray);
`;
