import SimpleBar from 'simplebar-react';
import styled from 'styled-components';
// import 'simplebar/dist/simplebar.min.css';

export const ScrollBarStyles: any = styled(SimpleBar)`
  .simplebar-track {
    background-color: rgba(56, 62, 73, 0.07);
    border-radius: var(--border-radius);
  }

  .simplebar-scrollbar:before {
    background-color: var(--red);
    border-radius: var(--border-radius);
    opacity: 1;
  }

  &.products-scrollbar {
    .simplebar-track.simplebar-horizontal {
      margin-right: 16px;
    }
  }

  &.profit-scrollbar {
    .simplebar-track.simplebar-horizontal {
      margin-right: 16px;
      margin-bottom: 10px;
    }
  }

  &.notifications-scrollbar {
    .simplebar-track.simplebar-vertical {
      width: 8px;
    }
  }

  &.structure-scrollbar {
    .simplebar-track.simplebar-vertical {
      margin: 20px 10px 20px 0;
    }
  }

  &.finance-scrollbar {
    .simplebar-track.simplebar-vertical {
      margin: 20px 0;
    }
  }

  @media (max-width: 992px) {
    .simplebar-track {
      display: none;
    }

    .simplebar-scrollbar {
      display: none;
    }

    &.notifications-scrollbar {
      .simplebar-track {
        display: block;
      }

      .simplebar-scrollbar {
        display: block;
      }
    }
  }
`;
