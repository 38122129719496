import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// import TelegramIcon from '@/assets/img/muiIcons/telegram';
import {
  Icon,
  // DropDown,
} from '../Icon/Icon';
import { SocialNetworksStyles } from './SocialNetworks.Styles';
import { SOCIAL_NETWORKS, SOCIAL_NETWORKS_LANGUAGE } from '../../const/app.constants';

interface Props {
  className?: string;
  // themeType?: string;
}

export const SocialNetworks: React.FC<Props> = (props: Props) => {
  // const { className, themeType } = props;
  // const { t, i18n } = useTranslation();
  const { className } = props;
  const { i18n } = useTranslation();
  const { language } = i18n;
  const [currentLang, setCurrentLang] = useState<string>(language);

  useEffect(() => {
    if (language !== currentLang) {
      setCurrentLang(language);
    }
  }, [language, currentLang]);

  const renderSocialNetworks = (social: string, links: any, key: number) => {
    switch (social) {
      case SOCIAL_NETWORKS.LINKEDIN:
        return (
          <a className="link" href={links[SOCIAL_NETWORKS.LINKEDIN]} target="_blank" rel="noreferrer" key={key}>
            <Icon name="linkedin" />
          </a>
        );
      case SOCIAL_NETWORKS.INSTAGRAM:
        return (
          <a className="link" href={links[SOCIAL_NETWORKS.INSTAGRAM]} target="_blank" rel="noreferrer" key={key}>
            <Icon name="instagram" />
          </a>
        );
      default:
        return null;
    }
  };

  return (
    <SocialNetworksStyles className={className}>
      {Object.keys(SOCIAL_NETWORKS_LANGUAGE).includes(currentLang)
        ? SOCIAL_NETWORKS_LANGUAGE[currentLang].list.map((social: string, index: number) =>
          renderSocialNetworks(social, SOCIAL_NETWORKS_LANGUAGE[currentLang].links, index + 1)
        )
        : SOCIAL_NETWORKS_LANGUAGE.en.list.map((social: string, index: number) =>
          renderSocialNetworks(social, SOCIAL_NETWORKS_LANGUAGE.en.links, index + 1)
        )}
    </SocialNetworksStyles>
  );
};
