import React from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import { MobileSidebarWrapper } from './MobileSidebar.styles';
import { useTranslation } from "react-i18next";

import { PATHS } from "../../const/paths.constants";


const MobileSidebar: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <MobileSidebarWrapper className='mobileMenu'>
      <div className="mobileMenu-wrap">
        <div className={pathname === PATHS.DASHBOARD ? 'mobileMenu-item -active' : 'mobileMenu-item'}>
          <Link to={PATHS.DASHBOARD} className='mobileMenu-link'>
            <span className="mobileMenu-text">{t('navBar.dashboard')}</span>
          </Link>
        </div>
        <div className={pathname.includes(PATHS.PRACTICE) ? 'mobileMenu-item -active' : 'mobileMenu-item'}>
          <Link to={PATHS.PRACTICE} className='mobileMenu-link'>
            <span className="mobileMenu-text">{t('navBar.practice')}</span>
          </Link>
        </div>
      </div>
    </MobileSidebarWrapper>
  );
};

export default connect()(MobileSidebar);
