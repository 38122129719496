import { makeHandler } from './makeHandler';
import {UserData} from "./codecs.users";

export const logIn = makeHandler(
  'logIn',
  (data: LogInParams) => ({
    method: 'POST',
    path: '/api/Login/Login',
    data,
  }),
  (res: LogInResponse) => res.data
);

export const registration = makeHandler(
  'registration',
  (data: RegistrationParams) => ({
    method: 'POST',
    path: '/api/Login/UserRegistration',
    data,
  }),
  (res: RegistrationResponse) => res.data
);

export const forgotPassword = makeHandler(
  'forgotPassword',
  (data: ForgotPasswordParams) => ({
    method: 'POST',
    path: '/api/Login/ChangePassword',
    data,
  }),
  (res: LogInResponse) => res.data
);

export type LogInParams = {
  /** e-mail */
  email: string;
  /** Password */
  password: string;
};

export type RegistrationParams = {
  /** e-mail */
  email?: string;
  /** Password */
  password: string;
  /** Confirm Password */
  confirmPassword: string;
  /** Terms */
  terms?: string;
};

export type ForgotPasswordParams = {
  /** e-mail */
  email?: string;
};

export type TokenData = {
  access_token: string;
  refresh_token: string;
  expires_in: string;
};

export type LogInResponse = {
  data: {
    tokenDetails: TokenData;
    Id?: number;
    LevelId?: number;
    /** E-mail */
    Email?: string;
    /** Номер мобильного телефона */
    Phone?: string;
    Photo?: string;
    Rating?: string;
    Skills?: string;
    TeacherId?: number;
    RoleName?: string;
    FirstName?: string;
    LastName?: string;
  }
};

export type RegistrationResponse = {
  data: {
    code: string;
    verificationToken: string;
  }
};
