import types from '../actionTypes';
import {
  AddNotificationParams,
  GetSheduletsParams,
  RemoveNotificationParams,
  TaskResultParams,
  UpdateNotificationParams
} from "../../api";

const getTasks = (payload: any) => ({
  type: types.GET_TASK_REQUEST,
  payload,
});

const createTask = (payload: any) => ({
  type: types.CREATE_TASK_REQUEST,
  payload,
});

const sendTaskResult = (payload: TaskResultParams) => ({
  type: types.TASK_RESULT_REQUEST,
  payload,
});

const getShedulets = (payload: GetSheduletsParams) => ({
  type: types.GET_SHEDULETS_REQUEST,
  payload,
});

const addShedulets = (payload: AddNotificationParams) => ({
  type: types.ADD_SHEDULETS_REQUEST,
  payload,
});

const updateShedulets = (payload: UpdateNotificationParams) => ({
  type: types.UPDATE_SHEDULETS_REQUEST,
  payload,
});

const removeShedulets = (payload: RemoveNotificationParams) => ({
  type: types.REMOVE_SHEDULETS_REQUEST,
  payload,
});

export {
  getTasks,
  createTask,
  sendTaskResult,
  getShedulets,
  addShedulets,
  updateShedulets,
  removeShedulets
};
