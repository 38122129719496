import styled from 'styled-components';

export const FooterComponent: any = styled.footer`
  
  
  .footer {
    background: #000;
    color: #fff;
    padding: 40px 0;
    
    & a {
      color: #ccc;
      display: block;
      font-size: 14px;
      line-height: 20px;
      padding: 2px 0;
      transition: all 1000ms ease;

      &:hover {
        color: #b22222;
      }
    }

    &-h1 {
      font-family: 'Poppins', sans-serif;
      font-size: 30px;

      & img {
        width: 50px;
        vertical-align: baseline;
      }
    }

    &-title {
      font-family: 'Poppins', sans-serif;
      font-size: 26px;
      font-weight: 500;
      line-height: 30px;
      margin-top: 32px;
      
      & .red {
        color: #b22222;
      }
    }

    &-p {
      font-family: 'Poppins', sans-serif;
    }

    &-autoris {
      margin-top: 10px;
      width: fit-content;
    }

    &-ul {
      margin-top: 38px;
      padding: 0;
      list-style-type: none;
      width: fit-content;

      & a {
        font-size: 14px;
        text-decoration: none;
        line-height: 22px;
        text-transform: uppercase;
        transition: all 1400ms ease;
      }
    }
    
    &-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &-copyrite {
      text-align: center;
      padding: 20px 0;
      background-color: #343434;
      color: #fff;
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
    }
  }
`;
