import React from 'react';
import {
  MainContent,
} from '../../elements';
import {
  FeaturesContent,
  RoadmapLineMobile,
  RoadmapMobile,
  RoadmapPostDotMobile,
  RoadmapPostDotWhiteMobile,
  RoadmapPostMobile,
  RoadmapRowMobile,
} from "./Features.Styles";
import {useTranslation, Trans} from "react-i18next";

const Features: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <MainContent className="content-main home-page">
        <FeaturesContent className="features">
          <div className="grid-container">
            <RoadmapMobile>
              <RoadmapLineMobile className="-mobile" />
              <RoadmapRowMobile>
                <RoadmapPostMobile>
                  <span className="roadmap-title">{t('features.roadmap.first.title')}</span>
                  <ul className="roadmap-list">
                    <li><Trans>{t('features.roadmap.first.list.0')}</Trans></li>
                    <li><Trans>{t('features.roadmap.first.list.1')}</Trans></li>
                  </ul>
                  <RoadmapPostDotMobile />
                  <RoadmapPostDotWhiteMobile />
                </RoadmapPostMobile>

                <RoadmapPostMobile>
                  <span className="roadmap-title">{t('features.roadmap.second.title')}</span>
                  <ul className="roadmap-list">
                    <li><Trans>{t('features.roadmap.second.list.0')}</Trans></li>
                    <li><Trans>{t('features.roadmap.second.list.1')}</Trans></li>
                  </ul>
                  <RoadmapPostDotMobile />
                  <RoadmapPostDotWhiteMobile />
                </RoadmapPostMobile>

                <RoadmapPostMobile>
                  <span className="roadmap-title">{t('features.roadmap.third.title')}</span>
                  <ul className="roadmap-list">
                    <li><Trans>{t('features.roadmap.third.list.0')}</Trans></li>
                    <li><Trans>{t('features.roadmap.third.list.1')}</Trans></li>
                    <li><Trans>{t('features.roadmap.third.list.2')}</Trans></li>
                  </ul>
                  <RoadmapPostDotMobile />
                  <RoadmapPostDotWhiteMobile />
                </RoadmapPostMobile>

                <RoadmapPostMobile className="-mobile">
                  <span className="roadmap-title">{t('features.roadmap.fourth.title')}</span>
                  <ul className="roadmap-list">
                    <li><Trans>{t('features.roadmap.fourth.list.0')}</Trans></li>
                  </ul>
                  <RoadmapPostDotMobile />
                  <RoadmapPostDotWhiteMobile />
                </RoadmapPostMobile>

                <RoadmapPostMobile className="-mobile">
                  <span className="roadmap-title">{t('features.roadmap.fifth.title')}</span>
                  <ul className="roadmap-list">
                    <li><Trans>{t('features.roadmap.fifth.list.0')}</Trans></li>
                  </ul>
                  <RoadmapPostDotMobile />
                  <RoadmapPostDotWhiteMobile />
                </RoadmapPostMobile>
                <RoadmapPostMobile className="-mobile" bottomPost>
                  <span className="roadmap-title">{t('features.roadmap.sixth.title')}</span>
                  <ul className="roadmap-list">
                    <li><Trans>{t('features.roadmap.sixth.list.0')}</Trans></li>
                    <li><Trans>{t('features.roadmap.sixth.list.1')}</Trans></li>
                    <li><Trans>{t('features.roadmap.sixth.list.2')}</Trans></li>
                    <li><Trans>{t('features.roadmap.sixth.list.3')}</Trans></li>
                    <li><Trans>{t('features.roadmap.sixth.list.4')}</Trans></li>
                    <li><Trans>{t('features.roadmap.sixth.list.5')}</Trans></li>
                  </ul>
                  <RoadmapPostDotMobile bottomPost />
                  <RoadmapPostDotWhiteMobile bottomPost />
                </RoadmapPostMobile>
                <RoadmapPostMobile className="-mobile" bottomPost>
                  <span className="roadmap-title">{t('features.roadmap.seventh.title')}</span>
                  <ul className="roadmap-list">
                    <li><Trans>{t('features.roadmap.seventh.list.0')}</Trans></li>
                    <li><Trans>{t('features.roadmap.seventh.list.1')}</Trans></li>
                    <li><Trans>{t('features.roadmap.seventh.list.2')}</Trans></li>
                    <li><Trans>{t('features.roadmap.seventh.list.3')}</Trans></li>
                  </ul>
                  <RoadmapPostDotMobile bottomPost />
                  <RoadmapPostDotWhiteMobile bottomPost />
                </RoadmapPostMobile>
              </RoadmapRowMobile>

              <RoadmapLineMobile className="-desktop" />

              <RoadmapRowMobile className="-desktop">

                <RoadmapPostMobile bottomPost className="roadmap-auto">
                  <span className="roadmap-title">{t('features.roadmap.fourth.title')}</span>
                  <ul className="roadmap-list">
                    <li><Trans>{t('features.roadmap.fourth.list.0')}</Trans></li>
                  </ul>
                  <RoadmapPostDotMobile bottomPost />
                  <RoadmapPostDotWhiteMobile bottomPost />
                </RoadmapPostMobile>

                <RoadmapPostMobile bottomPost className="roadmap-auto">
                  <span className="roadmap-title">{t('features.roadmap.fifth.title')}</span>
                  <ul className="roadmap-list">
                    <li><Trans>{t('features.roadmap.fifth.list.0')}</Trans></li>
                  </ul>
                  <RoadmapPostDotMobile bottomPost />
                  <RoadmapPostDotWhiteMobile bottomPost />
                </RoadmapPostMobile>

                <RoadmapPostMobile bottomPost className="roadmap-auto">
                  <span className="roadmap-title">{t('features.roadmap.sixth.title')}</span>
                  <ul className="roadmap-list">
                    <li><Trans>{t('features.roadmap.sixth.list.0')}</Trans></li>
                    <li><Trans>{t('features.roadmap.sixth.list.1')}</Trans></li>
                    <li><Trans>{t('features.roadmap.sixth.list.2')}</Trans></li>
                    <li><Trans>{t('features.roadmap.sixth.list.3')}</Trans></li>
                    <li><Trans>{t('features.roadmap.sixth.list.4')}</Trans></li>
                    <li><Trans>{t('features.roadmap.sixth.list.5')}</Trans></li>
                  </ul>
                  <RoadmapPostDotMobile bottomPost />
                  <RoadmapPostDotWhiteMobile bottomPost />
                </RoadmapPostMobile>

                <RoadmapPostMobile bottomPost className="roadmap-auto">
                  <span className="roadmap-title">{t('features.roadmap.seventh.title')}</span>
                  <ul className="roadmap-list">
                    <li><Trans>{t('features.roadmap.seventh.list.0')}</Trans></li>
                    <li><Trans>{t('features.roadmap.seventh.list.1')}</Trans></li>
                    <li><Trans>{t('features.roadmap.seventh.list.2')}</Trans></li>
                    <li><Trans>{t('features.roadmap.seventh.list.3')}</Trans></li>
                  </ul>
                  <RoadmapPostDotMobile bottomPost />
                  <RoadmapPostDotWhiteMobile bottomPost />
                </RoadmapPostMobile>

              </RoadmapRowMobile>
            </RoadmapMobile>
          </div>
        </FeaturesContent>
      </MainContent>
    </>
  );
};

export default Features;
