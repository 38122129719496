import styled from 'styled-components';

export const RegistrationStyles: any = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  font-family: var(--montserrat);

  .registration {
    &__container {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 456px;
    }

    &__box {
      width: 100%;
      text-align: left;
    }

    &__title {
      text-align: center;
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
    }

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      color: var(--black);
      margin: 0;
      margin-right: 3px;

      &-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4px;
      }

      &-link {
        color: var(--red);
        font-weight: 700;
        font-size: 14px;
        line-height: 32px;
      }
    }

    &__input {
      margin-bottom: 20px;
    }

    &__checkbox {
      align-items: center;
      text-align: left;
      display: flex;
      margin-top: 31px;
      margin-left: -3px;

      a {
        display: inline;
        color: var(--red);
      }

      .MuiSvgIcon-root {
        width: 23px;
        height: 23px;
      }

      .MuiFormControlLabel-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 32px;
        font-family: var(--montserrat);
      }

      .MuiCheckbox-root {
        padding: 0;
        margin-right: 4px;
        color: var(--light-gray);

        &.Mui-checked {
          .MuiSvgIcon-root {
            color: var(--red);
            fill: var(--light-gray);
          }
        }
      }

      + p {
        display: none;
        animation: fadeIn 0.8s;
      }

      &.-error {
        .MuiSvgIcon-root {
          fill: var(--red);
        }

        + p {
          display: block;
        }
      }
    }

    &__button {
      &-wrap {
        display: flex;
        justify-content: center;
        margin-top: 18px;
      }
    }
  }

  .error-text {
    margin: 5px 0;
    color: var(--red);
    font-size: 12px;
    line-height: 14px;
    text-align: left;
  }
`;
