export enum PATHS {
  HOME = '/',
  FEATURES = '/features',
  ROADMAP = '/roadmap',
  EXERCISES = '/features/:exercise/*',
  LOGIN = '/login',
  REGISTRATION = '/registration',
  FORGOT_PASSWORD = '/forgot-password',
  DASHBOARD = '/dashboard',
  PRACTICE = '/practice',
  PRACTICE_TASKS = '/practice/:task',
  PRACTICE_TASKS_EXERCISE = '/practice/:task/:exnumber',
  LISTENING = '/listening',
  LISTENING_TASKS = '/listening/:task',
  LISTENING_TASKS_EXERCISE = '/listening/:task/:exnumber',
  SETTINGS = '/settings',
  BLOG = 'https://blog.didactist.com/',
  JOTTER = 'https://blog.didactist.com/jotter/',
  SYNCHRONIST = 'https://blog.didactist.com/synchronist/',
  PROCESSING = '/processing',
  PROCESSING_TASK = '/processing/:task'
}
