import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyle: any = createGlobalStyle`
  ${normalize}
    // Variables
  :root {
    --montserrat: 'Montserrat', sans-serif;
    --libero: 'Libre Franklin', sans-serif;
    --poppins: 'Poppins', sans-serif;

    --white: #ffffff;
    --black: #060931;
    --light-blue: #F0F6FF;
    --gray: #ccc;
    --light-gray: #e3e3e3;
    --dark-gray: rgb(86, 86, 86);
    --blue: #3B8EFF;
    --turquoise: rgba(92, 214, 207, 0.75);
    --dark-turquoise: rgba(92, 214, 207, 1);
    --light-turquoise: rgba(92, 214, 207, 0.5);
    --dark-white: #FCFCFC;
    --violet: #8000ff;
    --pink: #dd59f8;
    --red: #b22222;
    --green: #22E292;
    --light: #eaeaea;
    --dark: #212529;
    --light-brown: #636363;
    --brown: #434343;
    --dark-blue: #15172E;

    --ametis: #D95CF9;
    --lazurit: #689EF4;
    --nefrit: #02E271;
    --emerald: #00FFB2;
    --ruby: #FF005C;
    --saphire: #5D31FC;
    --diamond: #00E1EF;

    --border-radius: 8px;

    --horizontal-border: linear-gradient(90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.06) 71.01%,
    rgba(255, 255, 255, 0) 99.52%);
    --vertical-border: linear-gradient(rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.06) 71.01%,
    rgba(255, 255, 255, 0) 99.52%);
  }


  html {
    box-sizing: border-box;
  }

  *,
  ::after,
  ::before {
    box-sizing: inherit;
  }

  body {
    min-width: 320px;
    font-family: var(--libero);
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    scrollbar-width: none;
    background-color: var(--white);

    ::-webkit-scrollbar {
      display: none;
    }
  }

  html, body, #app {
    height: 100%;
  }

  img {
    width: 100%;
  }

  a {
    display: inline-block;
    color: inherit;
    text-decoration: none;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: var(--red);
    }

    &:focus {
      outline: none;
      color: var(--red);
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
    }
  }

  ::selection {
    background-color: rgba(196, 196, 196, 0.5);
    color: var(--white);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @keyframes blink {
    0% {
      width: 100%;
    }
    50% {
      width: 75%;
    }
    100% {
      width: 100%;
    }
  }

  .MuiTooltip-tooltip {
    background-color: var(--white)!important;
    border-radius: var(--border-radius)!important;
    padding: 0 1rem!important;

    .shedulets {
      &-list {
      }
      
      &-item {
        border-top: 1px solid var(--red);
        padding: 1rem 0;

        &:first-child {
          border-top: 0;
        }
        
        &__btn {
          padding: 0;
          color: var(--white);
          background-color: var(--dark);
          border: 3px solid var(--dark);
          cursor: pointer;
          font-size: 12px;
        }
      }
    }
    p {
      display: block;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: rgb(120, 128, 141);
    }

    span {
      display: block;
      margin: 0;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: rgb(27, 27, 27);
      margin-bottom: 3px;
    }
  }
`;
