import { makeHandler } from './makeHandler';

export const createTask = makeHandler(
  'createTask',
  (data: CreateTaskParams) => ({
    method: 'POST',
    path: '/api/CreateTask/CreateTask',
    data,
  }),
  (res: CreateTaskResponse) => res.data
);

export const fetchTasks = makeHandler(
  'fetchTasks',
  (data: GetTaskParams) => ({
    method: 'GET',
    path: `/api/Tasks/GetTasksByTaskType?userid=${data.UserId}&task_type=${data.Task_Type}&languageid=${data.languageId}`,
  }),
  (res: CreateTaskResponse) => res.data
);

export const sendTaskResult = makeHandler(
  'sendTaskResult',
  (data: TaskResultParams) => ({
    method: 'POST',
    path: '/api/Result/CreateTaskResult',
    data,
  }),
  (res: CreateTaskResponse) => res.data
);

export const updateTaskResult = makeHandler(
  'updateTaskResult',
  (data: TaskResultParams) => ({
    method: 'POST',
    path: '/api/Result/UpdateTaskResult',
    data,
  }),
  (res: CreateTaskResponse) => res.data
);

export const schedulerGetShedulets = makeHandler(
  'schedulerGetShedulets',
  (data: GetSheduletsParams) => ({
    method: 'POST',
    path: '/api/Scheduler/GetShedulets',
    data,
  }),
  (res: CreateTaskResponse) => res.data
);

export const schedulerAddNotification = makeHandler(
  'schedulerAddNotification',
  (data: AddNotificationParams) => ({
    method: 'POST',
    path: '/api/Scheduler/AddNotification',
    data,
  }),
  (res: CreateTaskResponse) => res.data
);

export const schedulerUpdateNotification = makeHandler(
  'schedulerUpdateNotification',
  (data: UpdateNotificationParams) => ({
    method: 'POST',
    path: '/api/Scheduler/UpdateNotification',
    data,
  }),
  (res: CreateTaskResponse) => res.data
);

export const schedulerRemoverNotification = makeHandler(
  'schedulerRemoverNotification',
  (data: RemoveNotificationParams) => ({
    method: 'GET',
    path: `/api/Scheduler/RemoverNotification/${data.notificationId}`,
  }),
  (res: { data: { Id: number } }) => res.data
);

export const getAccents = makeHandler(
  'getAccents',
  (data: GetAccentsParams) => ({
    method: 'GET',
    path: `/api/Tasks/GetExtAccent?taskId=${data.taskId}`,
  }),
  (res) => res.data
);

export const getSimplificate = makeHandler(
  'getSimplificate',
  (data: TranslateParams) => ({
    method: 'POST',
    path: `/api/Translate/GetSimplificate`,
    data
  }),
  (res) => res.data
);

export const getTranslate = makeHandler(
  'getTranslate',
  (data: TranslateParams) => ({
    method: 'POST',
    path: `/api/Translate/GetTranslateText`,
    data
  }),
  (res) => res.data
);

export const getListeningExtTasks = makeHandler(
  'getListeningExtTasks',
  (data: { userId: number }) => ({
    method: 'GET',
    path: `/api/TaskHandling/GetListeningExtTasks?userid=${data.userId}`
  }),
  (res) => res.data
);

export const getListeningExtTask = makeHandler(
  'getListeningExtTask',
  (data: { userId: number, taskId: string }) => ({
    method: 'GET',
    path: `/api/TaskHandling/GetListeningExtTask?userid=${data.userId}&taskid=${data.taskId}`
  }),
  (res) => res.data
);

export type GetAccentsParams = {
  taskId: number;
};

export type TranslateParams = {
  Id: number;
  task_text: string;
  task_title: string;
  TLang?: string;
};

export type CreateTaskParams = {
  TopicId: string;
  Task_Type: number;
  UserId: number;
};

export type GetTaskParams = {
  Task_Type?: number;
  UserId: number;
  languageId:number;
};

export type TaskResultParams = {
  completed?: boolean;
  TrueResult: number;
  FalseResult: number;
  FactTimeSec: number;
  TaskId: number;
  studentId: number;
  languageId?:number;
};

export type GetSheduletsParams = {
  UserId: number;
  Language_id: number;
};

export type AddNotificationParams = {
  UserId: number;
  Language_id: number;
  TaskId: number;
  EventDate: string;
  IsNotification: boolean;
};

export type UpdateNotificationParams = {
  Id: number;
  EventDate: string;
};

export type RemoveNotificationParams = {
  notificationId: number;
};

export type Shedule = {
  id: number;
  userId: number;
  taskId: number;
  eventTitle: string;
  eventDate: string;
  creationDate: string;
  isNotification: boolean;
  reflection: string;
  notificationFrequensy: number;
  description: string;
  taskType: string;
};

export type CreateTaskResponse = {
  data: {}
};
