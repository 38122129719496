import styled from 'styled-components';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const CalendarBlock: any = styled.div`
  font-family: var(--inter);
  
  .calendar {
    &-icon {
      
    }
    &-wrap {
      position: relative;
    }
    
    &__btn {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.5;
      border: 0;
      background: var(--black);
      color: var(--white);
      padding: 10px;
      border-radius: 0;
      border-right: 1px solid var(--light-blue);
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      text-transform: uppercase;
      cursor: pointer;
    }
    
    &-error {
      margin: 6px 0;
      color: var(--red);
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      display: block;
      white-space: pre-line;
      transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    }
  }
`;

export const CalendarHidden: any = styled(DatePicker)`
  position: absolute!important;
  height: 1px;
  width: 1px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  opacity: 0;
  
  .MuiInputBase-root {
    padding: 0;
    height: 1px;
    width: 1px;
  }
`;
