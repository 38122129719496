import React from 'react';

import {
  UserInfoBlock,
  UserInfoBox,
  UserInfoId,
  UserInfoImg,
  UserInfoName,
} from './UserInfo.Styles';

interface Props {
  className?: string;
  src?: string;
  alt?: string;
  userName?: string;
  userId?: string;
}

export const UserInfo: React.FC<Props> = ({
  className,
  src,
  alt,
  userName,
  userId,
}) => {
  return (
    <UserInfoBlock className={`user-info ${className}`}>
      <UserInfoImg className="user-info__img">
        <img src={src} alt={alt} />
      </UserInfoImg>
      <UserInfoBox>
        <UserInfoName className="user-info__name">
          <span>{userName}</span>
        </UserInfoName>
        <UserInfoId className="user-info__id">{userId}</UserInfoId>
      </UserInfoBox>
    </UserInfoBlock>
  );
};
