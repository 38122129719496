import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Container,
  MainContent,
  InfoItem,
} from '../../elements';
import { useTranslation } from "react-i18next";
import {
  ActivesContracts, ActivesContractsItem,
  ActivesStyles, TitleBlock,
} from "./Practice.Styles";
import { Link } from "react-router-dom";
import {ProfitCardTitle} from "../../elements/ProfitComponent/ProfitComponent.Styles";
import {PATHS} from "../../const/paths.constants";
import {AppStateType} from "../../store";
import {fetchStatistics} from "../../store/user/actions";
import {UserReducerState} from "../../store/user/reducers";
import {StatisticsParams} from "../../api";
import {StatisticsListTask} from "../../api/codecs.users";
import {EXERCISES_ALIAS, TASK_DATA_BY_ID} from "../../const/exercises.constants";

export type ActiveTask = {
  title: string;
  generated_count: number;
  completed_count: number;
  completed_percent: number;
  spent_time: string;
  redirectUrl: string;
  className?: string;
};

const data:any = {
  meaning: [
    {
      title: 'tasks.tasks.practice.items.listSelection.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: `tasks.tasks.practice.items.${EXERCISES_ALIAS.MATCH_THE_HEADINGS}.title`,
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.matchingParagraphInfo.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.multiple.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.sentenceCompletion.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.shortAnswerQuestion.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    }
  ],
  allegory: [
    {
      title: 'tasks.tasks.practice.items.idioms.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.metaphors.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.personification.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.storyElements.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
  ],
  structure: [
    {
      title: 'tasks.tasks.practice.items.choosingTitle.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.classificationQuestion.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.compareContrast.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.diagramCompletion.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.matchingSentenceEnding.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.sequencing.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.summaryCompletion.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.tableCompletion.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
  ],
  criticalThinking: [
    {
      title: 'tasks.tasks.practice.items.cause.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.conclusionsInferences.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.contextClues.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.factOpinion.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
    {
      title: 'tasks.tasks.practice.items.prediction.title',
      generated_count: 0,
      completed_count: 0,
      completed_percent: 0,
      spent_time: 0,
      redirectUrl: '',
      className: '-inactive'
    },
  ],
};

export interface PracticeProps {
  user: UserReducerState;
  fetchStatistics: (payload: StatisticsParams | any) => void;
}

const Practice: React.FC<PracticeProps> = (props: PracticeProps) => {
  const { user, fetchStatistics } = props;
  const { t } = useTranslation();

  const [meaning, setMeaning] = useState<any[]>([]);

  useEffect(() => {
    if (!user.statistics.loaded) {
      fetchStatistics({
        StudentId: user.userData?.id,
        languageId: user.learningLanguage
      });
    } else {
      if (user.statistics.list.length) {
        const newStatistics = user.statistics.list.map((stat: StatisticsListTask ) => {
          if (!TASK_DATA_BY_ID[stat.TaskType]) return null;

          return {
            title: `tasks.tasks.practice.items.${EXERCISES_ALIAS[TASK_DATA_BY_ID[stat.TaskType]]}.title`,
            generated_count: stat.GeneratedTasks,
            completed_count: stat.CompletedTasks,
            completed_percent: stat.GeneratedTasks ? ((stat.CompletedTasks / stat.GeneratedTasks) * 100).toFixed(2).replace(/\.?0+$/, '') : '0',
            spent_time: `${(stat.FactTimeSec > 60 ? (stat.FactTimeSec / 60) > 60 ? stat.FactTimeSec / 3600 : stat.FactTimeSec / 60 : stat.FactTimeSec).toFixed(2).replace(/\.?0+$/, '')} ${stat.FactTimeSec > 60 ? (stat.FactTimeSec / 60) > 60 ? 'h' : 'min' : 'sec'}`,
            redirectUrl: `${PATHS.PRACTICE}/${EXERCISES_ALIAS[TASK_DATA_BY_ID[stat.TaskType]]}`
          }
        }).filter((stat) => !!stat);

        const statisticsArr: any[] = [...newStatistics, ...data.meaning];

        setMeaning(statisticsArr);
      }
    }

    if (user.learningLanguage !== user.statistics.languageId && user.statistics.loaded) {
      fetchStatistics({
        StudentId: user.userData?.id,
        languageId: user.learningLanguage
      });
    }
  }, [user, fetchStatistics, setMeaning]);

  const activesBlock = (task: ActiveTask, index: number) => (
    <ActivesContractsItem
      key={`actives-task-${index + 1}`}
      className={`active-contracts__item ${task.className}`}
    >
      <div className="overlay">
        <ProfitCardTitle className="active-contracts__percent">{t(task.title)}</ProfitCardTitle>
        <p>In development</p>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" className="blur">
        <defs>
          <filter id="blur">
            <feGaussianBlur stdDeviation="5" />
          </filter>
        </defs>
      </svg>
      <div className="active-contracts__container">
        <div className="active-contracts__header">
          <p className="active-contracts__percent">{t(task.title)}</p>
        </div>
        <div className="active-contracts__info-items">
          <InfoItem
            className=""
            infoText={t('exercises.common.generated')}
            infoValue={task.generated_count}
          />
          <InfoItem
            className=""
            infoText={t('exercises.common.completed')}
            infoValue={task.completed_count}
          />
          <InfoItem
            className=""
            infoText={t('exercises.common.successfully')}
            infoValue={`${task.completed_percent}%`}
          />
          <InfoItem
            className=""
            infoText={t('exercises.common.spentTime')}
            infoValue={task.spent_time}
          />
          <Button
            as={Link}
            className="products-item__btn md"
            to={task.redirectUrl}
          >
            {t('common.perform')}
          </Button>
        </div>
      </div>
    </ActivesContractsItem>
  );

  return (
    <MainContent className='content-main home-page'>
      <Container>
        <ActivesStyles className="actives">
          <TitleBlock>{t('tasks.headers.meaning')}</TitleBlock>
          <ActivesContracts className="active-contracts">
            {meaning.map((task: ActiveTask, index: number) => activesBlock(task, index))}
          </ActivesContracts>

          <TitleBlock>{t('tasks.headers.allegory')}</TitleBlock>
          <ActivesContracts className="active-contracts">
            {data.allegory.map((task: ActiveTask, index: number) => activesBlock(task, index))}
          </ActivesContracts>

          <TitleBlock>{t('tasks.headers.structure')}</TitleBlock>
          <ActivesContracts className="active-contracts">
            {data.structure.map((task: ActiveTask, index: number) => activesBlock(task, index))}
          </ActivesContracts>

          <TitleBlock>{t('tasks.headers.criticalThinking')}</TitleBlock>
          <ActivesContracts className="active-contracts">
            {data.criticalThinking.map((task: ActiveTask, index: number) => activesBlock(task, index))}
          </ActivesContracts>
        </ActivesStyles>
      </Container>
    </MainContent>
  );
};

const mapState = (state: AppStateType) => {
  const { user } = state;
  return {
    user,
  };
};

export default connect(mapState, { fetchStatistics })(Practice);
